export const contentFrench = {

    "about": {

    'header-p2': 'La traduction comme enjeu de survie de langues juives en tant que langues post-vernaculaires',
        'headerGeneral': 'Informations générales',

    'paragraph1': 'Le projet LJTRAD doit offrir une vision aussi complète que possible de la question de la traduction des langues juives en abordant toutes les dimensions de la pratique traductive depuis les problèmes généraux concernant l’inscription des langues juives dans le panorama de la «littérature monde» jusqu’aux questions linguistiques et culturelles les plus pointues qui se posent au traducteur dans son travail sur le texte original.',
    'paragraph2': 'Le projet LJTRAD offre, pour la première fois, une approche raisonnée et systématique des traductions des langues juives, ce qui permettra la publication d’un ouvrage collectif consacrée au sujet, fruit de collaborations avec des collègues étrangers, et offrant une vision comparative des situations de traduction en fonction des langues et des pays. Nous fournirons un bilan complet des traductions vers le français et leur bibliographie critique et commentée. Nous élaborerons une typologie des difficultés de traduction qui aboutira à la rédaction et à la publication d’un guide pour les futurs traducteurs des langues juives. Enfin nous développerons une base de données proposant des corpus parallèles alignés de textes originaux et de traductions, recherchables et exploitables thématiquement et linguistiquement, ce qui suppose la mise au point de nouveaux outils de traitement automatique des langues juives. Nous mettrons en pratique les enseignements apportés par les résultats précédents pour fournir, en ligne, et sur papier, de nouvelles traductions originales d’ouvrages fondamentaux de ces cultures ainsi que des anthologies bilingues fort nécessaires dans une perspective pédagogique et pour la diffusion vers le grand public des richesses culturelles des langues juives.',
    'paragraph3': 'Le sujet de la traduction des langues juives reste relativement peu théorisé et notre projet sera pionnier en la matière. L’hypothèse sur laquelle celui-ci repose est la suivante: de même qu’il existe une spécificité linguistique des langues juives, de même l’acte de traduction rencontre des problèmes uniques, et qui méritent d’être abordés comme tels, dans son traitement des textes en langues juives. Notre pensée devra se nourrir de tous les apports théoriques de la traductologie, particulièrement riches ces dernières décennies, mais elle devra également trouver les moyens de caractériser, et de formaliser, les obstacles particuliers qu’opposent les langues juives à l’acte traducteur.',
    'image': 'assets/img/pattern3.png',
     'headerTeam': 'Equipe du projet',
        'acknowledgementHeader': 'Remerciements'
    },

    "history": {
        "historyHeader": "La traduction du yiddish en France",
        "historySubHeader": "Histoire d’une pratique, mémoire d’un monde disparu",
        "author": "Malena Chinski",
    },
     "corpusPage": {
         title: "Parallel Yiddish-French corpus",
            firstLang: "First language",
            secondLang: "Second language",
            strict: "Recherche stricte"
     }


}


export const contentEnglish = {

    "about": {
    'header-p2': 'Translation as a strategic issue for the survival of post-vernacular Jewish languages – LJTRAD',
        'headerGeneral': 'General information',

    'paragraph1': 'The present project aims at offering a systematic and comprehensive study of the translation of Jewish languages (Yiddish, Judeo-Spanish, Judeo-Arabic). Since those languages have lost, during the last decades, a great part of their speakers, their cultures are often dealt with through new practices which do not take for granted any linguistic fluency and those languages have been defined as post-vernarcular (heritage languages). This new state of things makes of translation the central piece of any strategy of conservation and transmission of the cultural wealth created in Jewish languages. It is, for this reason, essential to catalogue in a critical way all the translations from these languages available in French and to reflect on the particular obstacles translation faces when it encounters their cultural universe embued with religious notions and their mixed linguistic material of which each component can be freely used to change the connotations of the discourse.',
    'paragraph2': 'During our project, translations from Jewish languages will be, first of all, studied, as a whole, as an essential, though problematic, instrument of cultural transfer from a minority culture to the dominant one. Practical difficulties and ideological prejudices are usual factors throughout the history of these translations and they deserve to be enlightened in providing a critcal review of the existing French translations. Deeper insights into the question will be provided through the organization of two international conferences during which the French relationship to thes endangered languages and cultures will be compared to the cultural transfers existing towards other linguistic spheres (English, Russian, Hebrew) in order to identify the common challenges and the varying solutions. But this project will also analyze the concrete choices implied by the process of translation and the difficulties which are recurringly encountered, be they lexical, syntactical or stylistical. ',
    'paragraph3': 'In order to facilitate our research, one great challenge will be to design new digital tools which would enable, through the use of automated language processing and of parallel aligned corpora, to access a wealth of data concerning translation choices. As a result, we should be able to observe some deep historical, cultural and esthetic trends which influenced the changes in the translation patterns, but also, from a pragmatic point of view, the possibility to gain much information about the various translation options. It will give the opportunity to write a guide for future translators, which will not intend to prescribe anything, but will answer the main question each and every translator of the Jewish languages has to face at a time when the environment in which the texts have been produces becomes harder to fathom. Besides in searchable platform, giving access to original texts and their translations, the present project wishes to provide, through a website and through printed books, a series of new translations of literary texts which will be included in anthologies illustrating the great perods, genres and problematics of each culture. Only through such bilingual resources can one satisfy the needs of a new readership turning to this heritage whith a great variety of cultural and linguistic competencies, as the post-vernacular use of these languages wants it. Thus, beyond the new digital tool it will design, the present project will lead to the production of pedagogical tools and of ressources relevant for a wider audience.',
    'image': 'assets/img/pattern3.png',
     'headerTeam': 'Project team',
        'acknowledgementHeader': 'Acknowledgements'

    },
     "corpusPage":
        {
            title: "Corpus parallèle yiddish-français",
            firstLang: "Première langue",
            secondLang: "Deuxième langue",
            strict: "Strict search"
        }
}



