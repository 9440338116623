import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import ParallelTextDisplay from "../components/ParallelTextDisplay";
import "../style/App.css";

const useStyles = makeStyles((theme) => {});

export default function ParallelTextsPage(props) {
  const classes = useStyles();
  const req = require.context("../texts", false, /\.json$/);
  const jsonFiles = req.keys();
  const jsonContents = jsonFiles.map((file) => req(file));

  return (
    <>
      <nav
        style={{ marginBottom: "20px", textAlign: "center", marginTop: "20px" }}
      >
        <ol style={{ padding: 0 }}>
          {jsonContents.map((content, index) => (
            <li
              key={index}
              style={{
                marginBottom: "10px",
                fontFamily: "Arial, sans-serif",
                color: "#13a7c0",
              }}
            >
              {" "}
              <a
                href={`#section-${index}`}
                style={{
                  textDecoration: "none",
                  color: "#13a7c0",
                  fontSize: "16px",
                  fontWeight: "bold",
                  transition: "color 0.3s",
                }}
              >
                {" "}
                {index + 1}. {content.title}
              </a>
            </li>
          ))}
        </ol>
      </nav>

      {jsonContents.map((content, index) => (
        <section id={`section-${index}`} style={{ marginTop: "50px" }}>
          <Container maxWidth="md" my={5}>
            <ParallelTextDisplay key={index} data={content} />
          </Container>
        </section>
      ))}
    </>
  );
}
