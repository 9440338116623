import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";

const useStyles = makeStyles((theme) => ({
  footerNav: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(1),
  },
  footerLink: {
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
}));

export default function Footer(props) {
  const classes = useStyles();

  let content = {
    English: {
      brand1: { image: "assets/img/anr.png", width: 150 },
      brand2: { image: "assets/img/inalco.png", width: 150 },
      link1: "Home",
      link2: "About the project",
      link3: "History of the translations",
      link4: "Research",
      "primary-action": "Français",
    },
    French: {
      brand1: { image: "assets/img/anr.png", width: 150 },
      brand2: { image: "assets/img/inalco.png", width: 150 },
      link1: "Home",
      link2: "Information du projet",
      link3: "Histoire des traductions",
      link4: "Recherche",
      link5: "",
      "primary-action": "English",
    },
  };

  props.language === "Français"
    ? (content = content.French)
    : (content = content.English);

  let brand1 = (
    <img src={content.brand1.image} alt="" width={content.brand1.width} />
  );
  let brand2 = (
    <img src={content.brand2.image} alt="" width={content.brand2.width} />
  );

  return (
    <footer>
      <Container maxWidth="lg">
        <Box py={6} textAlign="center">
          <Link
            href="https://anr.fr/en/"
            color="inherit"
            underline="none"
            target="_blank"
          >
            {brand1}
          </Link>
          <Link
            href="http://www.inalco.fr/recherche/cermom"
            color="inherit"
            underline="none"
            target="_blank"
          >
            {brand2}
          </Link>
          <Box component="nav" className={classes.footerNav}>
            <Link
              href="/"
              variant="body1"
              color="textPrimary"
              className={classes.footerLink}
            >
              {content["link1"]}
            </Link>
            <Link
              href="/#information"
              variant="body1"
              color="textPrimary"
              className={classes.footerLink}
            >
              {content["link2"]}
            </Link>
            <Link
              href="/history"
              variant="body1"
              color="textPrimary"
              className={classes.footerLink}
            >
              {content["link3"]}
            </Link>
            <Link
              href="#"
              variant="body1"
              color="textPrimary"
              className={classes.footerLink}
            >
              {content["link4"]}
            </Link>
          </Box>

          <Typography
            color="textSecondary"
            component="p"
            variant="caption"
            gutterBottom={false}
          >
            {content["copy"]}
          </Typography>
        </Box>
      </Container>
    </footer>
  );
}
