import Box from "@material-ui/core/Box";
import {FormControl, FormHelperText, MenuItem, Select} from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import {BootstrapInput, BootstrapInputRTL} from "../helpers/corpusPageHelpers";
import React from "react";

export default function CustomizedSelects({before, query, setBefore, setQuery, language, firstlang}) {
    let content = {
        French: {
            before: "Phrases en contexte",
            query: "*Entrez votre requête ici"
        },
        English: {
            before: "Sentences in context",
            query: "*Enter your query here"
        }
    }
    language === "Français"
        ? (content = content.French)
        : (content = content.English);

    const handleChangeBefore = (event) => {
        setBefore(event.target.value);
    };
    return (
        <Box style={{display: "flex", flexWrap: "wrap"}}>
            <FormControl sx={{m: 1}} variant="standard">
                <InputLabel htmlFor="demo-customized-textbox"> </InputLabel>
                {firstlang === 'Yiddish' ? <BootstrapInputRTL id="demo-customized-textbox" value={query}
                                                              onChange={(e) => setQuery(e.target.value)}/> :
                    <BootstrapInput id="demo-customized-textbox" value={query}
                                    onChange={(e) => setQuery(e.target.value)}/>}
                <FormHelperText>{content['query']}</FormHelperText>
            </FormControl>
            <FormControl sx={{m: 1}} variant="standard">
                <InputLabel id="demo-customized-select-label"> </InputLabel>
                <Select
                    labelId="demo-customized-select-label"
                    id="demo-customized-select"
                    value={before}
                    onChange={handleChangeBefore}
                    input={<BootstrapInput/>}
                >
                    <MenuItem value={1}>{language === "Français" ? "Une" : "One"}</MenuItem>
                    <MenuItem value={2}>{language === "Français" ? "Deux" : "Two"}</MenuItem>
                    <MenuItem value={3}>{language === "Français" ? "Trois" : "Three"}</MenuItem>
                    <MenuItem value={4}>{language === "Français" ? "Quatre" : "Four"}</MenuItem>
                </Select>
                <FormHelperText>{content['before']}</FormHelperText>
            </FormControl> <br/>
        </Box>
    );
}