import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import "../style/App.css";
import TableHead from "@mui/material/TableHead";
import { Alert } from "@mui/material";
import CSVReport from "./CSVReport";
import {
  createData,
  TablePaginationActions,
  handleTranslations,
} from "../helpers/resultTableHelpers";
import { useEffect } from "react";

export default function ResultTable(props) {
  const [rows, setRows] = React.useState([]);

  useEffect(() => {
    setRows([]);
    const res = makeRows();
    setRows(res);
  }, [props.result]);

  const makeRows = () => {
    const data = props.result;
    let lang2 = "fr";
    if (props.lang2 !== "Français") {
      lang2 = "yi";
    }

    for (let i = 0; i < data.length; i++) {
      let finalLang1 =
        lang2 === "fr"
          ? '<div class="rtl secondaryText">'
          : '<div class="secondaryText">';
      let finalItemLang2 =
        lang2 === "fr"
          ? '<div class="secondaryText">'
          : '<div class="rtl secondaryText">';
      for (let j = 0; j < data[i]["before"]["sentences"].length; j++) {
        finalLang1 += data[i]["before"]["sentences"][j]["text"];
        finalItemLang2 += handleTranslations(
          data[i]["before"]["translations"][lang2]
        );
        finalLang1 += " ";
        finalItemLang2 += " ";
      }
      finalLang1 +=
        '<span class="mainSent">' +
        data[i]["result"]["sentences"][0]["text"] +
        "</span>";
      finalItemLang2 +=
        '<span class="mainSent">' +
        handleTranslations(data[i]["result"]["translations"][lang2]) +
        "</span>";
      finalLang1 += " ";
      finalItemLang2 += " ";
      for (let k = 0; k < data[i]["after"]["sentences"].length; k++) {
        finalLang1 += data[i]["after"]["sentences"][k]["text"];
        finalItemLang2 += handleTranslations(
          data[i]["after"]["translations"][lang2]
        );
        finalLang1 += " ";
        finalItemLang2 += " ";
      }
      finalLang1 += "</div>";
      finalItemLang2 += "</div>";
      let fr = "";
      let yi = "";
      if (lang2 === "fr") {
        fr = finalItemLang2;
        yi = finalLang1;
      } else {
        fr = finalLang1;
        yi = finalItemLang2;
      }
      rows.push(createData(i + 1, yi, fr, data[i]["metadata"]));
    }
    return rows;
  };

  const emptyRows =
    props.page > 0
      ? Math.max(0, (1 + props.page) * props.rowsPerPage - rows.length)
      : 0;

  const handleChangePage = (event, newPage) => {
    props.setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    props.setRowsPerPage(parseInt(event.target.value, 10));
    props.setPage(0);
  };

  return (
    <>
      <CSVReport data={rows} />
      {props.result.length === 0 ? (
        <Alert style={{ margin: "auto", width: "50%" }} severity="info">
          Your query has given no results!
        </Alert>
      ) : (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
            <TableHead>
              <TableRow>
                <TableCell align="left"> </TableCell>
                <TableCell align="center">Français</TableCell>
                <TableCell align="center">Yiddish</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow key={`${row.number}_${Math.random()}_${row.french}`}>
                  <TableCell>
                    {row.number + props.page * props.rowsPerPage}
                  </TableCell>
                  <TableCell align="left">
                    <p dangerouslySetInnerHTML={{ __html: row.french }} />
                    <p>
                      <span>
                        <i className="colored">{row.metadata.title}</i>
                      </span>
                      <br />
                      <span>
                        <b className="colored">{row.metadata.author}</b>
                      </span>
                    </p>
                  </TableCell>
                  <TableCell align="right">
                    <p dangerouslySetInnerHTML={{ __html: row.yiddish }} />
                    <p className="rtl">
                      <span style={{ marginBottom: 0 }} className="rtl">
                        <i className="colored ">{row.metadata.title}</i>
                      </span>
                      <br />
                      <span>
                        <b className="colored ">{row.metadata.author}</b>
                      </span>
                    </p>
                  </TableCell>
                </TableRow>
              ))}

              {/*{emptyRows > 0 && (*/}
              {/*  <TableRow style={{ height: 53 * emptyRows }}>*/}
              {/*    <TableCell colSpan={6} />*/}
              {/*  </TableRow>*/}
              {/*)}*/}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, 50]}
                  colSpan={5}
                  count={props.count}
                  rowsPerPage={props.rowsPerPage}
                  page={props.page}
                  SelectProps={{
                    inputProps: {
                      "aria-label": "rows per page",
                    },
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      )}
    </>
  );
}
