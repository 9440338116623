import React from "react";
import { HashLink } from "react-router-hash-link";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Box from "@material-ui/core/Box";
import Toolbar from "@material-ui/core/Toolbar";
import Link from "@material-ui/core/Link";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import Button from "@material-ui/core/Button";
import Menu from "@mui/material/Menu";
import { useKeycloak } from "@react-keycloak/web";

const useStyles = makeStyles((theme) => ({
  toolbar: {
    minHeight: 70,
  },
  brand: {
    lineHeight: 1,
    marginRight: "auto",
  },
  link: {
    textDecoration: "none",
    marginRight: theme.spacing(5),
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  primaryAction: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  menuButton: {
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  iconWrapper: {
    minWidth: 40,
  },
  icon: {
    color: theme.palette.text.hint,
  },
  drawerContainer: {
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(3),
    width: 300,
  },
}));

export default function NavBar(props) {
  const { keycloak, initialized } = useKeycloak();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  let content = {
    French: {
      brand: { image: "assets/img/logo.svg", width: 110 },
      link1: "Home",
      link2: "Information du projet",
      link3: "Histoire des traductions",
      link4: "Recherche",
      link5: "Textes parallèles",
      "primary-action": "English",
      historyLink1: "Yiddish",
      historyLink2: "Judéo-espagnol",
    },
    English: {
      brand: { image: "assets/img/logo.svg", width: 110 },
      link1: "Home",
      link2: "About the project",
      link3: "History of translations",
      link4: "Research",
      link5: "Parallel Texts",
      historyLink1: "Yiddish",
      historyLink2: "Ladino",
      "primary-action": "Français",
    },
  };
  props.language === "Français"
    ? (content = content.French)
    : (content = content.English);
  const classes = useStyles();
  let brand = (
    <img src={content.brand.image} alt="" width={content.brand.width} />
  );
  const [state, setState] = React.useState({ open: false });
  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, open });
  };
  return (
    <AppBar position="static" color="inherit">
      <Toolbar className={classes.toolbar}>
        <Link
          href="/"
          color="primary"
          underline="none"
          variant="h5"
          className={classes.brand}
        >
          {brand}
        </Link>
        <Button id="homeLink" size="small" href="/" className={classes.link}>
          {content["link1"]}
        </Button>
        <Button
          id="aboutLink"
          size="small"
          href="/#information"
          className={classes.link}
        >
          {content["link2"]}
        </Button>
        <Button
          id="textsLink"
          size="small"
          href="/texts"
          className={classes.link}
        >
          {content["link5"]}
        </Button>
        <Button
          id="historyLink"
          size="small"
          aria-controls={open ? "history-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
          className={classes.link}
        >
          {content["link3"]}
        </Button>
        <Menu
          id="history-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "historyLink",
          }}
        >
          <MenuItem onClick={handleClose}>
            <Link style={{ textDecoration: "none" }} href="/history">
              {content["historyLink1"]}
            </Link>
          </MenuItem>
          <MenuItem onClick={handleClose}>
            <Link style={{ textDecoration: "none" }} href="/historyEs">
              {content["historyLink2"]}
            </Link>
          </MenuItem>
        </Menu>
        <Link
          sx={{ mr: 2 }}
          href="#"
          color="textPrimary"
          variant="body2"
          className={classes.link}
          style={{ textDecoration: "none" }}
        >
          {!keycloak.authenticated && (
            <Button
              variant="contained"
              color="info"
              className={classes.primaryAction}
              onClick={() => keycloak.login()}
            >
              Login{" "}
            </Button>
          )}

          {keycloak.authenticated && (
            <Button
              variant="contained"
              color="info"
              className={classes.primaryAction}
              onClick={() => keycloak.logout()}
            >
              Logout{" "}
            </Button>
          )}
        </Link>

        <Link
          href="#"
          color="primary"
          variant="body2"
          className={classes.link}
          style={{ textDecoration: "none" }}
        >
          <Button
            variant="contained"
            color="secondary"
            className={classes.primaryAction}
            href="/corpus"
          >
            Corpus{" "}
          </Button>
        </Link>

        <Box sx={{ maxWidth: 100, minWidth: 100 }} className="LanguageSelect">
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label"> </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={props.language}
              label="Language"
              onChange={(e) => props.handleSetLanguage(e.target.value)}
            >
              <MenuItem value={"English"}>English</MenuItem>
              <MenuItem value={"Français"}>Français</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <IconButton
          edge="start"
          color="primary"
          aria-label="menu"
          className={classes.menuButton}
          onClick={toggleDrawer(true)}
        >
          <MenuIcon />
        </IconButton>
      </Toolbar>
      <Drawer anchor="left" open={state.open} onClose={toggleDrawer(false)}>
        <div className={classes.drawerContainer}>
          <Box
            mb={1}
            ml={2}
            pb={2}
            border={1}
            borderTop={0}
            borderLeft={0}
            borderRight={0}
            borderColor="background.emphasis"
          >
            <Link
              href="#"
              color="primary"
              underline="none"
              variant="h5"
              className={classes.linkBrand}
            >
              {brand}
            </Link>
          </Box>
          <List>
            <ListItem button key={content["link1"]}>
              <Link href="/">
                {" "}
                <ListItemText primary={content["link1"]} />
              </Link>
            </ListItem>

            <ListItem button key={content["link2"]}>
              <HashLink to="/#information" style={{ textDecoration: "none" }}>
                <ListItemText primary={content["link2"]} />
              </HashLink>
            </ListItem>

            {props.language !== "English" ? (
              <ListItem button key={content["link3"]}>
                <Link href="/history">
                  <ListItemText primary={content["link3"]} />
                </Link>
              </ListItem>
            ) : null}
          </List>

          <Box
            mt={1}
            ml={2}
            pt={3}
            border={1}
            borderBottom={0}
            borderLeft={0}
            borderRight={0}
            borderColor="background.emphasis"
          >
            <Button
              variant="contained"
              color="primary"
              fullWidth
              href="/corpus"
            >
              Login
            </Button>
            <br />
            <br />
            <Button
              variant="contained"
              color="secondary"
              fullWidth
              href="/corpus"
            >
              Corpus
            </Button>
          </Box>
          <br />

          <Box sx={{ maxWidth: 100, minWidth: 100, marginTop: 20 }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Language</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={props.language}
                label="Language"
                onChange={(e) => props.handleSetLanguage(e.target.value)}
              >
                <MenuItem value={"English"}>English</MenuItem>
                <MenuItem value={"Français"}>Français</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </div>
      </Drawer>
    </AppBar>
  );
}
