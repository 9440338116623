import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { HashLink } from "react-router-hash-link";
import "../style/App.css";

const useStyles = makeStyles((theme) => ({
  name: {
    lineHeight: 1,
  },
  content: {
    [theme.breakpoints.up("md")]: {
      paddingLeft: theme.spacing(8),
      paddingRight: theme.spacing(8),
    },
  },
  paragraph: {
    marginBottom: theme.spacing(3),
  },
  image: {
    maxWidth: "100%",
    borderRadius: theme.shape.borderRadius,
  },
}));

export default function HistoryPage(props) {
  const classes = useStyles();
  return (
    <>
      <section>
        <Container maxWidth="md">
          <Box py={10}>
            <Box textAlign="center" mb={5}>
              <Container maxWidth="sm">
                <Box my={5}>
                  <Typography variant="h4" component="h4" color="primary">
                    La littérature sépharade à travers les siècles
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="center" alignItems="center">
                  <Box ml={2} textAlign="left">
                    <Typography
                      align="justify"
                      variant="subtitle1"
                      component="h2"
                      className={classes.name}
                    >
                      Elena Romero, CSIC, Madrid*
                    </Typography>
                  </Box>
                </Box>
              </Container>
            </Box>
            <Box className={classes.content}>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                Le mot « sépharade » est défini par le Dictionnaire de La Real
                Academia comme suit : « Juif exilé d’Espagne » ; précisons cette
                définition, par ailleurs correcte, et ajoutons que les
                « Sépharades » sont les descendants des Juifs qui ont quitté
                l’Espagne à la fin du Moyen-Âge{" "}
                <HashLink to="#1" className="ftnLink" id="s1">
                  [1]
                </HashLink>
                . Mais avant d’entrer dans le vif du sujet, il convient de
                dissiper une confusion terminologique.
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                C’est une sérieuse erreur conceptuelle de recourir à la
                dénomination « sépharade ». Il convient d’appeler
                « Hispano-Juifs » les Juifs qui vécurent dans les royaumes
                musulmans et chrétiens d’Espagne au Moyen-Âge, et leur
                production littéraire, écrite dans la langue dans laquelle ils
                s’exprimaient, doit être appelée hispano-hébraïque,
                hispano-arabe ou hispano-romane. Rappelons que le mot
                « sefardi » est l’adjectif démonyme de Sefarad, toponyme
                hébraïque qui apparaît une seule fois dans la Bible (Abdias
                1 :20) et qu’à partir du Moyen-Âge, on a commencé à l’assimiler
                à la Péninsule Ibérique dans son ensemble. Le fait qu’un
                démonyme aussi généralisant ne doive pas s’appliquer aux Juifs
                qui vivaient dans la Péninsule avant l’expulsion est étayé par
                une raison de taille : ces mêmes Juifs ne se le sont jamais
                appliqué à eux-mêmes lorsqu'ils vivaient dans la Péninsule, car
                l'étendue même du concept ne renvoyait à aucun signe d'identité
                spécifique ; c'est ainsi qu'ils nous ont laissé des
                dénominations telles que <em>haguirondí</em> (de Gérone),{" "}
                <em>haleoní</em> (de León), etc. Afin de ne pas nous submerger
                d'exemples, je me limiterai à deux cas : Selomó ibn Gabirol a
                laissé dans l'acrostiche de certains de ses poèmes l'indication
                de son lieu d'origine : <em>hamalaquí</em> "le Malagueño"
                (habitant de Malaga) ; et Maïmonide a été appelé{" "}
                <em>hasefaradí</em> "le Sépharade", alors qu'il vivait déjà en
                Égypte et que son œuvre était connue dans le monde juif et
                chrétien. L'éloignement de la patrie est donc une condition{" "}
                <em>sine qua non</em> pour que le gentilice généralisant prenne
                un sens.
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                La présence juive en Espagne prend fin dans un acte final
                tragique : l’expulsion. Mais les Juifs qui ont dû vivre ce
                terrible événement, qui avaient hérité de leurs aînés un
                enracinement total dans les cultures hispaniques, bien qu'ils
                aient quitté la terre de leurs ancêtres, n'ont pas complètement
                abandonné l'environnement culturel dans lequel ils avaient vécu
                ni sa manifestation la plus noble, qui est la langue.
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                C’est à partir de la culture et de la langue espagnoles de
                l'époque de l'expulsion que se sont développées une culture
                sépharade autonome et une évolution autonome de la langue.
                Pendant des siècles, la communauté sépharade y a eu recours
                comme langue de communication et langue de création littéraire,
                et c'est cet aspect de la pérennité d'une langue qui, dans son
                développement autonome, n'a pas cessé d'être hispanique, qui
                nous permet de déterminer notre objet d'étude selon des critères
                culturels.
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                Etant donné que les œuvres littéraires sont souvent le fruit des
                événements socio-historiques que les auteurs ont eu à vivre, et
                étant donné que je vais examiner ce qui a été produit par les
                Sépharades durant une longue période -la moitié d’un millénaire-
                et également géographique, je devrai mentionner au cours de mon
                exposé quelques faits qui ont eu un impact considérable sur la
                production littéraire en judéo-espagnol. Et le premier évènement
                auquel je dois faire allusion, même brièvement, est la formation
                de la diaspora sépharade.
              </Typography>
              <Typography
                align="justify"
                variant="h5"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                I. La diáspora sepharade
              </Typography>

              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                Je suivrai le schéma de périodisation développé par Hassan{" "}
                <HashLink to="#2" className="ftnLink" id="s2">
                  [2]
                </HashLink>
                , qui procède à une division en trois périodes, la première et
                la dernière étant désignées comme ouvertes et la période
                intermédiaire comme fermée{" "}
                <HashLink to="#3" className="ftnLink" id="s3">
                  [3]
                </HashLink>
                . J'expliquerai, au cours de mon exposé, la signification de ces
                désignations.
              </Typography>

              <Typography
                align="justify"
                variant="h6"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                1.1 L'expulsion d'Espagne et la formation de la diaspora
                sépharade
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                La première période, entre la fin du XVe siècle et le milieu du
                XVIIe siècle, ne commence pas en 1492, mais après 1391, année
                où, à la suite des troubles et des exactions commises contre les
                Juifs dans les royaumes hispaniques, un certain nombre de
                personnes quittent la terre de leurs pères, avant-garde de ceux
                qui choisiront le difficile chemin de l'exil à la fin du XVe
                siècle. Mais l'exode ne s'arrête pas à 1492 : parmi les Juifs
                qui, après la proclamation de l'édit d'expulsion, ont opté pour
                la conversion, nombreux sont ceux qui, après une période plus ou
                moins longue, ont décidé de s'exiler pour rejoindre les
                communautés fondées dans ce que l'on peut appeler la diaspora
                sépharade, en embrassant à nouveau publiquement le judaïsme. Ce
                flux humain de convertis en provenance de la péninsule ibérique
                - rappelons qu'entre 1581 (Philippe II) et 1668 (Charles II),
                l'Espagne et le Portugal ne formaient qu'un seul royaume - qui
                s'est prolongé jusqu'à une bonne partie du XVIIe siècle,
                représente une voie de communication ouverte - d'où le nom de la
                période - entre le monde sépharade et le pays d'origine.
              </Typography>

              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                En 1492, les Juifs sont expulsés de Castille et d'Aragon (ainsi
                que de Sicile). C'est ainsi que prend fin une présence qui,
                pendant quinze siècles, s’était révélée enrichissante pour les
                royaumes ibériques médiévaux. Jusqu'au XIXe siècle, on ne trouve
                plus de Juifs dans la péninsule, ou de manière isolée.
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                Dans l'espoir que l'expulsion soit temporaire et que les Rois
                Catholiques reviennent sur leur terrible décision, de nombreux
                Sépharades cherchent à s'installer dans des lieux proches de
                l'Espagne. L'un d'entre eux est le Portugal, où les expulsés
                espagnols sont admis par Joao II, moyennant le paiement d'un
                tribut considérable et pour une période de huit mois.
                L'animosité populaire déclenchée par les émigrants accélère leur
                départ. Ceux qui se trouvent dans l’impossibilité de partir sont
                déclarés esclaves du roi. En 1496, le roi Manuel promulgue un
                édit d'expulsion ordonnant aux Juifs de quitter le pays avant la
                fin du mois d'octobre 1497. Cet édit est suivi d'une campagne de
                conversion forcée à laquelle est soumise la quasi-totalité des
                Juifs portugais. En conséquence, le phénomène du crypto-judaïsme
                s'est développé au Portugal à une échelle beaucoup plus
                importante qu'en Espagne, et même l'Inquisition mise en place au
                début du XVIe siècle n'a pas pu l'arrêter. Un grand nombre de
                ces juifs convertis ont fui vers les Pays-Bas tout au long des
                XVIe et XVIIe siècles.
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                D'autres voies de départ se situent (approximativement) au nord,
                dans le royaume de Navarre, d'où ils sont expulsés en 1512,
                suite à l'annexion du royaume à la Couronne d'Aragon (Ferdinand
                le Catholique), le sud de la France et, pour l'essentiel, au
                sud, vers l'Afrique du Nord, et à l'est, vers l'Italie, qui est
                à la fois un point d'arrivée et une étape vers les Balkans et la
                Méditerranée orientale.
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                Avec le passage de la première à la deuxième des périodes
                précédemment mentionnées, la diaspora sépharade s'est divisée en
                trois grandes zones. L'une au sud-ouest, en Afrique du Nord,
                qui, après l'occupation de Gibraltar par les Britanniques en
                1704, peut être étendue jusqu'au détroit de Gibraltar, avec des
                centres prédominants, d'abord à Fès, puis à Tétouan. Et une
                autre, plus étendue, à l'Est, dans les frontières de l'Empire
                ottoman, où les villes de Constantinople et de Salonique à
                toutes les époques, d'Andrinople (Edirne) dans les premières
                années et de Smyrne dans les dernières, deviennent à leur tour
                des points focaux rayonnant vers d'autres villes plus petites
                qui finissent par couvrir, en un réseau dense, la vaste
                extension territoriale suivante, que je détaille d'ouest en est
                et du nord au sud : la quasi-totalité de la péninsule
                balkanique, les îles de la mer Égée, les côtes de l'Anatolie, la
                Syrie (surtout Alep), Israël (surtout Jérusalem et Safed) et
                l'Égypte (surtout Le Caire et Alexandrie) ; ou pour le dire en
                termes contemporains et en ce qui concerne l'Europe, dans ce qui
                est aujourd'hui les États de Turquie, de Grèce, d'Albanie, de
                Bosnie-Herzégovine, de Serbie-Monténégro, de Croatie
                (quelques-uns), de Macédoine, de Bulgarie et de Roumanie
                méridionale. À ces deux zones s'en ajoute une troisième, formée
                par les villes de Budapest et de Vienne, cette dernière étant la
                ville où s'est établie, à partir de 1718, une très importante
                communauté sépharade, fondée par des Juifs de l'Empire ottoman.
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                Entre les deux zones - Afrique du Nord et Ottomane - se trouvent
                les communautés sépharades italiennes établies à Ferrare, Pise,
                Venise et Livourne ; et au nord de toutes, celles des Pays-Bas,
                où Amsterdam a fini par donner naissance à Hambourg à l'est, à
                Londres à l'ouest et aux anciennes communautés des Caraïbes et
                plus tard des États-Unis de l'autre côté de l'Atlantique.
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                Au sein de l'Empire ottoman (qui ne s'occupait de ses minorités
                et de ses peuples que lorsqu'il s'agissait de percevoir un
                tribut), la communauté sépharade, organisée en communautés
                urbaines et repliées sur elles-mêmes, a bénéficié d'une
                autonomie suffisante, avec ses propres dirigeants, ses tribunaux
                rabbiniques, ses administrateurs et, surtout, son propre système
                d'éducation. Un tableau similaire à celui de l'Empire turc peut
                être établi grossièrement pour les Sépharades qui, accueillis
                par les hiérarques marocains, se sont installés en Afrique du
                Nord. Sous le statut de "gens du livre", ils s'organisent dans
                un environnement musulman avec l'obligation de payer des impôts
                et la permission de s'organiser en interne.
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                Comme je l'expliquerai plus loin, nous ne traiterons pas des
                Sépharades qui se sont installés aux Pays-Bas, en Italie et dans
                le sud de la France, où ils ont perdu, au fil du temps, ce que
                nous avons considéré comme leur principal trait culturel : la
                langue espagnole. Une fois la diaspora sépharade formée, la
                deuxième période, qui s'étend du milieu du XVIIe siècle au
                milieu du XIXe siècle, se caractérise par le fait que les
                grandes masses sépharades, intégrées à l'Empire ottoman, voient
                s'interrompre presque tout contact avec leur pays d'origine,
                c'est-à-dire l'Espagne. La diaspora sépharade orientale s'est
                détachée de l'Espagne, constituant dans les Balkans une nation
                ou un milet, intégré de plus dans la structure, presque plus
                administrative que politique, de l'Empire ottoman permissif. Un
                phénomène similaire s'est produit, par exemple, dans cette zone
                géographique avec les communautés bulgare, grecque, etc., ces
                dernières ayant leur propre territoire, ou avec la communauté
                arménienne sans espace territorial.
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                D'une manière générale, et à l'exception des sièges africains
                devenus propriété de la couronne espagnole au cours du XVIe
                siècle, on retrouve pour les Sépharades installés en Afrique du
                Nord une situation similaire à celle décrite pour l'Empire
                ottoman, en termes d'interruption des contacts avec la Péninsule
                et d'organisation interne autonome. La différence significative
                réside dans le fait que ces Sépharades reprennent un contact
                direct avec l'Espagne après la fin de la guerre d'Afrique contre
                le sultan du Maroc en 1860 et l'établissement du protectorat
                espagnol dans la région.
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                Revenons donc à l'examen plus détaillé de l'évolution historique
                des Sépharades installés dans l'Empire ottoman.
              </Typography>
              <Typography
                align="justify"
                variant="h6"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                1.2 L'Empire ottoman
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                Pour en revenir aux Balkans, c'est à cette époque que la langue
                se consolide en tant que système indépendant de l'espagnol,
                alors qu'elle n'était au départ qu'une variété d'un même
                système.
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                Au XVIe siècle, les sultans ottomans avaient conquis de vastes
                territoires en Europe, en particulier dans les Balkans et les
                pays slaves, ainsi qu'en Asie et en Afrique du Nord. Ce vaste
                territoire abritait une population très diversifiée, composée de
                différents groupes ethniques, nationaux et religieux. Après la
                conquête de Constantinople en 1453, la ville devient la capitale
                et est rebaptisée Istanbul.
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                Les expulsés d'Espagne, qui apportent le riche patrimoine
                culturel, social et économique qu'ils avaient acquis dans la
                péninsule ibérique, sont accueillis par le sultan Bajazet II
                (1481-1512), qui leur ouvre volontiers les portes de l'Empire.
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                Sous le régime juridique du statut protégé, on les traite
                généralement avec tolérance, la Sublime Porte ne restreignant
                jamais l'activité économique des minorités hébergées sur son
                territoire et n'empêchant pas les Juifs ottomans d'exercer une
                activité ou une profession quelconque.
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                La période la plus prospère est vécue par les Sépharades au
                cours du XVIe siècle, qui est également le zénith de la
                puissance politique, économique et militaire de l'Empire
                ottoman, en particulier sous le règne de Soliman le Magnifique
                (1520-1566). Leur situation est meilleure que celle des
                minorités chrétiennes, car les sultans, en guerre contre le
                christianisme, préfèrent accorder leur confiance à ceux qui
                pratiquent une autre religion. Don Yosef Nasí et sa tante Dona
                Gracia Mendes, qui, au milieu du XVIe siècle, sont propriétaires
                terriens et conseillers du sultan, sont des exemples du statut
                élevé atteint par certains juifs. La riche famille Nasí,
                originaire d'Espagne, avait été convertie de force au
                christianisme au Portugal ; après avoir longtemps erré aux
                Pays-Bas, en France et en Italie, elle s'est finalement
                installée à Istanbul en 1552, où elle est revenue publiquement
                au judaïsme. À l'époque du sultan Selim II, Yosef Nasi dirige la
                politique étrangère de la Sublime Porte et est nommé duc de
                Naxos. Il est vrai que cette situation privilégiée -dont
                jouissaient certains Sépharades- était souvent instable,
                soumise, comme il ne pouvait en être autrement, aux vicissitudes
                des changements politiques internes de la cour ottomane toujours
                tempétueuse, dans laquelle l'occupation de postes importants
                comportait des risques ; c'est ainsi que l'on voit nombre de ces
                notables sépharades exécutés sommairement, pris au milieu des
                oscillations des rapports de force et des alliances de cour. Il
                n'est donc pas surprenant que nous trouvions également des Juifs
                convertis à l'islam dans les environs de la Sublime Porte, en
                tant que médecins des sultans ou courtisans à différents
                niveaux.
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                Tout au long des siècles de leur séjour en terre ottomane, les
                communautés sépharades ont souffert de façon quasi endémique de
                trois maux : les excès des janissaires, troupe rebelle à
                laquelle le sultan Mahmoud II a dû mettre fin par la force en
                1826 ; les abus et les extorsions des gouverneurs locaux,
                souvent avides d'argent ; et les accusations de crime rituel.
                Les deux premières touchent non seulement les Juifs mais toutes
                les minorités religieuses de l'Empire, mais la dernière les
                concerne plus particulièrement, leurs voisins grecs orthodoxes
                étant les principaux propagateurs de la calomnie. Ces
                accusations de crime rituel ont proliféré dans tout l'Empire
                ottoman, certains des incidents les plus marquants, en raison de
                leurs répercussions internationales, s'étant produits à Damas et
                à Rhodes en 1840.
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                Au XVIe siècle, les structures juridiques et organisationnelles
                des communautés ont été créées et, dans la foulée du grand essor
                économique, une intense activité culturelle s'est développée. De
                nombreux Juifs occupent des postes importants dans l'économie et
                la politique, en tant que ministres et hauts fonctionnaires de
                l'administration impériale, diplomates et interprètes,
                collecteurs d'impôts, conseillers et médecins des sultans, et
                fournisseurs de l'armée, une activité qui durera jusqu'au XVIIIe
                siècle. Parmi les personnes expulsées d'Espagne, il ne manque
                pas d'experts en commerce international et de personnes
                qualifiées dans des industries telles que le textile et la
                fabrication d'armes, qui développent une industrie de guerre
                moderne dans l'Empire. Les Sépharades qui se sont installés dans
                les villes portuaires de la Méditerranée, telles que Salonique
                et Smyrne, et dans d'importants centres de communication, comme
                Andrinople, ont établi un réseau de relations commerciales avec
                les communautés d'autres régions d'Europe, activant ainsi le
                commerce d'exportation et d'importation, qui était
                particulièrement important avec Amsterdam et avec des villes
                italiennes comme Venise et Livourne. Dans l'industrie textile,
                Safed (Palestine) se distingue au XVIe siècle, tout comme
                Salonique (Macédoine), dont la production se maintient jusqu'au
                XIXe siècle.
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                De son côté, la majorité du peuple développe d'autres fonctions
                multiples à petite échelle, telles que boutonniers, musiciens et
                comédiens, bijoutiers et orfèvres, forgeurs rompus aux méthodes
                des armureries de Tolède et de Saragosse, pharmaciens et
                parfumeurs, taverniers, teinturiers, etc. et même porteurs et
                autres métiers portuaires. Ce sont également les Sépharades qui
                fondent la première imprimerie de l'Empire, qui commence à
                fonctionner à Istanbul deux ans après l'expulsion et dont ils
                ont eu le monopole jusqu'en 1727 ; il y a également des
                imprimeries à Salonique, Smyrne, Andrinople et ailleurs, et des
                centaines de livres en hébreu et en judéo-espagnol sortent de
                leurs presses. Les communautés, parfaitement organisées par un
                corpus de dispositions légales ou tacanot, sont gouvernées par
                des rabbins et des chefs de communauté. Avec les réformes
                ottomanes des Tanzimat au XVIIIe siècle, apparaît la figure du
                Grand Rabbin, sous le contrôle religieux duquel sont placées
                toutes les communautés.
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                Le déclin économique et politique de l'Empire commence au XVIIe
                siècle et atteint son paroxysme au XIXe siècle. Il touche toutes
                les minorités en général et la communauté juive en particulier,
                avec une certaine stagnation et un déclin général. La crise de
                l'Empire coïncide avec la reprise économique et le développement
                industriel de l'Europe occidentale et centrale, ce qui favorise,
                à partir de la fin du XVIe siècle, l'implantation dans les
                villes portuaires et commerciales de l'Empire d'établissements
                économiques européens, les "compagnies françaises, anglaises et
                hollandaises du Levant", avec lesquelles les Sépharades
                collaborent activement en tant qu'agents et fournisseurs, et qui
                ouvre la voie à une influence européenne grandissante.
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                Le XVIIe siècle voit l'inauguration du système des
                capitulations, qui accorde des privilèges politiques,
                juridiques, économiques et religieux aux sujets des pays
                européens installés dans l'Empire. Ce système a deux
                conséquences majeures : l'installation de Juifs européens
                -principalement des Italiens d'origine portugaise- dans les
                villes portuaires et commerciales, véritable moteur de la
                modernisation des Sépharades ottomans mais aussi le renforcement
                de la position des Chrétiens de l'Empire grâce au soutien qu'ils
                ont commencé à recevoir des puissances européennes ; ce sont ces
                puissances qui, à partir de la fin du XVIIIe siècle, contribuent
                de manière décisive, surtout parmi les populations grecques et
                arméniennes, à la consolidation des mouvements nationalistes
                dans les Balkans. Cette même situation leur permet également de
                remplacer progressivement les Juifs aux postes importants de
                l'économie - commerce international, banques, etc. La rivalité
                entre Chrétiens et Juifs s'aggrave et s'accompagne parfois
                d'accusations et de persécutions contre les Juifs, surtout là où
                l'autorité centrale est affaiblie et où les communautés sont
                soumises à l'arbitraire de gouverneurs locaux quasi autonomes ;
                c'est le cas, par exemple, en Égypte, où même les juifs du Caire
                sont temporairement exclus au cours du XVIIIe siècle, en
                Palestine et ailleurs.
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                Au XVIIe siècle, c’est au sein de la communauté sépharade de
                l'Empire ottoman que naît le cataclysme qui va secouer les
                communautés juives européennes : le mouvement messianique promu
                par le faux messie Sabetay Sevi (1626-1676), né à Smyrne, et qui
                s'est prolongé jusqu'au XVIIIe siècle. Le mouvement sabbatéen a
                germé dans le terreau fertilisé par les enseignements
                néo-kabbalistiques d'Isaac Luria à Safed, selon lesquels il est
                possible de hâter la rédemption qui doit survenir en 1648. Le
                mouvement se propage au-delà des frontières de l'Empire ottoman
                avec une force inhabituelle à travers les communautés sépharades
                d'Europe occidentale (Hollande et Italie) mais également au sein
                des communautés juives d'Europe centrale, c'est-à-dire dans le
                monde ashkénaze. Sevi attire des foules enthousiastes et gagne
                de nombreux adeptes, y compris d'éminents rabbins et érudits,
                tant sépharades qu'ashkénazes, dans toutes les communautés
                juives : d'Alep à Amsterdam, de Hambourg à Lemberg, en Italie,
                en Angleterre, en Égypte et en Afrique du Nord. Sous la pression
                du sultan Mehmed IV, qui le menace de décapitation, Sevi opte
                pour la conversion à l'islam, conversion dans laquelle il est
                suivi par un certain nombre de disciples, qui vont former une
                secte crypto-juive au sein de l'islam - les Dömnès - qui a
                perduré pendant des siècles, en particulier à Smyrne et à
                Salonique jusqu'au XXe siècle.
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                Au cours du XIXe siècle, les tensions entre Juifs et Chrétiens -
                Grecs et Arméniens - s’aggravent, tant pour des raisons
                commerciales que religieuses. Il convient de rappeler qu'en
                1830, une grande partie de la Grèce est devenue indépendante, ne
                laissant que la Macédoine et Salonique au sein de l'Empire
                jusqu'en 1912. Les villes où la population juive est la plus
                importante restent Istanbul, Izmir et surtout Salonique qui, à
                partir de la seconde moitié du XIXe siècle, atteint une
                population d'environ 75 000 âmes ; grâce à l'activité portuaire
                et au développement en Macédoine de l'industrie du tabac et de
                son importation, la ville parvient à se relever de
                l'effondrement de l'industrie textile lorsque l'armée des
                janissaires, principal destinataire des tissus thessaliens, est
                démantelée en 1826.
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                Les réformes entreprises dans l'Empire ottoman pour moderniser
                le pays et l'influence européenne croissante ont eu un impact
                sur le monde juif, entraînant des changements perceptibles dans
                le système éducatif et dans les habitudes quotidiennes. Dans le
                domaine de l'éducation, la création, dès le début du XIXe
                siècle, d'écoles chrétiennes européennes aux méthodes modernes a
                encouragé la communauté juive à suivre le mouvement, jusqu'à
                l'établissement dans l'Empire du réseau d'écoles de l'Alliance
                Israélite Universelle, une organisation fondée en France en
                1860. Son objectif était de fournir aux Juifs les moyens de
                s'intégrer dans le monde moderne ; cette activité éducative a
                permis l'émergence d'une élite culturelle plus laïque, dont
                beaucoup sont devenus les dirigeants des communautés.
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                D'autre part, en Turquie, qui était devenue un État-nation avec
                les frontières que nous connaissons aujourd'hui, les Juifs se
                sont vu accorder des droits civils égaux. Le développement des
                idées du sionisme politique a conduit à l'émergence en Turquie
                d'un grand nombre d'adhérents à la cause, qui se sont enracinés
                dans de larges sections de la communauté ; les sionistes ont
                trouvé leur contrepartie, en particulier dans la ville de
                Salonique, dans un mouvement ouvrier juif fort et bien établi,
                de tendance socialiste.
              </Typography>
              <Typography
                align="justify"
                variant="h6"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                1.3 Le déclin de l'Empire et l’émergence de la Turquie
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                Après le règne de Soliman, un très lent déclin de l'Empire
                s'amorce, du XVIe siècle au XXe siècle. Cependant, à l'aube du
                XIXe siècle, l'Empire ottoman règne encore sur de vastes
                territoires : en Europe, dans toute la péninsule balkanique, ce
                qui signifie la soumission des Roumains, des Bulgares, des
                Serbes, des Bosniaques, des Albanais et des Grecs ; en Asie, ses
                territoires comprennent l'Asie mineure, la Syrie, la Mésopotamie
                et l'Arabie ; et en Afrique, l'Égypte et la Tripolitaine.
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                Tout au long du XIXe siècle, l'intérêt d'autres puissances
                européennes - d'abord la France et l'Angleterre, puis
                l'Allemagne et l'Italie - a commencé à se tourner vers ces
                vastes territoires et, avec l'Autriche et la Russie, elles sont
                entrées en conflit avec la Sublime Porte dans la poursuite -
                toujours intéressée, comme en politique, bien sûr - de divers
                objectifs : obtenir leur propre part de ce que l'on appelle
                "l'homme malade de l'Europe"; obtenir des zones d'influence
                parmi les peuples chrétiens des Balkans, une poudrière prête à
                exploser ; et mettre un terme à l'influence des autres
                puissances européennes qui ont été trop gourmandes dans la
                répartition du gâteau convoité.
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                Les Sépharades de ces terres ont dû vivre, souffrir et
                participer à d'interminables périodes de guerres, parmi
                lesquelles je ne mentionnerai, parce qu'elles sont plus proches
                de nous, que celles qui ont éclaté dans le dernier tiers du XIXe
                siècle, c'est-à-dire la guerre d'indépendance d'une partie de
                l'Espagne, qui a été la première guerre du XIXe siècle :
                l'indépendance d'une partie de la Grèce actuelle, après une
                longue guerre qui a duré de 1821 à 1829 ; la guerre russo-turque
                de 1877-1878 ; la guerre italo-turque pour la Tripolitaine en
                1911 ; les guerres balkaniques de 1912-1913 ; et la Première
                Guerre mondiale entre 1914 et 1918, à l'issue de laquelle la
                Turquie, qui s'était alliée aux puissances perdantes, était sur
                le point de disparaître en tant que nation, un désastre auquel
                elle a échappé grâce à l'impulsion de Mustafa Kemal (Atatürk) et
                à sa victoire en 1922 sur les Grecs qui, avec l'approbation de
                la Grande-Bretagne, avaient occupé une grande partie de
                l'Anatolie en 1919, avec la ville d'Izmir comme principale base.
                L'accession au pouvoir de Kemal entraîne l'abolition du sultanat
                en 1922, puis du califat en 1924, et la proclamation de la
                République en 1923, qui se définit comme nationaliste, populaire
                et laïque ; quelques années plus tard, en 1932, le nouveau pays
                adhère à la Société des Nations, puis à son héritière,
                l'Organisation des Nations unies.
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                Outre les périodes de guerre que j'ai mentionnées, causées par
                des ingérences d’autres puissances, la Turquie a connu des
                bouleversements internes à l'aube du XXe siècle, le plus
                important étant la révolution des "Jeunes Turcs". En 1908, un
                soulèvement éclate contre le sultan Abdul Hamid II (1842-1918),
                qui peut être considéré comme le dernier des sultans ottomans.
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                La révolution Jeunes Turcs et la proclamation subséquente de
                l'égalité des droits civiques pour tous les sujets ottomans,
                sans distinction de religion ou de croyance, ont mis en lumière
                un problème qui couvait depuis les réformes des Tanzimat
                susmentionnées : le service militaire obligatoire pour tous les
                jeunes hommes ottomans. En ce qui concerne les Juifs, cette
                obligation était particulièrement pénible en raison de trois
                facteurs : les exigences d'un repas conforme à la religion ;
                leur méconnaissance - croyez-le ou non après quatre siècles - de
                la langue turque ; et ce que l'on craignait depuis le début et
                qui s'est avéré vrai : les Juifs conscrits n'effectuaient pas de
                véritable service armé, mais étaient surtout utilisés comme
                ouvriers au service de l'armée, dans des tâches telles que le
                défrichage et la construction de routes, l'ouverture de tunnels
                et de voies de communication, l'abattage de forêts, etc.
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                En 1912, à la fin de la première guerre balkanique, un événement
                d'une importance exceptionnelle pour l'histoire des Juifs de
                l'Empire ottoman a lieu : le transfert des mains des Turcs à
                celles des Grecs de la ville de Salonique, où la population
                juive était devenue majoritaire. Dès le début de leur occupation
                de la ville, les Grecs, qui, comme je l'ai déjà mentionné,
                avaient été les ennemis traditionnels des Juifs dans leur
                coexistence forcée sous l'Empire, font sentir leurs sentiments
                antijuifs, qui sont renforcés par l'arrivée à Salonique de Grecs
                de Thrace et d'Anatolie à l'occasion des échanges de population
                entre la Turquie et la Grèce, entrepris en 1922 après la guerre
                susmentionnée entre les deux pays.
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                Plusieurs événements graves touchent ensuite la communauté juive
                de Salonique, le plus notable d’entre eux étant le terrible
                incendie, que beaucoup considèrent comme criminel, qui, en 1917,
                au plus fort de la Première Guerre mondiale, réduit en cendres
                la vieille ville, principalement habitée par des Juifs. Cette
                catastrophe, qui pour de nombreux historiens a marqué le début
                de la fin de la riche et cultivée communauté sépharade de
                Salonique, a été aggravée par la politique gouvernementale
                d'expropriation massive de la zone sinistrée, qui a conduit à la
                ruine totale de milliers de familles juives, dépossédées, sans
                presque aucune compensation, de ce qui avait été leur propriété
                séculaire. L'antisémitisme grec, soutenu par la politique
                d'Eleftherios Venizelos, qui a violé à plusieurs reprises les
                droits civils des Juifs, atteint l'un de ses points culminants
                lors du pogrom déclenché dans la ville en 1931. La communauté
                juive grecque connaît des souffrances bien pires encore,
                puisqu'elle est presque entièrement détruite lors de la Seconde
                Guerre mondiale, avec l'occupation de la Grèce par les Allemands
                en 1942. Seuls 4 000 Juifs environ reviennent des camps
                d'extermination sur les quelques 60 000 qui avaient été arrachés
                à leur foyer par les nazis. Les Juifs de Turquie résistent
                mieux, car si les manifestations antisémites n'ont pas cessé
                pendant la guerre, la neutralité du pays, maintenue jusqu'à la
                veille de la fin du conflit, permet aux Juifs turcs d'échapper à
                des massacres généralisés.
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                Nombre des événements que j'ai mentionnés - le service
                militaire, le passage de Salonique aux mains des Grecs et son
                incendie, les guerres incessantes et les crises économiques
                permanentes qui en découlent - ont donné lieu, dès l'aube du XXe
                siècle, à un fort courant d'émigration de Sépharades des Balkans
                vers différents pays européens – l’Italie, l’Espagne et surtout
                la France -, vers l'Afrique et le Moyen-Orient -, en Afrique -
                surtout au Congo et en Rhodésie -, vers Israël (rappelons qu'en
                1917 a eu lieu la déclaration Balfour, qui a permis aux Juifs
                d'entrer en Palestine, alors sous mandat britannique) et surtout
                vers les deux Amériques. L'émigration a été principalement le
                fait de jeunes gens, porteurs d’un nouveau souffle de vie, loin
                du foyer traditionnel dans lequel les Sépharades avaient vécu
                pendant près de quatre siècles ; ces émigrants ont subi des
                transformations conséquentes que j'évoquerai plus tard en ce qui
                concerne leur héritage culturel hispanique. La période de
                l'entre-deux-guerres à laquelle j'ai fait référence est la
                troisième période que j'ai appelée « ouverte », car la
                communauté sépharade de l'ancien Empire ottoman s'ouvre à
                nouveau, mais cette fois-ci au monde entier et très peu à
                l'Espagne.
              </Typography>
              <Typography
                align="justify"
                variant="h5"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                I. La littérature
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                Avant de nous pencher sur la littérature sépharade, il est
                nécessaire de revenir sur quelques considérations préliminaires{" "}
                <HashLink to="#4" className="ftnLink" id="s4">
                  [4]
                </HashLink>
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                En général, on entend par littérature d'un peuple l'ensemble des
                œuvres littéraires produites par ce peuple dans sa propre langue
                et qui lui servent de moyen de communication. Un tel concept
                généralisant n'est cependant pas valable pour tous les peuples
                et il nous fait défaut lorsqu'il s'agit de la littérature des
                Sépharades dans son ensemble. À l'époque de l'expulsion, nous
                nous trouvons face à une culture qui peut être bilingue,
                c'est-à-dire à des individus généralement capables d'une
                production littéraire qui peut s'exprimer aussi bien en hébreu
                qu'en langues ibériques. Au fil des siècles, à ce bilinguisme
                initial, il faudra ajouter les langues des cultures
                environnantes au contact desquelles les Sépharades ont vécu et
                surtout celles apprises à l’école.
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                En tant que Juif, le Sépharade utilise l'hébreu et en fait usage
                dans nombre de ses œuvres ; mais cette partie de sa production
                doit en réalité être considérée comme partie intégrante de la
                littérature hébraïque générale. De la même façon Elias Canetti,
                Sépharade né en Bulgarie, ne peut être considéré comme un prix
                Nobel de littérature bulgare ou judéo-espagnole, cette dernière
                langue étant restée au seuil de son enfance, mais comme
                appartenant à la littérature allemande, qui est sa langue de
                création.
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                Quant à la production littéraire des Sépharades en langue
                espagnole, elle ne peut être considérée dans son ensemble comme
                un tout uniforme. De fait, les Juifs qui ont quitté la Péninsule
                non pas en 1492 mais plus tard, ont nécessairement subi une
                conversion forcée au christianisme. Déracinés pendant au moins
                une génération des pratiques juives et ayant vécu pleinement
                intégrés dans les environnements culturels espagnols et
                portugais, ils n'ont perdu le contact avec l'Espagne que des
                siècles plus tard, une fois exilés en Hollande et dans d'autres
                pays d'Europe occidentale, au cours du XVIIe siècle. Ils ont
                produit des œuvres dans un espagnol pratiquement identique à
                l'espagnol péninsulaire de l'époque, et leurs sources
                d'inspiration ne présentent aucune des spécificités que l'on
                retrouve dans celles produites par leurs frères balkaniques. Ce
                sont en fait des œuvres littéraires que l'on peut considérer
                comme celles d'Espagnols en exil. Ainsi en va-t-il par exemple
                des pièces écrites au XVIIe siècle par Enríquez Gómez, Leví de
                Barrios et d'autres auteurs qui doivent être regroupées avec
                celles du théâtre du Siècle d'or espagnol en ce qui concerne
                leur sujet et leur langue.
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                Au fil du temps, l'espagnol puis le portugais ont
                progressivement disparu en tant que langues littéraires et
                culturelles de ces communautés, cédant la place aux langues
                nationales : le néerlandais aux Pays-Bas, les dialectes italiens
                en Italie, le français dans le sud de la France, et plus tard
                l'allemand à Hambourg et l'anglais en Angleterre et aux
                États-Unis. La situation linguistique des colons sépharades dans
                les Balkans est tout à fait différente. D'une part, parce qu'ils
                n'ont pas été témoins des profonds changements survenus au XVIe
                siècle en espagnol ; d'autre part, parce que, isolés dans
                l'autonomie de leurs communautés, ils ne se sont pas sentis
                obligés d'apprendre les langues des environs, fondant leur
                identité nationale sur leur propre langue. Ainsi, au cours des
                XVIe et XVIIe siècles, leurs langues hispaniques se sont
                progressivement consolidées en une seule langue, qui, bien
                qu'avec ses propres variations internes, présentait un système
                indépendant de celui de l'espagnol, par rapport à la situation
                initiale dans laquelle les deux étaient des variétés du même
                système. Cela ne s'est pas produit, comme on l'a souvent dit,
                parce que la langue sépharade, le judéo-espagnol, était restée
                bloquée à ce point de départ, fossilisée dans sa situation
                initiale et en dehors des profonds changements subis par
                l'espagnol au cours des siècles qui ont suivi la séparation,
                mais parce que toutes deux, et la langue des Sépharades tout
                autant que l'espagnol, ont subi des changements jusqu'à ce
                qu'elles deviennent deux systèmes différents{" "}
                <HashLink to="#5" className="ftnLink" id="s5">
                  [5]
                </HashLink>
                .
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                Et pour en revenir à la littérature, de tout ce tableau complexe
                que j'ai esquissé, je ne vais, par définition, que traiter
                exclusivement ici des œuvres produites par les Sépharades dans
                la langue à laquelle je viens de faire référence, le
                judéo-espagnol, qui, à partir des différentes modalités des
                langues hispaniques de la fin du XVe siècle et avec les
                évolutions et les changements normaux d'une langue vivante, leur
                a servi de langue véhiculaire de communication orale et écrite
                pendant plus de cinq siècles. Nous définissons donc la
                littérature sépharade que nous allons étudier comme la création
                littéraire des Juifs originaires d'Espagne ou assimilés,
                produite ou transmise en judéo-espagnol depuis les premières
                générations d'expulsés jusqu'à nos jours.
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                On peut distinguer deux aires géographiques concernant la
                production littéraire sépharade. D'une part l'Empire ottoman et
                les pays issus de son démembrement, auxquels il faut ajouter la
                ville de Vienne. Ses principaux centres d'édition sont
                Constantinople, Salonique, Smyrne, Jérusalem, Sofia, Belgrade,
                Alexandrie, Vienne, etc. Dans toute cette zone de l'Europe de
                l'Est, une grande partie de la littérature originale est
                produite en judéo-espagnol, et les genres issus de la
                transmission orale survivent également dans cette zone. La
                deuxième zone est l'Afrique du Nord, principalement le Maroc,
                dont je ne parlerai pas ici, car si les genres issus de la
                transmission orale y ont survécu avec vigueur, il n'y a
                pratiquement pas de production littéraire écrite en haketiya,
                nom donné au judéo-espagnol du détroit de Gibraltar.
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                En raison du rôle important joué par leurs imprimeries, il faut
                également mentionner certaines villes italiennes, comme Pise et
                Venise dans la première période, et à toutes les époques la
                ville de Livourne, où furent réimprimés un grand nombre
                d'ouvrages publiés dans les Balkans, qui furent largement
                diffusés parmi les lecteurs sépharades du Maroc et de Gibraltar,
                où il n'y avait pas d'imprimeries jusqu'à une époque très
                récente. Livourne a ainsi joué un rôle de pont culturel entre
                les Sépharades d'Europe de l'Est et ceux d'Afrique du Nord.
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                Comme nous le verrons, le développement de la littérature
                judéo-espagnole est souvent conditionné par les événements
                historiques et sociaux traversés par la diaspora sépharade. À
                cet égard, il convient de souligner quatre jalons importants qui
                détermineront trois étapes différentes de sa production. Ces
                étapes sont étroitement liées à certaines des situations
                historico-culturelles que j'ai déjà mentionnées et,
                chronologiquement, nous pouvons les situer au XVIe siècle, en
                tant que période d'installation, au début du XVIIIe siècle, au
                milieu du XIXe siècle et au XXe siècle.
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                Avant d'entrer dans le vif du sujet, il convient de revenir sur
                deux points. Le premier est que cette littérature a été écrite
                (pratiquement) jusqu'à la Seconde Guerre mondiale en lettres
                hébraïques principalement, de sorte que pour diffuser ses textes
                à un public hispanique, l'érudit moderne doit les soumettre à un
                processus de transcription en lettres
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                Le second vient établir une certaine classification dans
                l'ensemble du matériel littéraire que nous connaissons. On peut
                distinguer deux niveaux dans la littérature judéo-espagnole,
                observée dans son ensemble et en fonction de son inspiration, de
                son sujet et de sa finalité : le religieux et le profane.
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                Il faut tenir compte du fait que pour les Sépharades, comme pour
                les Juifs en général, le "religieux" est une catégorie qui
                transcende le purement théologique ou doctrinal, qui affecte
                sans limites précises tous les actes quotidiens, et englobe tout
                ce qui fait partie de l'héritage mental et expérientiel du Juif,
                accumulé au cours des générations. Ainsi comprise, la
                quasi-totalité de la littérature sépharade produite jusqu'au
                renouveau culturel du milieu du XIXe siècle est une littérature
                religieuse, mais en vertu des considérations qui précèdent, elle
                devrait plutôt être qualifiée de littérature patrimoniale. Il
                s'agit d'une littérature juive de contenu et de forme purement
                hispaniques qui satisfait le désir sépharade de meldar,
                c'est-à-dire de lire ou d'étudier toutes sortes de livres
                d'inspiration religieuse qui traitent à la fois des normes et
                des pratiques du judaïsme et de ses principes moraux, le tout
                sous l'égide des sources hébraïques traditionnelles communes à
                tous les Juifs.
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                À partir du milieu du XIXe siècle, on assiste non pas à la fin
                de la littérature patrimoniale mais au début de la coexistence
                avec les genres "séculiers" nouvellement adoptés. Cette
                littérature d'adoption est d'inspiration essentiellement
                française et s'exprime dans un néo-judéo-espagnol très évolué et
                éloigné de son antécédent hispanique profane.
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                Parallèlement aux genres patrimoniaux et adoptés, et parfois
                entrelacés avec eux, court le courant ininterrompu de la
                littérature transmise, c'est-à-dire des genres traditionnels de
                transmission essentiellement orale, comme le <em>romancero</em>,
                le
                <em>cancionero</em>, le <em>refranero</em> et la{" "}
                <em>
                  cuentística popular{" "}
                  <HashLink to="#6" className="ftnLink" id="s6">
                    [6]
                  </HashLink>
                </em>
                , dont je ne parlerai pas ici.
              </Typography>
              <Typography
                align="justify"
                variant="h6"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                2.1 La production littéraire du XVIe siècle et les traductions
                de la Bible
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                Le XVIe siècle voit l'installation des nouvelles communautés
                issues des expulsions d'Espagne, et leur production littéraire
                vise essentiellement à fournir aux Sépharades ne maîtrisant pas
                l'hébreu les outils nécessaires pour mieux se conformer à la
                pratique juive et aux rites synagogaux. Durant cette période, où
                la production en langue hébraïque est très abondante, ce qui est
                écrit en judéo-espagnol se limite le plus souvent à des
                traductions de cette langue. Il s'agit d'ouvrages dont les
                thèmes peuvent être classés sous la rubrique générale de{" "}
                <em>hochmat Yisrael</em> "sagesse du peuple juif", leurs sources
                d'inspiration étant celles de la pensée juive traditionnelle. La
                plupart de ces ouvrages ont été publiés dans la seconde moitié
                du siècle et étaient sans doute destinés à rééduquer les
                convertis qui affluaient d'Europe occidentale vers l'Empire
                ottoman.
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                Parmi les ouvrages traduits et originaux, certains sont parus à
                Constantinople, comme le livre de règles religieuses{" "}
                <em>Hilchot sehita</em> (vers 1510), sur les règles de
                l'observance religieuse dans l'Empire ottoman. La traduction par
                Yosef Firmon de l'ouvrage moral <em>Hovot halevavot</em> (1569)
                de l'auteur judéo-espagnol du XIIe siècle Bahia ibn Paquda, et
                l'ouvrage moral <em>Yihus hasadiquim</em> (vers 1595), version
                du livre hébreu publié dans cette ville vers 1515 ou 1520 sur le
                lieu de sépulture des justes en Israël. D'autres ont vu le jour
                à Salonique, comme le traité de dispute judéo-chrétienne{" "}
                <em>Fuente Clara</em> (1595){" "}
                <HashLink to="#7" className="ftnLink" id="s7">
                  [7]
                </HashLink>
                , et le <em>Shulhan hapanim</em> ou Table de l'âme (1568), avec
                la traduction des traités <em>Yoreh deah</em> et{" "}
                <em>Orah hayim</em> du code religieux normatif{" "}
                <em>Shulhan aruch</em> par le Sépharade du XVIe siècle Yosef
                Caro, publié pour la première fois en hébreu à Venise en 1565,
                c'est-à-dire seulement trois ans plus tôt.
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                Bon nombre des versions susmentionnées sont réalisées à l'aide
                d'un système de traduction appelé calque, qui présente des
                caractéristiques spécifiques et une fonction clairement définie
                : celle de mieux refléter le caractère sacré de la source
                hébraïque, en particulier dans le cas des textes bibliques et
                liturgiques ; le système suit les anciens systèmes de traduction
                développés avant l'expulsion.
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                Ce processus quasi chimique de traduction servile, dont je
                n'aborderai pas ici le système, est encore plus évident dans les
                traductions des textes hébreux nécessaires au service synagogal,
                c'est-à-dire la Bible, les prières et les poèmes liturgiques des
                prières quotidiennes et des fêtes, ainsi que d'autres ouvrages
                particulièrement importants pour la vie religieuse, comme le
                traité mishnique de morale <em>Pirquei Avot</em> et la{" "}
                <em>Haggadah</em>, qui doit être lue dans les maisons juives
                pendant le repas festif de la nuit de la Pâque. Je ne traiterai
                ici que très brièvement des traductions bibliques qui, bien que
                commençant au XVIe siècle, se prolongent bien au-delà de ce
                siècle.
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                Le plus ancien livre biblique qui nous soit parvenu en
                traduction est le livre des Psaumes, publié à Constantinople
                vers 1540. Sept ans plus tard, en 1547, le texte complet du
                <em>Pentateuque</em> y est publié dans sa fameuse version
                trilingue en hébreu, en grec moderne et en judéo-espagnol.
                Quelque temps plus tard, et probablement dans le but d'achever
                l'œuvre commencée à Constantinople, le travail d'édition est
                repris à Salonique. Parmi les livres publiés entre 1568-1572 et
                1583-1585, nous avons conservé <em>Isaïe, Jérémie, Ezéchiel</em>
                , deux éditions des
                <em>
                  Premiers Prophètes et Psaumes, Proverbes, Job et Daniel
                </em>{" "}
                ; la plus ancienne édition connue du{" "}
                <em>Cantique des Cantiques</em> date de Salonique en 1600.
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                Les Sépharades d'Europe occidentale ne sont pas étrangers à la
                tâche de traduction biblique, dont je ne mentionnerai que les
                suivantes : En 1553, Yom Tov Atías (Jerónimo Vargas) et Abraham
                Usque (Duarte Pinel) impriment à Ferrare une traduction complète
                de la Bible en lettres latines. Des traductions espagnoles de
                certains livres sont également réalisées à Amsterdam au cours du
                XVIIe siècle{" "}
                <HashLink to="#8" className="ftnLink" id="s8">
                  [8]
                </HashLink>
                . Nous ne mentionnerons que le Pentateuque de Menasseh ben
                Israël et ses différentes rééditions.
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                Pour en revenir à l'Orient, le besoin d'une nouvelle édition de
                la Bible en judéo-espagnol se fait ressentir au XVIIIe siècle.
                C'est ainsi qu'entre 1739 et 1745 paraît à Constantinople la
                version d'Abraham Asa, la première en caractères hébraïques
                comprenant l'ensemble de la Bible. L'édition est menée à bien
                malgré les problèmes qui président à son apparition : tout
                d'abord, des désaccords personnels entre l'éditeur Yonah
                Ashkenazi et le Vénitien Binyamin Rossi, son partenaire dans
                cette grande entreprise et au nom duquel est délivrée la
                dispense du rabbinat de Constantinople pour l'édition du livre.
                Par la suite, le problème est le manque constant d'argent, qui
                paralyse parfois l'impression. La traduction d'Asa marque un
                tournant dans les versions de la Bible et sert de base à toutes
                les éditions traditionnelles produites aux XVIIIe et XIXe
                siècles chez les Sépharades orientaux.
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                Au début du XIXe siècle (1813-1815), une nouvelle version
                complète de la Bible est publiée à Vienne, celle de Yisrael B.
                Hayim, qui est également largement diffusée parmi les Sépharades
                d'Afrique du Nord. Enfin, en 1838, une autre nouvelle version de
                la Bible complète est publiée à Smyrne, cette fois par
                l'ecclésiastique protestant allemand Wilhelm Gotlib Schawfler ;
                et aussi étrange que cela puisse paraître, compte tenu de son
                auteur, nous pouvons considérer cette traduction comme la
                dernière réalisée parmi les Sépharades d'Europe de l'Est. Il
                convient toutefois de mentionner une autre version
                judéo-espagnole de l'ensemble du texte biblique qui représente
                un esprit nouveau et différent de la version sépharade ; je veux
                parler de celle réalisée par la Mission protestante, différente
                des versions sépharades surtout par sa langue, qui voit le jour
                à Constantinople (1873) et qui jouit d'une diffusion
                remarquable, étant rééditée à Constantinople en 1895 et en 1905.
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                En raison de leur intérêt particulier, il convient de mentionner
                les seules œuvres de création libre écrites en{" "}
                <em>Aljamiado</em> au cours du XVIe siècle, toutes sous la plume
                de Moïse Almosnino (Salonique 1518 - 1580) ; Il s'agit du{" "}
                <em>Regimiento de la vida</em>
                (Salonique 1564), qui comporte en annexe le{" "}
                <em>Tratado de los sueños</em>, le Traité scientifique{" "}
                <em>Tratado del estrolabio</em>
                (manuscrit, écrit en 1560) et le{" "}
                <em>Canon de reloj de plata</em>
                (manuscrit, écrit en 1570), ainsi que la{" "}
                <em>Crónica de los reyes otomanos</em> (manuscrit, écrit avant
                1567){" "}
                <HashLink to="#9" className="ftnLink" id="s9">
                  [9]
                </HashLink>
                . Outre leur intérêt littéraire, ces œuvres présentent également
                un intérêt linguistique en raison de la langue dans laquelle
                elles sont écrites : il s'agit ici d'un proto-judéo-espagnol,
                très proche du castillan contemporain, bien qu'il contienne déjà
                certains des traits que l'on retrouvera plus tard pleinement
                développés dans la langue sépharade des siècles suivants.
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                Nous pouvons également considérer comme librement créées, bien
                que dans le cadre religieux, certaines <em>responsas</em>{" "}
                écrites en judéo-espagnol par les rabbins de la première
                génération d'expulsés et dispersées dans des ouvrages rédigés
                pour la plupart en hébreu.
              </Typography>
              <Typography
                align="justify"
                variant="h6"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                2.2 Le XVIIIe siècle, âge d'or de la littérature judéo-espagnole
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                Après le silence du XVIIe siècle, dont presque tout ce qui nous
                est parvenu en judéo-espagnol est constitué de réimpressions
                d'ouvrages imprimés au XVIe siècle et d'autres passages insérés
                dans des livres de responsa, nous entrons dans le XVIIIe siècle,
                à partir duquel, à un rythme inhabituel, les presses sépharades
                commencent à produire des centaines d'ouvrages en
                judéo-espagnol, pour la plupart de création libre, une
                production qui durera jusqu'à une bonne partie du XXe siècle. Ce
                boom littéraire a été provoqué par plusieurs facteurs
                historiques, dont nous mentionnerons les plus pertinents.
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                Au milieu du XVIIe siècle, un événement s'est produit au sein
                des communautés balkaniques, qui allait avoir un impact très
                important sur leur développement culturel. Il s'agit de
                l'émergence et de la fin frustrante du mouvement
                pseudo-messianique de Sabetay Sevi (Smyrne, 1629). Les
                polémiques entre adeptes et détracteurs du faux messie ont
                produit de grandes dissensions au sein des communautés, divisant
                leurs membres en camps irréconciliables.
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                Les Sépharades d'Orient entament le XVIIIe siècle sous le poids
                de la frustration laissée par l'échec cuisant de leurs espoirs
                messianiques. La fin malheureuse du cataclysme qui a secoué
                l'Europe juive du XVIIe siècle a laissé une marque indélébile
                due au découragement général et, pire encore, a métamorphosé le
                visage des communautés, à présent divisées. Cette situation
                coïncide également avec le déclin progressif des études
                rabbiniques en hébreu qui, pour diverses raisons, s'est produit
                depuis un certain temps dans les communautés sépharades du monde
                balkanique, ce qui signifie que peu de gens du peuple peuvent se
                plonger dans l'étude des sources classiques du judaïsme qu'ils
                ont étudiées. La langue hébraïque est devenue la chasse gardée
                d'une élite de rabbins, et les livres hébraïques dont les gens
                simples ont hérité de leurs ancêtres sont oubliés car
                indisponibles. Avec le manque de lecture de livres religieux,
                l'ignorance du peuple de ses propres fondements et traditions
                augmente.
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                Face à ces faits et afin de réveiller l'intérêt pour les valeurs
                religieuses traditionnelles, les seules à l'époque qui méritent
                d'être considérées comme des valeurs culturelles, un groupe
                d'auteurs, tous dotés de vastes connaissances rabbiniques,
                s'attellent à la tâche de fournir à leurs coreligionnaires un
                enseignement religieux-patrimonial juif afin de les affermir
                dans leur propre identité et aussi de protéger le peuple contre
                de potentiels futurs troubles, semblables à celui qu’était le
                sabbataïsme. Cette transmission de valeurs ne peut se faire en
                hébreu, mais doit se faire dans une langue compréhensible de
                tous, le judéo-espagnol. I. M. Hassán a vu un parallèle avec
                l'attitude du roi de Castille, Alphonse X le Sage, lorsqu'il a
                encouragé la traduction en langue vernaculaire des connaissances
                qui, jusqu'alors, étaient généralement exprimées en latin.
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                Grâce au travail de ce groupe de rabbins, qui se sentent
                investis d'une même mission de rééducation populaire, commence
                ce que nous considérons comme le Siècle d'Or de la littérature
                judéo-espagnole.
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                Il convient de souligner que tout ce qui a été produit dans le
                cadre de ce renouveau culturel est teinté du ton commun du
                religieux, c'est-à-dire tout ce qui fait partie de l'héritage
                mental et expérientiel du Juif, accumulé au fil des générations.
                Ces rabbins ont cherché à approcher l'esprit du Juif sépharade
                de leur temps par deux voies qui tendent vers la même finalité :
                la « culturalisation » religieuse. En premier lieu, il fallait
                lui donner une compréhension du texte biblique et de la
                liturgie, et c'est ainsi qu'Abraham Asa a réalisé les versions
                de la Bible et du livre de prières mentionnées plus haut. Mais
                il faut aussi lui fournir des textes en judéo-espagnol pour
                satisfaire ce besoin qu'éprouve tout Juif de <em>meldar</em>,
                c'est-à-dire de lire et d'étudier toutes sortes de textes
                religieux.
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                Le renouveau littéraire est également favorisé par deux autres
                facteurs dont il faut tenir compte. D'une part, à cette époque,
                des familles sépharades, enrichies par le commerce, se sont
                imposées grâce au vent de prospérité économique qui a soufflé
                sur l'Empire ottoman au cours du XVIIe siècle et à
                l'établissement dans de nombreuses villes, notamment portuaires,
                de ce que l'on appelle les <em>Compagnies du Levant</em> de
                diverses nations européennes ; ces familles deviendront, à
                plusieurs reprises, les patrons d'édition de nos auteurs.
                L'autre facteur important est l'amélioration des techniques
                d'édition développée vers les années 1830, principalement à
                Constantinople, par une famille de Juifs d'Europe centrale, les
                Ashkenazi, fuyant les persécutions qui faisaient rage contre le
                judaïsme en Pologne à l'époque. L'activité d'imprimeur de Yonah
                Ashkenazi fait de Constantinople la métropole de l'imprimerie
                hébraïque dans toute la Méditerranée et, après sa mort en 1745,
                ses fils Nissim et Reuven poursuivent l'œuvre de leur père.
                C'est ainsi que, par les hasards de la fortune, un Ashkénaze
                devient le plus ardent promoteur des éditions sépharades les
                plus spirituelles et le compagnon de leurs auteurs dans leurs
                peines et leurs travaux.
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                Nous avons donc les trois éléments nécessaires à l'émergence du
                livre : l'auteur, le financement et la technique d'édition.
                C'est avec la combinaison de ces trois facteurs que commence ce
                que nous considérons comme l'âge d'or de la littérature
                judéo-espagnole.
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                Nous ne pouvons pas évoquer ici les nombreux ouvrages publiés
                par les Sépharades tout au long du XVIIIe siècle sur les normes
                religieuses, la morale et d'autres connaissances rabbiniques,
                qu'ils aient été traduits de l'hébreu ou qu'ils aient été créés
                de toutes pièces. Je ne ferai référence que premièrement au
                travail de traduction d'Abraham Asa, déjà cité, et aux œuvres
                les plus représentatives du renouveau littéraire en langue
                sépharade, deuxièmement au <em>Meam loez</em> - une œuvre qui
                devient un genre -, et troisièmement aux <em>Coplas</em> - un
                genre qui prend forme dans de nombreuses publications. Ccs deux
                derniers vont au-delà du XVIIIe siècle et continuent d'être
                productifs au cours des siècles suivants.
              </Typography>
              <Typography
                align="justify"
                variant="h6"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                2.2.1 Abraham Asa et son travail de traduction
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                En ce qui concerne Asa, on dispose de peu d'informations sur sa
                vie. On suppose qu'il est né à Constantinople vers 1710, et
                c'est là que paraissent les premières éditions de ses œuvres. La
                date de sa mort a été fixée à 1770 environ.
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                Outre la version de la Bible mentionnée ci-dessus, il a publié
                neuf livres à Constantinople entre 1729 et 1762, dont la plupart
                ont été réimprimés par la suite. Le premier de la série est
                <em>Lettres de Rabbi Aquiva</em> (1729), une version du livre
                kabbalistique Otiyot de rabbi Aquiva. Il est suivi par son{" "}
                <em>Sefer Sorche sibur</em> (1733), dans lequel il formule en
                prose rimée un ensemble de règles religieuses ; et ses
                traductions de l'oraison quotidienne du <em>Bet Tefillah</em>{" "}
                (1739), et du livre de fiction historique{" "}
                <em>Sefer Ben Gorion</em> (1743), dans lequel il a incorporé une
                histoire succincte des sultans ottomans. Parmi les autres
                ouvrages traduits par Asa figurent le livre de lectures morales
                <em>Sebet musar</em> (vers 1740), écrit en hébreu par le rabbin
                sépharade Eliyahu Hakomen, le traité <em>Orah hayim</em> du{" "}
                <em>Shulhan aruch</em>, paru sous le titre Shulhan hamelech
                (1749), et le <em>Menorat hamaor</em>
                (1762), datant de la fin du XIVe siècle, écrit par l'auteur
                judéo-espagnol Yishak Aboab.
              </Typography>
              <Typography
                align="justify"
                variant="h6"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                2.2.2 Le <em>Meam loez</em>
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                <em>Parmi</em> les œuvres littéraires produites en
                judéo-espagnol à l'aube du XVIIIe siècle, le Meam loez se
                distingue par son importance. Il est l'un des ouvrages les plus
                représentatifs de la renaissance littéraire, combinant la
                version judéo-espagnole de textes hébraïques avec une création
                libre en langue sépharade.
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                Son initiateur est Yaacov Hulí, né en Palestine en 1690 et mort
                à Constantinople en 1732, à l'âge de 42 ans. Les mots qui
                donnent son titre à l'ouvrage, que l'on peut traduire par "Du
                peuple qui ne parle pas l'hébreu", sont tirés du premier verset
                du psaume 114, qui fait partie de la prière appelée Halel, qui
                fait aussi partie de la <em>Haggadah de Pessah</em> ; ce verset
                114 : 1 se lit :{" "}
                <em>Beset Yisrael miMisráyim, bet Yaacov meam loez</em> ("Quand
                Israël est sorti d'Egypte, la maison de Jacob d'un peuple
                barbare"), c'est-à-dire d'un peuple de langue barbare ou
                étrangère, ou comme le traduisent les bibles sépharades :
                "peuple ladin". Il ne fait aucun doute que notre auteur a choisi
                un tel verset pour deux raisons : d'une part, parce qu'il
                reflète l'idée fondamentale que son œuvre s'adresse à ceux qui
                ne connaissent pas l'hébreu ; d'autre part, parce qu'au verset 7
                du même psaume 114, nous lisons :{" "}
                <em>Milifné Adón hulí ares ; milifné Eloah Yaacov</em> ("En
                présence du Seigneur créateur de la terre ; devant le Dieu de
                Jacob"), mots hébreux dans lesquels nous trouvons hulí qui est
                le nom de famille de notre auteur et Yaacov qui est son prénom.
                Le fait d'intituler les œuvres avec des passages bibliques qui
                incluent les noms des auteurs est une pratique très répandue
                dans le monde juif ; l'habileté de Hulí dans ce cas réside dans
                l'utilisation d'un court psaume, dont les huit versets sont
                inclus dans la Haggadah de Pessah - ce qui signifie qu'il n'y a
                pas de juif, aussi ignorant soit-il, qui ne les connaisse par
                cœur - dans lequel notre auteur trouve trois choses :
                l'intention et le titre de son œuvre, son prénom et son nom de
                famille. Si j'ai insisté sur ce point, c'est pour éviter
                l'erreur commise par bon nombre de spécialistes de la
                littérature sépharade qui s'obstinent à lire le nom de famille
                de notre auteur comme <em>Kuli, Kulí</em> et autres formes tout
                aussi erronées.
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                Ya'akov Huli, soucieux de fournir à ses coreligionnaires un
                livre qui les sortirait de leur ignorance de la loi et des
                pratiques du judaïsme et leur redonnerait le goût de la lecture
                de la littérature religieuse, conçoit son œuvre comme un vaste
                commentaire de la Bible dans lequel il y aurait place pour tout
                le matériel législatif, légendaire, historique, anecdotique et
                folklorique, moral et mystique, etc. que le commentaire
                rabbinique précédent avait développé et que l'auteur est en
                mesure d'apporter dans l'illustration de chaque verset ou de
                chaque passage du texte biblique. Si le but de Hulí est
                d'enseigner en réjouissant, l'essentiel pour lui est cependant
                de transmettre la connaissance de la norme religieuse ou
                <em>halakhah</em>. Plus qu'un livre, le <em>Meam loez</em> est
                un système de commentaires auquel une douzaine d'auteurs
                différents se sont appliqués pendant plus d'un siècle et demi.
                Il en résulte une sorte d'encyclopédie du savoir juif
                traditionnel en général et sépharade en particulier, dans
                l'élaboration de laquelle on peut distinguer trois étapes : le{" "}
                <em>Meam loez</em> classique (XVIIIe siècle), le{" "}
                <em>Meam loez</em> de transition (seconde moitié du XIXe siècle)
                et le nouveau Meam loez (fin du XIXe siècle), séparés par deux
                périodes intermédiaires où l'on ne produit rien de nouveau et où
                l'on ne réimprime que l'ancien.
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                Le <em> Meam loez</em> que nous appelons classique a été publié
                entre 1730 et 1777 à Constantinople et comprend le commentaire
                des cinq livres du <em> Pentateuque</em> . Ya'akov Huli écrit le
                livre de la Genèse (1730) et la moitié de l'Exode (1733). À sa
                mort, Yishak Magriso prend la plume et reprend le flambeau là où
                son prédécesseur l'avait laissée. Il achève l'<em> Exode</em>{" "}
                (1746-1747)
                <HashLink to="#10" className="ftnLink" id="s10">
                  [10]
                </HashLink>{" "}
                et développe le commentaire du Lévitique (1753){" "}
                <HashLink to="#11" className="ftnLink" id="s11">
                  [11]
                </HashLink>{" "}
                et des <em> Nombres</em> (1764). Yishak Argüeti poursuit la
                tâche avec le commentaire du <em> Deutéronome</em> , dont seules
                les trois premières des onze péricopes du livre paraissent
                (1773), bien que l'on puisse supposer que le reste du
                commentaire était déjà écrit et en manuscrit.
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                Bien qu'il ait été rédigé par trois auteurs différents, le{" "}
                <em>Meam loez</em> du Pentateuque présente une remarquable
                uniformité, peut-être en raison d'une série de caractéristiques
                communes à tous, la plus importante étant la solide culture
                rabbinique de ses auteurs.
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                Huli et ses disciples immédiats utilisent dans leur manière
                d'élaborer le commentaire un système qui est intimement lié à
                l'esprit et à la forme du commentaire rabbinique traditionnel.
                C'est en fait la tradition rabbinique qui parle par trois
                bouches différentes. Ainsi, par l'apport des sources ou par ses
                propres commentaires, le texte biblique est utilisé pour parler
                des institutions, des normes et des rites du judaïsme, de son
                histoire et de sa légende, des règles de coexistence et
                d'hygiène, des principes moraux et du messianisme, pour donner
                des nouvelles de la géographie et de l'histoire des nations, de
                l'astronomie, de la météorologie, de l'agronomie, des sciences
                naturelles, de la prophylaxie et de la magie, et pour fournir
                des données folkloriques et documentaires sur les communautés
                sépharades.
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                Les différences que l'on peut noter entre certains livres et
                d'autres sont davantage dues à la matière à commenter qu'à une
                conception différente du commentaire de leurs auteurs
                respectifs.
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                Le <em>Meam loez</em> classique a connu une diffusion
                inhabituelle : ses volumes ont été réimprimés de nombreuses
                fois, la Genèse occupant la première place avec dix
                réimpressions. Rares sont les foyers sépharades où l'on ne
                trouve pas au moins un volume du <em>Meam loez</em>, ni un
                homme, une femme ou un enfant qui n'en ait pas lu ou n'en ait
                pas fait lire des passages. Bien que de grand format, il est
                devenu le livre de chevet de nombreux lecteurs qui y trouvent
                une source inépuisable d'illustrations et de distractions. Pour
                illustrer l'estime dont jouit le <em>Meam loez</em>, citons ces
                exemples de villes de l'Empire ottoman, où les pères avaient
                l'habitude d'offrir à leurs filles, en guise de dot exclusive,
                les livres du <em>Meam loez</em> du <em>Pentateuque</em>.
                L'année 1851 marque le début de ce que nous avons appelé la
                phase de transition, au cours de laquelle sont rédigés de
                nouveaux commentaires qui, par leur contenu, leur style,
                l'utilisation abondante de sources rabbiniques, etc. sont très
                proches de ceux du <em>Meam loez</em> classique, mais dans
                lesquels commencent déjà à apparaître certaines des
                caractéristiques du nouveau. Cette période est représentée par
                trois livres : le <em>Meam loez</em> de Josué de Menahem Mitrani{" "}
                <HashLink to="#12" className="ftnLink" id="s12">
                  [12]
                </HashLink>{" "}
                , publié en deux parties (Salonique 1851 et Smyrne 1870) ; le{" "}
                <em>Meam loez</em> d’Esther de Refael Pontremoli (Smyrne 1864) ;
                et le <em>Meam loez</em> de Ruth (Salonique 1882), écrit par
                Rafael Y. Benveniste.
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                Après une période de douze ans, commence la phase des nouveaux
                <em>Meam loez</em>, dont certains auteurs n'ont pas fait
                d'études rabbiniques, mais sont des intellectuels issus d'une
                nouvelle époque, fruit des "lumières" sépharades et des écoles
                de l'Alliance Israélite Universelle, dont nous parlerons plus
                loin, dont la formation religieuse est bien différente de celle
                forgée dans les écoles rabbiniques du XVIIIe siècle.
                L'appartenance de certains de ces ouvrages à la série du{" "}
                <em>Meam loez</em> n'est pas tant déterminée par le style et la
                forme du commentaire que par le fait que leurs auteurs se
                sentent liés à la méthode et à l'œuvre initiées par Hulí, ce qui
                est dit dans les premières pages et dans les introductions, ou
                peut être déduit des titres eux-mêmes.
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                Le commentaire d'Isaïe (Salonique 1892) de Yishak Y. Abba fait
                partie de cette étape de rédaction. Abba, suivi, en l'espace de
                cinq ans par deux commentaires différents de{" "}
                <em>l'Ecclésiaste</em> : celui de Shelomo Hakohen, intitulé{" "}
                <em>Hesek Shelomo</em> (Jérusalem 1893)
                <HashLink to="#13" className="ftnLink" id="s13">
                  [13]
                </HashLink>
                , et celui de Nisim M. Abud sous le nom de{" "}
                <em>Osar hahochmah</em>
                (Constantinople 1898). Hayim Y. Saky avec son commentaire sur le
                <em>Cantique des Cantiques</em> (Constantinople 1899).
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                Outre les changements prévisibles dans la langue et le style,
                d'autres différences importantes distinguent ce{" "}
                <em>Meam loez</em> des étapes ultérieures du classique. Nous ne
                pouvons les étudier ici, mais soulignons au moins que le cas
                extrême est celui du
                <em>Meam loez</em> du <em>Cantique des Cantiques</em>, dans
                lequel nous trouvons surtout les commentaires inspirés à son
                auteur, un érudit moderne, par la lecture du livre poétique,
                compris comme l'histoire de l'idylle entre Dieu et Israël.
              </Typography>
              <Typography
                align="justify"
                variant="h6"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                2.2.3 Les <em>Coplas</em>
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                Si le Meam loez confère au judéo-espagnol la qualité de langue
                apte à la création libre en prose et plus seulement, comme il
                l'avait été jusqu'alors, langue de traduction, au XVIIIe siècle
                également, le judéo-espagnol se consolide, ou du moins se montre
                consolidé, pour la création littéraire en vers et s'exprime en
                tant que tel dans le genre poétique des <em>coplas</em>{" "}
                <HashLink to="#14" className="ftnLink" id="s14">
                  [14]
                </HashLink>
                .
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                Nous pouvons sans aucun doute affirmer que si nous examinons les
                différentes sources poétiques de la littérature sépharade dans
                son ensemble - les <em>coplas</em>, le <em>romancero</em>, la
                poésie lyrique, les hymnes liturgiques et la poésie moderne de
                l'auteur - c'est dans les <em>coplas</em> que se trouve la
                production poétique sépharade la plus authentique, et qui est
                donc le genre le plus représentatif.
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                J'expliquerai cette affirmation pour les raisons évidentes
                suivantes, qui ont trait à la fois au contenu et aux structures
                poétiques. Les ballades sépharades font principalement partie
                des ballades hispaniques et décrivent un monde d'expériences
                étrangères au monde juif sépharade. Sa poésie lyrique
                traditionnelle s'inspire des formes poétiques de l'espagnol
                ancien et moderne et des peuples balkaniques environnants, et
                exprime des sentiments qui sont caractéristiques de tout être
                humain et ne sont en aucun cas exclusifs au monde sépharade. La
                poésie liturgique judéo-espagnole consiste pour l'essentiel en
                des traductions plus ou moins fidèles de poèmes liturgiques
                hébraïques, même si, en raison des difficultés rencontrées, ces
                versions ne correspondent généralement pas aux schémas
                strophiques des originaux. Quant à sa poésie d'auteur moderne,
                elle exprime également - sauf dans des cas très spécifiques,
                comme la poésie dite de l'Holocauste - des thèmes communs à
                toute créature vivante et, en outre, dans ses grandes lignes
                structurelles, elle a suivi et continue de suivre les modes et
                les courants de la littérature générale de son époque et en
                particulier de la littérature français
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                En revanche, les <em>coplas</em>, dans leurs lignes strophiques,
                sont généralement enracinées dans la <em>coplística</em>{" "}
                médiévale espagnole{" "}
                <HashLink to="#15" className="ftnLink" id="s15">
                  [15]
                </HashLink>{" "}
                et, en raison de leur sujet, de leur fonction et même de
                certaines structures nouvelles - comme c'est le cas des
                octosyllabes, des ennéasyllabes et des hexasyllabes -, elles
                constituent une création unique du groupe : elles ont été
                écrites par les Sépharades eux-mêmes pour leur loisir, cherchant
                leurs thèmes d'inspiration à la fois dans leur propre substrat
                culturel religieux juif et dans leurs propres expériences
                historiques et quotidiennes. C'est donc ce genre qui montre
                mieux que tout autre la dualité de ce monde judéo-espagnol qui
                s'exprime en espagnol et qui respire et se développe dans une
                sphère d'expériences religieuses et conceptuelles juives ou dans
                les expériences quotidiennes de la communauté.
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                Avant d'aller plus loin, commençons par définir ce que sont les
                <em>coplas</em>. Si l’on simplifie les éléments qui
                caractérisent le genre, les <em>coplas</em> sont des poèmes
                strophiques, composés d'un nombre variable de strophes, qui
                suivent toutes dans chaque poème le même schéma métrique et
                offrent un lien thématique.
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                Le strophisme des <em>coplas</em> est très varié et répond en
                général, comme je l'ai déjà mentionné, aux schémas strophiques
                présents dans la poésie espagnole médiévale. Leur longueur est
                également variée, allant de moins d'une douzaine de strophes,
                pour les plus courtes, à plusieurs centaines, pour les plus
                longues. Il s'agit généralement de poèmes acrostiches,
                c'est-à-dire que les lettres initiales de chaque strophe et
                parfois aussi du vers suivent un ordre spécifique ou
                communiquent une information.
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                Le contenu des <em>coplas</em> est principalement narratif, bien
                qu'occasionnellement descriptif ou expositif, et même lyrique.
                Nous savons que bon nombre d'entre elles étaient destinées à
                être chantées, soit en groupe, soit par un individu en présence
                d'un public qui chantait les refrains. Les mélodies peuvent être
                propres et spécifiques, mais elles utilisent aussi souvent des
                mélodies d'autres chansons connues. Comme dernière
                caractéristique pertinente, disons que les <em>coplas</em> sont
                les poèmes d'un auteur, mais que les noms des auteurs ne sont
                connus ou présumés connus de nous aujourd'hui que dans très peu
                de cas. La raison en est que les imprimeurs et les répétiteurs
                occasionnels des textes ou - ce qui revient au même - la
                conscience patrimoniale, ne prêtent pas attention ou ne trouvent
                pas pertinente la mémoire des compositeurs originaux, de sorte
                que le nom de l'auteur est généralement oublié. Il ne peut être
                connu du chercheur qu'à travers la lecture et - dans la plupart
                des cas - la reconstruction des lettres acrostiches s'il y en a,
                ou bien encore parce que les éditions les plus anciennes le font
                figurer sur la page de titre ou enfin parce qu'il est inséré
                dans une strophe du poème. Quant à leur transmission, on peut
                dire que les <em>coplas</em> sont des poèmes qui se transmettent
                principalement par écrit, à travers des éditions ou des
                manuscrits, bien que nous ayons également des versions orales
                d'un bon nombre de ces textes écrits.
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                Le corpus textuel des <em>coplas</em> est encore incomplet, mais
                ce qui est connu à ce jour donne un bilan d'environ quatre cents
                textes différents, représentés dans un nombre qui est plus
                proche de deux mille que de mille versions primaires{" "}
                <HashLink to="#16" className="ftnLink" id="s16">
                  [16]
                </HashLink>
                .
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                Les <em>coplas</em> appartiennent aux deux traditions
                littéraires sépharades : Méditerranée orientale et détroit de
                Gibraltar. La principale différence entre les deux zones
                géographiques est que, dans la région du détroit, les sources
                sont uniquement manuscrites ou orales, en raison de l'absence de
                centres d'édition.
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                D'une manière générale, on peut parler de corpus spécifiques
                pour chacune de ces deux zones géographiques. Mais nous trouvons
                aussi dans le <em>coplario</em> du détroit de Gibraltar des
                versions très traditionnalisées des <em>coplas</em> de l'Est.
                Plus encore : dans certains cas intéressants, les textes du
                détroit conservent des motifs et des thèmes qui n'apparaissent
                pas dans leurs versions parallèles de l'Est, ce qui suggère
                l'existence d'une
                <em>proto-copla</em> antérieure à la séparation des deux
                traditions.
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                Passons maintenant à l'histoire des <em>coplas</em>. Ce que nous
                savons, c'est qu'il s'agit d'un genre d’une rare longévité, dont
                certaines caractéristiques semblent très anciennes et doivent
                être mises en relation avec les précédents Hispano-Juifs, peu
                nombreux mais significatifs, de la Sefarad médiévale ;
                rappelons, par exemple, les splendides et anonymes coplas de
                Yosef, les coplas morales de Sem-Tov de Carrión, la{" "}
                <em>Lamentation de l'âme devant la mort</em>, également anonyme,
                et d'autres poèmes encore. Quelques rares <em>coplas</em>{" "}
                peuvent être datées sans aucun doute du XVIIe siècle, comme
                celles qui décrivent des épisodes de la vie de Sabetay Sevi ou
                celle qui raconte le siège subi par la ville de Belgrade en 1688
                aux mains des Autrichiens ; pour d'autres, nous avons reçu des
                témoignages qui montrent qu'elles étaient déjà connues au XVIIe
                siècle ; enfin un lot de <em>coplas</em>
                récemment découvertes par Dov Cohen pourrait peut-être être daté
                du XVIe siècle.
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                Le fait est que, dès l'aube du XVIIIe siècle, la prolifération
                des éditions nous montre que le genre est florissant, riche et
                vigoureusement enraciné dans la tradition. Cette force et cette
                vigueur nous permettent de déduire que, malgré la rareté de la
                documentation textuelle, le genre a eu une longue histoire et je
                suis encline à penser qu'il y a eu une tradition ininterrompue
                dans la création et l'entretien des <em>coplas</em> depuis la
                période précédant l'expulsion jusqu'à sa revitalisation en
                Orient au début du XVIIIe siècle. Cette revitalisation peut
                s'expliquer par le même motif qui a conduit à l'
                <em>opus magnum</em> que fut le <em>Meam loez</em> : le désir de
                certains auteurs de renforcer la connaissance du peuple du monde
                culturel juif traditionnel et de lui donner une meilleure
                compréhension de la culture juive. C'est ainsi que les{" "}
                <em>coplas</em> les plus anciennes qui nous sont parvenues
                portent pour la plupart sur des thèmes "religieux" au sens large
                du terme : hagiographiques, religieux normatifs, morales,
                paraliturgiques, etc.
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                Tout au long du XVIIIe siècle et de la première moitié du XIXe
                siècle, le genre s'est développé avec un grand dynamisme, et
                lors du changement radical de la langue et de la littérature
                sépharades dans le dernier tiers du siècle dernier, dont nous
                parlerons plus loin, le genre des <em>coplas</em> n'a pas
                disparu. Il subit certes des changements notables de style et de
                sujet, mais sa vitalité ne faiblit pas, et de nouvelles{" "}
                <em>coplas</em> sont constamment créées et d'anciennes
                réimprimées.
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                Le sujet des <em>coplas</em> est soit de manière générale juif,
                soit spécifiquement sépharade, et il est rare de trouver des
                textes qui ne soient pas étroitement liés à quelque chose de
                spécifique à ce monde d'expérience. Ce quelque chose peut être
                commun à tout le judaïsme, comme les grands personnages et les
                mythes du passé juif, ou des événements dans lesquels la
                communauté sépharade joue un rôle de premier plan ou auxquels
                elle participe d'une manière ou d'une autre ou qui l'affectent.
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                Pour les classer, nous allons suivre ici la proposition de
                classement élaborée par I. M. Hassán{" "}
                <HashLink to="#17" className="ftnLink" id="s17">
                  [17]
                </HashLink>
                , qui les ordonne dans un continuum selon différentes
                caractéristiques, la principale étant la chronologie, valable
                pour toutes celles qui ont une référence temporelle, soit la
                grande majorité.
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                Selon ce critère nous classerions les <em>coplas</em> en
                commençant par celles qui traitent de la création du monde et
                d'Adam et Eve, pour continuer avec les patriarches : Abraham et
                sa vocation, Isaac et son sacrifice, Jacob et les douze tribus
                et Joseph et son histoire romanesque, dont plusieurs textes
                différents nous sont parvenus. Vient ensuite le cycle de Moïse,
                avec sa naissance prodigieuse, sa mission de faire sortir le
                peuple juif d'Égypte, l'exode et le don de la loi. Suivent les
                quelques autres personnages bibliques - Aaron, Samuel, Jonas -
                et les nombreux récits du cycle très productif de la reine
                Esther, destinés à être lus ou chantés à l'occasion de la fête
                de Pourim{" "}
                <HashLink to="#18" className="ftnLink" id="s18">
                  [18]
                </HashLink>
                . Les temps bibliques se terminent par les <em>coplas</em> de la
                destruction du Temple et de l'exil. Celles qui sont consacrées
                aux exploits des Maccabées et celles qui font l'éloge des
                rabbins du Talmud appartiennent à la période du judaïsme
                classique.
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                Le groupe de <em>coplas</em> susmentionnées, qui chantent les
                grands mythes et les figures vénérées du peuple juif, est lié
                aux
                <em>coplas</em> qui se réfèrent à l'histoire sépharade elle-même
                : guerres ou événements historiques généraux, ou encore
                bouleversements locaux et escarmouches au cours desquels la
                communauté sépharade est en quelque sorte sauvée du danger.
                Parmi celles qui, en raison de leur sujet historique, peuvent
                être encadrées par une succession chronologique d’événements, je
                me référerai à quelques contenus qui peuvent donner une idée des
                thèmes très variés que les Sépharades ont mis en <em>coplas</em>{" "}
                : le repeuplement sépharade de la ville de Tibériade en 1740 ;
                une émeute lors de la Pâque grecque en 1790, liée à l'une des
                vieilles calomnies antijuives de crime rituel ; les guerres
                napoléoniennes de 1812 ; l'assassinat d'État en 1826 du
                courtisan constantinopolitain Behor Carmona ; la guerre
                russo-turque de 1877 ; le tremblement de terre de Constantinople
                de 1884 ; le sauvetage en 1886 par la police locale d'Abraham
                Beja, un salonicien enlevé par des bandits grecs ; les incendies
                de Salonique de 1890, 1917 et 1921 ; l'orage de grêle de
                Salonique en 1899 ; un vol dans le port de Salonique en 1907 ;
                la chute du sultan Abdul Hamid en 1909 et les libertés
                politiques et de la presse qui en découlent ; l'apparition d'une
                comète en 1910 ; l'épidémie de peste de 1913 et la famine
                qu'elle a entraînée ; et ainsi de suite{" "}
                <HashLink to="#19" className="ftnLink" id="s19">
                  [19]
                </HashLink>
                .
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                Cependant, tout ce qui est lié à l'héritage sépharade et que les
                <em> coplas</em> relatent n'a pas un point de référence
                chronologique précis. Elles traitent également de ce qui fait
                partie de l'expérience vécue, forgée par de nombreux siècles de
                vie en tant que Juif traditionnel et quelques siècles moins
                importants, mais également remarquables par leur empreinte, de
                vie en tant que Sépharade et dans un environnement non moins
                traditionnel.
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                C'est pourquoi nous trouvons également dans les sujets de ce
                genre littéraire les croyances et les règles du judaïsme,
                l'esprit des prières, les cérémonies et les rites du cycle de
                vie ou de la fête annuelle et ses manifestations folkloriques,
                le mode de vie dans l'environnement traditionnel (comment
                passent les heures de la journée, les jours de la semaine,
                comment on cuisine les légumes{" "}
                <HashLink to="#20" className="ftnLink" id="s20">
                  [20]
                </HashLink>
                ), peut-être une anecdote sentimentale, et toujours la nostalgie
                du retour en Terre sainte, dans les <em> coplas</em>{" "}
                néo-sionistes.
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                Les <em>coplas</em> à contenu moral du point de vue de l'éthique
                juive, consacrées à la réprimande des mauvaises habitudes, à la
                démonstration de la nature éphémère de la vie et des biens
                terrestres et à l'exhortation à l'accomplissement des préceptes
                de la loi, constituent un groupe d'une importance particulière.
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                De nombreuses <em>coplas</em> écrites à partir de la fin du XIXe
                siècle traitent des changements sociaux provoqués par
                l'avènement des temps nouveaux, et l'aspect descriptif y
                apparaît mêlé à des touches de critique sociale, comme un
                épigone tardif des <em>coplas</em>
                morales. Les nouvelles habitudes imposées par les changements de
                vie y sont considérées de manière burlesque et parfois grinçante
                : les mariages par intérêt, le goût du jeu ou de la danse, ce
                que les auteurs, toujours des hommes, considèrent comme des
                modes féminines farfelues, etc. ; bref, ils sont le produit des
                années heureuses du monde sépharade, où le sentiment du purement
                traditionnel et religieux s'atténue, et où apparaît à sa place
                une plus grande considération du quotidien, dans certaines
                limites terre à terre et, si l'on veut, plus triviales{" "}
                <HashLink to="#21" className="ftnLink" id="s21">
                  [21]
                </HashLink>
                .
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                Mais ce qu’il est intéressant de souligner, c'est que dans
                toutes les <em> coplas</em> mentionnées, qu'elles appartiennent
                à un groupe ou à un autre, le thème qui est développé est
                toujours quelque chose de spécifique à la communauté qui les
                crée et les chante.
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                Pour en finir avec les <em> coplas</em> , et même si nous ne
                pouvons pas nous y attarder ici, disons que bon nombre d'entre
                elles remplissent une fonction paraliturgique ou peuvent occuper
                une place spécifique en relation avec les étapes du cycle de vie
                - naissance, circoncision et décès ; rarement les mariages - et
                ce, soit parce qu'elles ont été créées pour une telle occasion,
                soit parce que, compte tenu de leur thème, l'usage traditionnel
                les a établies comme propres à ce cas.
              </Typography>
              <Typography
                align="justify"
                variant="h6"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                2.3 Le XIXe siècle et l'influence européenne
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                La troisième étape historique se situe au milieu du XIXe siècle
                et ses effets se sont prolongés jusqu'à la Seconde Guerre
                mondiale. À partir du dernier tiers de ce siècle, nous avons
                assisté à un renouveau culturel total dans le monde sépharade,
                qui a ébranlé et perturbé les modèles de vie ancestraux, brisant
                les modèles de vie traditionnels qui étaient restés presque
                inchangés pendant des siècles dans les communautés balkaniques.
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                Le changement susmentionné qui s'est produit au début du XVIIIe
                siècle a été unique et exceptionnel au sens où il a donné
                naissance et favorisé l'émergence d'œuvres littéraires librement
                créées et exprimées en judéo-espagnol. Mais, comme nous l'avons
                déjà souligné, les thèmes développés à cette époque
                appartiennent à la sagesse du peuple juif évoquée plus haut, et
                les sources d'inspiration sont encore principalement celles de
                la pensée juive traditionnelle.
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                Or, le changement profond réside précisément dans la rupture de
                ces vieux moules pour s'ouvrir à quelque chose de nouveau et
                jusqu'alors étranger aux Sépharades de cette aire géographique :
                le monde culturel non-juif de l'Occident.
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                Plusieurs facteurs provoqueront des changements aussi radicaux
                dans la situation socioculturelle des Sépharades des Balkans.
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                L'un d'eux est d'ordre général et concerne tous les habitants de
                la région, Juifs et non-Juifs : il s'agit de la recomposition
                politique de l'Europe de l'Est, initiée avec l'indépendance de
                la Grèce et poursuivie après la guerre russo-turque de 1877 et
                les guerres balkaniques de 1912-1913.
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                Au cours de cette période, les pays balkaniques se réveillent
                progressivement de la léthargie séculaire dans laquelle les
                avait plongés la soumission imposée par l'Empire ottoman ; à
                mesure que leur autorité politique est arrachée, ils sont
                secoués par les mouvements nationalistes qui ébranlent l'Europe
                de l'Est. Sous la peau déchirée de l'ancien empire, un certain
                nombre de peuples émergent et s'efforcent de récupérer leur
                conception historique d'eux-mêmes en tant que nations
                indépendantes, leur culture unique et leur propre entité
                nationale. La révolution des Jeunes Turcs de 1908, déjà
                mentionnée, a également été un événement majeur qui a affecté
                tous les habitants des régions qui appartenaient encore à
                l'Empire ottoman, désormais en phase terminale. La chute d'Abdul
                Hamid a mis fin à la censure stricte qui pesait sur la plume des
                écrivains et des journalistes, ouvrant ainsi une période de
                production littéraire remarquable. Elle a également permis la
                libre entrée dans le pays de livres publiés en Europe
                occidentale, dont l'importation avait été jusqu'alors interdite
                et sanctionnée. À partir du milieu du XIXe siècle, le monde
                sépharade a connu un processus que l'on pourrait qualifier de
                "révolution" culturelle, c'est-à-dire l'inverse de ce qui
                s'était passé au début du XVIIIe siècle.
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                Comme nous l'avons vu, ce qui s'était passé le siècle précédent
                était une recherche tournée vers l'intérieur, vers sa propre
                tradition afin de réaffirmer les anciens modes de vie.
                Aujourd'hui, le changement profond consiste à briser précisément
                ces vieux moules pour s'ouvrir à la culture de l'Occident.
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                Les causes de cette transformation sont multiples. Certaines
                sont les changements historico-sociaux susmentionnés qui
                affectent tous les habitants de la région et d'autres qui
                affectent spécifiquement les communautés sépharades. Parmi les
                facteurs de changement qui affectent directement le monde
                sépharade, le premier est l'écho du mouvement culturel de la
                <em>Haskalah</em> (Lumières) promu au XVIIIe siècle par le
                judaïsme russe et d'Europe centrale, dont les adeptes étaient
                déterminés à sortir du ghetto spirituel que représentait le
                maintien à tout prix de la vie juive traditionnelle, afin de
                s'intégrer au savoir culturel de l'Occident.
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                Un autre événement qui a particulièrement contribué à la
                transformation du monde sépharade d'Europe orientale est
                l'intérêt post-romantique de l'Occident pour l'Orient, avec des
                écrivains et des peintres tels que Victor Hugo, Chateaubriand,
                Byron, Delacroix, etc. Cet intérêt se traduit par l'ouverture
                d'écoles européennes, italiennes et allemandes dans les Balkans,
                et surtout par la mise en place, à partir de 1860, du réseau des
                écoles juives françaises de l'Alliance Israélite Universelle,
                institution fondée à Paris en 1860, dans toute la diaspora
                sépharade. Par leur intermédiaire, la production littéraire et
                les valeurs culturelles françaises et européennes sont
                transmises aux Sépharades. Les Sépharades s'ouvrent ainsi à
                l'Occident par le biais de la culture française, et le français
                et la francité auront une influence décisive sur leur
                littérature et, de manière irréversible, sur leur langue.
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                Contrairement au système éducatif traditionnel, qui s'éteint peu
                à peu, basé sur des écoles communautaires rattachées aux
                synagogues, ces nouvelles écoles sont construites avec un
                système d'enseignement à l'occidentale, inspiré (du) ou calqué
                sur le système français, avec deux objectifs précis : d'une
                part, l'objectif très louable d'offrir une formation
                professionnelle et commerciale aux garçons et aux filles et,
                d'autre part, l'objectif très français et sa politique
                culturelle enviable d’effacer de la surface de la terre - en
                l'occurrence, de l'esprit des Sépharades hispanophones des
                Balkans - tout ce qui avait un soupçon de culture avant leur
                débarquement dans la région, pour transformer ce monde et ces
                locuteurs en nouvelle colonie française, comme, par exemple, la
                Martinique. Les dirigeants parisiens de l'Alliance envoient à
                leurs enseignants des écoles des Balkans un exemple simple : ils
                ont pour instruction de punir sévèrement les élèves qui
                commettent le terrible crime de se parler en judéo-espagnol.
              </Typography>

              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                Et ils parviennent largement à leurs fins, malgré l'opposition
                frontale que ces écoles rencontrent, d'abord de la part des
                éléments les plus conservateurs des communautés et d'une grande
                partie du rabbinat "à l'ancienne", puis, bien au-delà du XXe
                siècle, de la part des groupes sionistes, qui soutiennent que la
                solution pour le monde juif ne réside pas dans la laïcité à la
                française véhiculée par les écoles de l'Alliance, ni dans le
                fait de se tourner vers le monde culturel et expérimental de
                l'Europe occidentale, mais dans le retour à la patrie de leurs
                ancêtres et le rétablissement de l'hébreu comme langue nationale
                de l'ensemble du peuple juif. Le perdant de la dispute étant le
                malheureux judéo-espagnol.
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                Les changements sociaux mentionnés ci-dessus déterminent le
                début de la troisième période de l'histoire sépharade, ouverte
                comme la première, mais désormais polarisée vers la France, dont
                les Sépharades acceptent les concepts et les valeurs et à
                travers laquelle ils sont influencés par la culture occidentale
                et le monde moderne. Cette influence, qui aurait pu être aussi
                enrichissante que l'a été en son temps celle des langues et des
                cultures de l'aire turco-balkanique, a cependant marqué le début
                du déclin inéluctable de la culture spécifiquement sépharade que
                j'évoquerai plus loin.
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                À partir de cette époque, bien que la littérature à contenu
                religieux traditionnel n'ait pas été interrompue, elle a
                commencé pour la première fois à coexister avec des genres
                "séculiers" récemment adoptés, tels que le journalisme, le
                théâtre, la narration, la poésie d'auteur et la littérature que
                nous pouvons appeler "d'érudition", c'est-à-dire : l'histoire,
                aussi bien générale que particulière sur certaines périodes ou
                événements spécifiques ; la biographie ; les sciences naturelles
                ; etc. Cette littérature d'adoption est d'inspiration
                essentiellement française et s'exprime dans un
                néo-judéo-espagnol très évolué, éloigné de son antécédent
                hispanique séculaire et de son précédent du XVIIIe siècle.
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                Mais avant de se plonger dans la production littéraire de cette
                période, il est nécessaire d'ouvrir une parenthèse pour se
                demander ce que faisait l'Espagne entre-temps à la fin du XIXe
                siècle et où se trouvait une politique culturelle qui, si elle
                avait été développée à l'époque dans ces lieux, aurait peut-être
                ramené définitivement de nombreux Sépharades dans les plis de la
                langue et de la culture espagnoles modernes. La réponse est que
                l'Espagne, après son errance grotesque au XVIIIe siècle sous
                Ferdinand VII, et son errance délirante et sanglante d'une
                guerre civile à l'autre pendant la plus grande partie du XIXe
                siècle, pansait à la fin de ce siècle les plaies de
                l'effondrement de l'Empire espagnol.
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                Rappelons qu'après avoir perdu la guerre contre les États-Unis,
                Cuba a renoncé à sa souveraineté en 1898 et que les îles des
                Philippines, de Guam et de Porto Rico ont été remises au
                vainqueur ; il est facile de comprendre que le pays n'était
                certainement pas d'humeur à faire de l'expansionnisme culturel.
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                D'autre part, cette génération sans doute exceptionnelle
                d'écrivains et de penseurs qui a émergé dans le dernier tiers du
                siècle, culminant dans ce que l'on appelle communément la
                "génération de 98", n'était pas non plus d'humeur à se
                préoccuper de l'extérieur, car elle avait suffisamment de soucis
                à l'intérieur. Et ils étaient bien extérieurs à eux ces
                Sépharades, de l’existence desquels beaucoup d’entre eux
                n’avaient jamais entendu parler ou si peu, à l’honorable
                exception près de Galdos et de quelques autres. Bien sûr, pire
                encore était la posture de ceux qui pour le coup en savaient
                quelque chose, comme Alarcón, Pío Baroja, Blasco Ibáñez,
                Menéndez Pelayo, etc. dans les écrits desquels l’antijudaïsme
                transpire de manière voilée ou tout à fait claire. Et ce qu'ils
                pensaient, c'est que, même si les Sépharades parlaient une
                langue très proche du castillan, ils sentaient toujours le
                "soufre" de tous les pores de leur peau; et avec cette posture
                si espagnole du « je persiste et signe », ils continuaient
                d’applaudir l'expulsion des Juifs par les Rois Catholiques comme
                une mesure d’une grande force politique. Et
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                La question que nous avons posée sur l'Espagne et son attitude à
                l'égard des Sépharades est justifiée ici, par quelque chose d’un
                grand intérêt pour notre sujet, puisque ses effets perdurent
                jusqu'à aujourd'hui. Certes c’est en cette fin du XIXe et aux
                débuts du XXe que se produisent les “découvertes” fortuites, par
                des Espagnols militaires en Afrique ou voyageurs en Orient, du
                phénomène linguistique qu’est la langue des Sépharades, les
                premières d’une longue série. En faisant part de leur étrange
                trouvaille aux philologues, ils suscitèrent leur intérêt. Et sur
                quoi reposait-il cet intérêt? Eh bien, c’est que ces
                philologues, avec un aveuglement aussi stupéfiant que chauvin,
                se sont sentis attirés purement et exclusivement par le très
                riche fonds traditionnel de ballades sépharades, héritage du
                fonds hispanique, ces romances que les Sépharades conservaient
                par tradition orale ; et, éblouis par le riche filon trouvé, ils
                ont effectué depuis la fin du XIXe siècle un travail
                exceptionnel de recueil de textes de ballades. Mais,
                malheureusement, ils n'étaient guère curieux de ces "autres
                choses" que les Sépharades interrogés s'obstinaient à "placer"
                dans la conversation. Ces "autres choses" étaient principalement
                des <em>coplas</em>, alors très vivantes dans une tradition
                sépharade aujourd'hui disparue ; en tant que corps étranger à la
                tradition hispanique, les philologues espagnols n'ont pas
                reconnu leur importance et n’ont pas cru bon de recueillir leurs
                versions, nous privant ainsi, nous les chercheurs ultérieurs, de
                textes qui sont aujourd'hui irrémédiablement perdus. Et s'il en
                a été ainsi pour les textes de <em>coplas</em> traditionnels, il
                va sans dire que ces philologues ne se rendirent même pas compte
                – et s’ils s’en rendirent compte, ils n’y accordèrent pas le
                moindre intérêt - que ces livres et opuscules qu'ils voyaient
                sans nul doute dans les maisons sépharades qu'ils visitaient,
                imprimés en lettres qui leur étaient étrangères et
                incompréhensibles, cachaient, derrière cette étrange apparence,
                les trésors d’œuvres écrites en "juif" – pour ce qui est des
                caractères- et en "espagnol" – pour ce qui était de la langue
                employée. Dans ces années-là, ces livres auraient pu être
                achetés pour quatre sous et auraient enrichi les bibliothèques
                espagnoles. Des centaines de publications judéo-espagnoles
                auraient été sauvées de la destruction et de la perte, qui ont
                aujourd'hui disparu à cause de l'incurie du temps, des
                innombrables incendies qui ont ravagé les quartiers des villes
                de l'Europe balkanique, et, le dernier point et non le moindre,
                le "nettoyage" effectué par les nazis dans leur plan
                systématique de faire disparaître tout vestige juif de la
                surface de l'Europe. Si la vie des Juifs des Balkans n'a pas été
                épargnée, le sort de leurs publications n’a pas été meilleur.
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                En outre, l'attitude arrogante et dédaigneuse des écrivains,
                philologues et hommes politiques espagnols de l'époque a
                contribué à la création d'une série de clichés, fondés sur la
                précipitation et l'ignorance, dont nous subissons encore
                aujourd'hui les dommages. Ces clichés sur les Sépharades,
                amplifiés à l'aube du XXe siècle par la campagne du sénateur
                Ángel Pulido à laquelle certains hispanophiles sépharades ont
                donné de l'écho, sont restés vivaces jusqu'à nos jours et
                circulent encore dans les médias.
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                Pour revenir à ce qui a fini par devenir une tâche fastidieuse
                de poursuite et destruction de ces erreurs qualifiées, et bien
                que nous répétions ce qui a déjà été dit dans d'innombrables
                forums, avec l'espoir de plus en plus perdu de corriger les
                diffuseurs irresponsables qui, comme des perroquets sans opinion
                propre, répètent inlassablement ce qui est faux, rappelons
                quelques-uns des clichés les plus répandus à l'époque. 1) Les
                Sépharades parleraient la langue du temps des Rois Catholiques,
                comme si une langue vivante pouvait être fossilisée dans un
                moment historique grâce à la baguette magique d'une phrase que
                quelqu'un a jugée heureuse. 2) Que la littérature sépharade a
                été exclusivement transmise oralement, image qui, comme nous
                l'avons déjà dit, a été diffusée par de très honorables
                hispanistes, éblouis par l'intérêt indubitable que représente le
                maintien chez les Sépharades de versions de ballades hispaniques
                appartenant au <em>Romancero</em> ancien. Ces érudits ont suivi
                la règle selon laquelle ce que je ne connais pas n'existe pas,
                ignorant la très abondante littérature d'auteur en prose et en
                vers produite par les Sépharades. 3) Que les Sépharades ont
                conservé leur langue pendant cinq siècles grâce à leur amour
                indéfectible de la patrie hispanique, phrase pompeuse aux
                accents impérialistes où l'on ignore généralement qu'il n'y a
                rien de singulier à ce qu'un peuple conserve sa propre langue,
                et où l'on ignore surtout que la langue espagnole était pour les
                Sépharades de l'Empire ottoman un signe d'identité au même titre
                que leur religion, et que lorsque, en raison de changements des
                circonstances historiques, elle a cessé d'être un tel signe
                d'identité et que son maintien aurait exigé un effort volontaire
                évident, c'est alors qu'a commencé son déclin inéluctable.
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                Mais laissons ces réflexions de côté et revenons à ce qui nous
                occupe. De toute l'abondante production littéraire des
                Sépharades de la fin du XIXe siècle à la Seconde Guerre mondiale
                que nous avons évoquée plus haut - journalisme, théâtre{" "}
                <HashLink to="#22" className="ftnLink" id="s22">
                  [22]
                </HashLink>
                , récit{" "}
                <HashLink to="#23" className="ftnLink" id="s23">
                  [23]
                </HashLink>
                , poésie d'auteur, histoire, biographie, etc. -, je ne ferai
                référence ici qu'à la prose savante, sujet dont je me
                contenterai de donner un bref aperçu et à la presse périodique
                judéo-espagnole, qui présente l'intérêt particulier d'avoir été
                le moteur de la création et de la diffusion d'un grand nombre
                des genres susmentionnés. En ce qui concerne le théâtre, je me
                contenterai de mentionner brièvement quelques aspects.
              </Typography>
              <Typography
                align="justify"
                variant="h6"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                2.3.1 Le théâtre
              </Typography>

              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                Hormis quelques vagues traces et nouvelles d'un théâtre
                traditionnel développé antérieurement par les Sépharades, soit
                dans les écoles, soit à travers des mascarades et des pantomimes
                pour célébrer la fête de Pourim commémorant les événements
                funestes relatés dans le livre biblique d'Esther, le théâtre
                sépharade apparaît sous nos yeux dans la seconde moitié du XIXe
                siècle comme un théâtre d'auteur, joué par des troupes d'acteurs
                mémorisant des textes littéraires établis : en d'autres termes,
                le théâtre tel que nous sommes habitués à le voir de nos jours.
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                Dans mon étude générale sur le théâtre sépharade, j'ai compilé
                une bibliographie de quatre-vingt-trois éditions de pièces en
                judéo-espagnol et j'ai documenté environ sept cents pièces
                différentes jouées dans une cinquantaine de lieux différents en
                Europe balkanique.
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                La documentation elle-même montre des limites chronologiques
                allant du dernier quart du XIXe siècle aux années 1940. Si,
                après la Seconde Guerre mondiale, certains auteurs sépharades
                ont écrit des pièces dans les pays d'installation de la diaspora
                secondaire (Israël, États-Unis, France, etc.), leurs motivations
                ont cependant été celles de la nostalgie ou de l'érudition, et
                leurs pièces n'ont plus eu la résonance communautaire qui se
                produisait autour des représentations du monde sépharade
                d'avant-guerre. Aujourd'hui, il est clair qu'avec la destruction
                totale ou partielle des communautés sépharades d'Europe de
                l'Est, le fil magique qui lie la scène et le théâtre, qui permet
                de parler d'un théâtre représentant un groupe humain avec ses
                particularités, a été rompu.
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                Un autre aspect du théâtre sépharade qui mérite d'être commenté
                est l'originalité et la langue des pièces. Il convient de noter
                qu'un grand nombre de pièces publiées et jouées dans le monde
                sépharade ne sont pas des originaux en judéo-espagnol, mais des
                traductions de pièces d'auteurs français, grecs, russes, etc. et
                d'auteurs juifs, écrites en hébreu, yiddish, néerlandais,
                polonais, etc. Ces traductions nous permettent de vérifier la
                connaissance que le monde sépharade avait du théâtre d'autres
                cultures et nous laissent une trace de leurs préférences et de
                leur goût sélectif, de leur plus ou moins grande compréhension
                ou intérêt pour un problème étranger, ou - ce qui est plus
                intéressant - elles nous montrent le processus d'adaptation à
                leur propre environnement communautaire d'un thème extérieur,
                par le biais de la manipulation ou de la modification du texte
                théâtral d'origine. Par conséquent, bien que nous ne puissions
                pas considérer les pièces traduites comme quelque chose
                d'intrinsèquement propre au théâtre sépharade, nous ne pouvons
                pas non plus les ignorer lorsque nous l'envisageons dans son
                ensemble.
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                Examinons maintenant brièvement leur sujet. En allant du plus
                particulier au plus général, nous pouvons distinguer trois
                groupes principaux : 1) les pièces à thème spécifiquement
                sépharade, 2) les pièces à thème juif et 3) les pièces importées
                du théâtre universel non juif.
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                1) Dans le groupe des pièces à thème spécifiquement sépharade,
                nous incluons celles qui sont nées au cœur des communautés de
                l'Est et qui reflètent et décrivent les expériences du monde
                fermé dans lequel se déroule leur vie ainsi que les souvenirs de
                leur histoire passée ou présente.
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                Parmi les œuvres appartenant au premier groupe, nous pouvons
                souligner un bon nombre d'œuvres à contenu <em>costumbrista</em>{" "}
                (sur les us et coutumes) qui, avec plus ou moins de longueur et
                de profondeur, traitent de problèmes quotidiens et
                communautaires. Beaucoup d'entre elles nous présentent toute une
                typologie sépharade : employés, artisans, marchands, marieurs,
                entremetteurs, sages-femmes, vendeurs, etc., qui s'expriment
                parfois dans un langage familier et agissent, rient et pleurent,
                pensent et vivent, en somme, dans le cadre de leur vie de tous
                les jours.
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                Une section spéciale est consacrée à ces œuvres qui sont le
                fruit des joyeuses années vingt, lorsque la vie traditionnelle
                s'est brisée et que la vie moderne, avec ses nouveaux problèmes,
                a fait irruption sur la scène. Il y a aussi des œuvres qui
                mettent en évidence les tensions idéologiques qui ont surgi dans
                le monde sépharade entre les sionistes et les socialistes, des
                idéologies politiques qui se sont souvent révélées
                irréconciliables.
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                Quant aux ouvrages historiques, les sépharades portent en eux
                une histoire que, pour le meilleur ou pour le pire, ils
                n'oublient pas : celle de leur passé en Espagne. Mais de toute
                cette période fertile de la présence juive dans la péninsule,
                seuls les moments terribles de l'expulsion et les horreurs de
                l'Inquisition restent dans les mémoires et deviennent une source
                d'inspiration pour bon nombre de pièces de théâtre. A cet égard,
                il convient de mentionner les différents drames écrits autour du
                personnage du ministre juif des Rois Catholiques, Abravanel, par
                divers auteurs, et il en existe également plusieurs qui traitent
                du problème des crypto-juifs.
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                Mais le Sépharade est aussi membre d'une communauté nationale
                (turque, bulgare, grecque, etc.) dans laquelle il est immergé
                depuis plusieurs générations, ayant été directement affecté par
                les vicissitudes historiques de la nation dans laquelle il vit.
                Il s'agit donc d'ouvrages qui traitent de l'histoire passée ou
                présente de ces pays.
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                2) Un autre groupe thématique est celui des œuvres qui
                intéressent le Sépharade en tant que Juif parce qu'elles
                recréent des situations du passé du peuple d'Israël ou parce
                qu'elles se réfèrent d'une manière ou d'une autre aux
                expériences juives en général.
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                Nous pouvons établir deux groupes : les œuvres inspirées par la
                <em>Bible</em> et ses prolongements légendaires ainsi que par
                l'histoire du peuple juif, et les œuvres qui reflètent les
                problèmes du judaïsme moderne dans les pays de la diaspora.
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                En ce qui concerne le premier groupe, il existe de nombreuses
                œuvres à thème biblique, inspirées par des sujets variés : la
                sortie d'Égypte, la conquête de Canaan, les exploits de divers
                héros, tels que Moïse, Jephté, Déborah, David et Goliath, etc.
                Mais les histoires bibliques qui ont été particulièrement
                profitables au théâtre sépharade sont les thèmes de Joseph et
                ses frères et de la reine Esther, qui ont été portés à la scène
                par différents auteurs et traducteurs.
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                Et pour continuer sur la voie des traductions, mentionnons qu'en
                raison de leur thème biblique, des œuvres plus ou moins
                classiques du théâtre européen ont été traduites en
                judéo-espagnol, comme <em>Athalie</em> de Racine, le{" "}
                <em>Saül</em> de Lamartine, le <em>Saül</em> de Vittorio Alfieri
                et d'autres.
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                En ce qui concerne l'histoire du judaïsme classique, les
                situations privilégiées pour le théâtre sont celles qui
                décrivent les épisodes héroïques de l'histoire juive.
                Mentionnons les diverses pièces aux titres similaires qui
                traitent des Maccabées et de leur époque, de Bar-Kokhba, ou des
                rabbins vénérés du Talmud, tels que Rabbi Aquiva ou Rabbi Meir,
                etc.
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                Quant aux pièces sur les questions juives, elles sont de nature
                très variée : celles qui révèlent tout un monde d'expériences
                religieuses, exposant des thèmes doctrinaux ou moralisateurs du
                point de vue des principes religieux ou éthiques juifs ; celles
                qui racontent la situation du judaïsme immergé dans un
                environnement étranger, traitant des problèmes d'assimilation et
                d'antisémitisme, et face auxquels beaucoup prônent la solution
                sioniste ; et enfin, celles qui décrivent des vies ou des
                environnements communautaires juifs non sépharades.
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                3) En ce qui concerne les adaptations du théâtre universel,
                c'est bien sûr le théâtre français qui est le mieux connu du
                public sépharade, qui accueille sur ses scènes, sans
                discrimination, tout ce qui s'est dit et fait de bien et de
                moins bien à l'époque dans le monde culturel français, qui lui
                sert de phare et de guide.
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                Dans le théâtre classique français, Molière est sans doute
                l'auteur préféré, et bon nombre de ses pièces ont été traduites.
                D'autres pièces françaises classiques ont également touché le
                public sépharade, comme <em> La Farce de Maître Pathelin</em> ,
                une farce anonyme du XVe siècle, et certains drames de Racine.
                On joue également des adaptations théâtrales de romans célèbres,
                comme
                <em> Les Misérables</em> de Victor Hugo. La scène sépharade
                offre aussi des pièces françaises contemporaines aux thèmes très
                variés. Des drames, comme l'adaptation théâtrale de{" "}
                <em> Thérèse Raquin</em> d'Emile Zola,{" "}
                <em> Les Affaires sont les affaires</em> d'Octave Mirbeau, etc.
                d’aimables comédies, comme Topaze de Marcel Pagnol ou des
                comédies "romantiques" comme <em> Cyrano de Bergerac</em>{" "}
                d'Edmond Rostand, <em> Les Deux Orphelines</em>{" "}
                d'Adolphe-Philippe Dennery, <em> Le Maître de forges</em> de
                Georges Ohnet et <em>La Vierge folle</em> d'Henry Bataille ; des
                films policiers, comme <em>Le Courrier de Lyon</em>
                d'Eugène Lemoine, et des films plus ou moins sociaux, comme{" "}
                <em> Les Mauvais Bergers</em> d'Octave Mirbeau. Le vaudeville et
                la comédie légère sont également bien représentés, avec des
                œuvres d'Eugène Labiche, de Georges Courteline, etc.
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                A plus petite échelle, et à distance de ce flot de répertoire
                dramatique français, le théâtre judéo-espagnol accueille
                également quelques échantillons de la production théâtrale et
                littéraire d'autres pays européens. Ainsi, des adaptations de
                célèbres romans russes, comme <em>Anna Karenine</em> et{" "}
                <em>Résurrection</em> de Tolstoï, ou{" "}
                <em>Les Frères Karamazoff</em> de Dostoïevski, sont jouées en
                judéo-espagnol. Le théâtre anglais est représenté par{" "}
                <em>Roméo et Juliette</em> de Shakespeare et quelques pièces de
                Bernard Shaw ; le théâtre italien par <em>L'Écossaise</em> de
                Goldoni ; le théâtre turc par des drames de Namık Kemal ; le
                théâtre grec par plusieurs pièces d'Andrea Laskaratos et de
                Spiro Melas ; etc.
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                Avant de terminer sur le théâtre, il convient de souligner
                l'absence totale de connaissance du théâtre espagnol de tous les
                temps dont ont souffert les Sépharades au cours des nombreuses
                années pendant lesquelles le théâtre a été un élément vivant de
                leurs communautés.
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                Les communautés orientales, qui se sont ouvertes à l'Occident
                principalement grâce à la culture française, ont ignoré la
                littérature espagnole alors qu'a priori on pourrait penser
                qu'elle leur était plus accessible que toute autre. Ce vide, qui
                se manifestera dans le bien nommé "anti-hispanisme" des
                dernières générations de Sépharades levantins, était déjà
                ressenti à l'époque par certains Sépharades "hispaniques", comme
                en témoignent les méditations d'Isaac Alcheh, précisément
                lorsqu'il assistait à des représentations théâtrales à Madrid :
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                "Je me suis souvenu et j'ai imaginé voir sur scène tous ces
                artistes et amateurs [de Salonique]... et je me suis demandé
                après coup ce que beaucoup d'entre eux auraient pu accomplir si,
                au lieu de s'approprier toutes les littératures étrangères
                traduites en judéo-espagnol, ils avaient pu assimiler toutes les
                richesses de la littérature espagnole".
              </Typography>
              <Typography
                align="justify"
                variant="h6"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                2.3.2 La prose savante
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                Le nouvel état d'esprit du monde sépharade à partir de la fin du
                siècle dernier se reflète dans la production d'un type d'œuvres
                qui ne peuvent être considérées comme strictement littéraires,
                mais auxquelles nous devons nous référer pour mieux montrer
                quels sont les nouveaux champs d'intérêt qui ont poussé les
                intellectuels et les éducateurs à prendre la plume.
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                Tout d'abord, il faut revenir sur la production abondante de
                livres pédagogiques à l'usage des écoles modernes. Il s'agit de
                livres de morale juive pour les enfants, auxquels s'ajoutent de
                nombreux autres manuels, grammaires et dictionnaires destinés à
                l'enseignement des langues{" "}
                <HashLink to="#24" className="ftnLink" id="s24">
                  [24]
                </HashLink>{" "}
                - judéo-espagnol, hébreu, français, allemand, turc, anglais - et
                des matières des écoles professionnelles en vogue à l'époque.
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                Autre produit des temps nouveaux, les nombreuses brochures
                publiées par les clubs et les associations, qui exposent leurs
                objectifs et leur règlement intérieur, rendent compte de leurs
                activités et, plus intéressant encore, diffusent les textes des
                conférences sur les sujets les plus divers, données par leurs
                membres ou par des invités de marque. Ces brochures fournissent
                des données intéressantes pour reconstituer l'histoire et les
                activités de nombreuses associations qui organisaient la vie
                sociale, culturelle et politique des communautés sépharades dans
                les Balkans.
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                Un chapitre intéressant de cette section est constitué par les
                livres d'histoire, dont certains sont des traductions, mais dont
                la plupart sont des originaux en judéo-espagnol. On peut les
                classer en trois catégories : ceux qui traitent de l'histoire du
                peuple juif en général, ceux qui traitent de l'histoire
                spécifique du monde sépharade et ceux qui traitent d'un sujet
                général, non spécifiquement juif. Dans le premier groupe, pour
                ne citer qu'un exemple, on peut mentionner l'ouvrage en douze
                volumes sur l'Israël classique de Hayim Israel Sakí, intitulé
                Histoire universelle, qui a été publié à Constantinople entre
                1899 et 1928 ; et les nombreuses biographies, tant de
                personnages du passé, comme Maïmonide, Sabetay Seví et la secte
                des Dönmès, etc. que de personnages modernes : le baron anglais
                Moïse Montefiori, le capitaine Alfred Dreyfus, etc. Parmi les
                ouvrages consacrés spécifiquement au monde sépharade, ceux qui
                traitent de l'histoire des communautés locales, comme les
                différents ouvrages sur Salonique, et d'autres sur Kastoria, la
                Bosnie, Vidin, Ruschuk, Sofia, Vienne, etc. sont
                particulièrement intéressants. Parmi le matériel
                historiographique général, nous trouvons de nombreux livres
                traitant des aspects de l'histoire ottomane{" "}
                <HashLink to="#25" className="ftnLink" id="s25">
                  [25]
                </HashLink>{" "}
                et grecque, tant ancienne que moderne, et traitant de
                personnages historiques célèbres, tels que Napoléon, Raspoutine,
                etc.
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                Pour leur érudition occasionnelle, il convient également
                d'inclure dans cette section les très nombreux livres qui se
                sont répandus parmi les Sépharades des Balkans à partir de la
                fin du XIXe siècle, et qui contiennent des matériaux variés :
                prescriptions médicales, récits de voyage, anecdotes, histoires,
                plaisanteries, passe-temps, événements curieux, informations
                communautaires, etc. Un précédent de ce type d'ouvrage est le
                très intéressant livre intitulé <em>La güerta de oro</em>{" "}
                (Livourne, 1778), de David Bahar Mosé Atías, qui est le plus
                ancien ouvrage judéo-espagnol "profane" connu sur des sujets
                judéo-espagnols.
              </Typography>
              <Typography
                align="justify"
                variant="h6"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                2.3.3 Le journalisme
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                L'importance du journalisme dans la formation de la nouvelle
                communauté sépharade n'a peut-être pas encore été suffisamment
                appréciée. Son influence s'est fait sentir de manière décisive
                sur trois points.
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                Tout d'abord, la presse périodique a été un facteur décisif dans
                l'éveil et la consolidation de la conscience d'appartenir à une
                communauté dont les problèmes ne sont pas exclusivement d'ordre
                religieux. Par les informations diffusées dans leurs pages sur
                la vie de la communauté, non seulement dans la ville où ils
                vivent mais aussi dans d'autres villes de la diaspora sépharade
                des Balkans, les journaux contribuent à l'information mais aussi
                à la formation de l'opinion, permettant à leurs lecteurs de
                connaître les problèmes quotidiens et les positions divergentes
                des membres des communautés dans la recherche de solutions.
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                De ce point de vue des tenants et aboutissants de la communauté,
                les journaux sont certainement un reflet fidèle de la vie
                interne des communautés, des préoccupations, des désirs et des
                espoirs des Sépharades depuis près d'un siècle.
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                D'autre part, les informations que les Sépharades recevaient par
                la presse sur les événements du vaste monde ont favorisé le
                développement d'une nouvelle mentalité chez ceux qui,
                jusqu'alors, étaient restés pratiquement à l'écart de la vie et
                du développement des peuples au-delà des frontières de l'Empire
                ottoman.
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                Enfin, et c'est le point qui nous intéresse le plus ici, la
                presse sépharade a également joué un rôle important dans le
                domaine littéraire. Nombre de nouveaux genres, tels que les
                romans et les pièces de théâtre, la poésie d'auteur, etc., ont
                trouvé refuge dans ses pages, devenant ainsi une fenêtre par
                laquelle non seulement les idées de progrès ont pénétré les
                communautés d'Europe de l'Est, mais aussi par laquelle les
                nouvelles formes littéraires ont été diffusées. En outre, un
                grand nombre de gens de presse, c'est-à-dire les éditeurs et les
                rédacteurs en chef de journaux, étaient liés à la nouvelle
                production littéraire en judéo-espagnol.
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                Les répertoires bibliographiques comprennent plus de 300
                journaux différents, dont la longueur, la périodicité et le
                caractère varient. Le journalisme judéo-espagnol a été divisé en
                trois grandes périodes, dont les jalons ont été marqués par son
                émergence après la révolution des Jeunes Turcs (1908) et la fin
                de la censure, et par sa quasi-extinction avec la Seconde Guerre
                mondiale. La période entre ces deux dates serait son "âge d'or"
                : en un peu plus de trente ans, plus de journaux ont été publiés
                qu'au cours des plus de soixante années qui ont précédé et des
                vingt et quelques années qui ont suivi. Auparavant, le
                journalisme sépharade avait été soit dépourvu de tradition à ses
                débuts, soit limité par la censure ferme d'Abdul Hamid ; après
                la Seconde Guerre mondiale, il n'y avait plus qu'une trentaine
                de journaux, et dans les quelques journaux de Constantinople qui
                ont dépassé les années 1950, le judéo-espagnol a peu à peu cédé
                la place au turc.
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                D'une manière générale, les deux tiers des journaux sont publiés
                dans quatre villes seulement : Salonique (105), Constantinople
                (45), Sofia (30) et Izmir (23). Vienne et les Etats-Unis suivent
                avec plus de dix titres, et d'autres villes comme Ruse
                (Ruschuk), Jérusalem, Plovdiv, Le Caire, etc. avec cinq et même
                moins.
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                L'orientation de la presse judéo-espagnole est très variable. Il
                y a des journaux d'information générale, politique et
                commerciale ; il y a ceux qui ont une intention expressément
                littéraire et même ceux qui sont principalement consacrés à la
                littérature ; il y en a aussi qui se consacrent à la compilation
                folklorique et à la diffusion historique, et d'autres qui se
                consacrent à la diffusion scientifique. Certains journaux sont
                des organes d'expression de clubs et d'associations, d'autres
                s'intéressent aux intérêts de certains groupes professionnels ou
                commerciaux. Un groupe important est l'organe d'expression de
                mouvements ou de courants d'opinion particuliers, tels que ceux
                qui s'intéressent aux idées nationalistes, socialistes,
                communistes et sionistes. Il y a également des journaux
                religieux et laïques, des journaux officiels et indépendants,
                etc.
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                Il faut noter l'abondance de la presse festive ou de
                divertissement, dont on connaît environ quarante-cinq titres
                différents, qui s'épanouit surtout après la révolution Jeune
                Turc de 1908 et la fin de la censure{" "}
                <HashLink to="#26" className="ftnLink" id="s26">
                  [26]
                </HashLink>
                .
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                Parmi les thèmes abordés par la presse judéo-espagnole, deux
                nous intéressent particulièrement et n'ont pas été suffisamment
                étudiés jusqu'à présent. Le premier est le thème de l'Espagne,
                c'est-à-dire les nombreuses nouvelles et commentaires qui se
                réfèrent à ce pays et qui nous montrent fréquemment l'image
                stéréotypée et défavorable entretenue par certains secteurs des
                Sépharades des Balkans au cours des siècles : l'Espagne est
                presque exclusivement le pays de l'Inquisition et de l'expulsion{" "}
                <HashLink to="#27" className="ftnLink" id="s27">
                  [27]
                </HashLink>{" "}
                ; dans cette section, les échos que les livres du sénateur
                Pulido et ses campagnes de récupération du monde sépharade{" "}
                <HashLink to="#28" className="ftnLink" id="s28">
                  [28]
                </HashLink>{" "}
                par l'Espagne ont suscité dans la presse judéo-espagnole
                sépharade sont d'un intérêt tout particulier. Le deuxième thème
                est celui des diverses controverses déclenchées dans la presse
                sur la survie du judéo-espagnol, qui montrent les opinions
                contradictoires des intellectuels des communautés, divisés entre
                ceux qui veulent garder leur langue telle quelle, ceux qui
                veulent la nettoyer des influences étrangères pour la rapprocher
                de l'espagnol d'Espagne, certains conseillant comme première
                mesure à prendre de se passer des anciens caractères hébraïques
                et d'utiliser les caractères occidentaux, et ceux qui préfèrent
                oublier le judéo-espagnol et le remplacer par une autre langue :
                le français, les langues locales - notamment le turc - ou
                l'hébreu. Les polémiques se déroulent bien sûr en
                judéo-espagnol, qui reste la langue comprise par la majorité{" "}
                <HashLink to="#29" className="ftnLink" id="s29">
                  [29]
                </HashLink>
                .
              </Typography>
              <Typography
                align="justify"
                variant="h6"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                3. LE PROCESSUS D'EXTINCTION ; LA SITUATION ACTUELLE
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                Au cours du vingtième siècle, le déclin de la littérature
                judéo-espagnole, désormais inéluctable, va s'opérer. Sa force
                créatrice a duré aussi longtemps qu'ont duré les conditions
                historiques et sociales qui l'ont rendue possible, à savoir :
                des communautés solides et stables qui ont maintenu une forte
                cohésion interne et avec une langue véhiculaire expressive, le
                judéo-espagnol, dont nous assistons aujourd'hui à l'agonie.
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                Les nouvelles notions culturelles susmentionnées qui ont émergé
                à la fin du XIXe siècle n'ont pas été reprises et intégrées dans
                ce qui aurait été de toute façon un nouvel état d'esprit
                hispano-sépharade, mais ont au contraire représenté le coup de
                grâce de cette culture en endommageant la seconde des deux
                composantes spécifiques du judéo-espagnol. En se jetant dans les
                bras de la langue et de la culture françaises, les intellectuels
                sépharades ont acquis l'idée que c'était la seule voie à suivre
                pour entrer dans la modernité et qu'il fallait se passer de la
                langue traditionnelle, le judéo-espagnol, qu'ils commençaient à
                considérer comme un "jargon abâtardi", un prolongement impur du
                castillan qui, en outre, et comme si cela ne suffisait pas,
                avait été la langue des inquisiteurs et des rois perfides qui
                avaient mis fin au judaïsme hispanique. Le mépris de ces
                intellectuels pour la langue qui leur avait servi de langue
                véhiculaire de communication et d'instrument de création
                littéraire a influencé le reste de la communauté sépharade et a
                fini par déterminer quelque chose d'antérieur mais non moins
                grave que l'abandon de la langue : sa relégation au rang de
                langue familière, le déracinement de sa tradition castillane, le
                désintérêt total pour ce qui avait été produit en langue
                sépharade au cours des siècles précédents et l'assimilation, en
                somme, de son comportement à celui des langues dépourvues de
                tradition littéraire.
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                Au cours des décennies de transition entre le XIXe et le XXe
                siècle, le flux migratoire mentionné plus haut vers les pays les
                plus avancés d'Europe et d'Amérique, leurs colonies en Afrique,
                la Palestine du mandat britannique, en Asie et même en Océanie
                s'est également produit. Dans les nouvelles circonstances du
                nouvel environnement géographique, le processus de déracinement
                de leur propre culture s'est poursuivi de manière irréversible.
                Dans les communautés décimées qui sont restées sur leur lieu
                d'implantation, la tentative marquée de nationalisation de leurs
                minorités, présente dans la Turquie d'Atatürk depuis les années
                1920 et dans les autres pays renaissant après le démembrement de
                l'Empire ottoman, a également joué en leur défaveur. Les
                communautés de certains pays (notamment la Grèce et
                l'ex-Yougoslavie) allaient subir un nouveau coup, littéralement
                mortel, avec la déportation et l'extermination que la barbarie
                nazie leur a fait subir au cours de la dernière guerre mondiale.
                Par la suite, l'émigration des Sépharades vers Israël s'est
                accélérée et, surtout au cours des premières décennies de ce
                jeune État, la tendance était à la fusion de tous les enfants
                d'immigrants originaires d'une grande variété de pays dans le
                creuset d'une culture israélienne commune.
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                Aujourd'hui, le monde culturel hispano-sépharade est à bout de
                souffle. Il ne reste qu'une petite communauté dans les pays de
                la diaspora sépharade traditionnelle à Istanbul. Les nouvelles
                communautés sépharades issues des flux migratoires déjà cités et
                ultérieurs, qui existent dans pratiquement tous les pays du
                monde occidental, sont sépharades d'un point de vue
                généalogique, mais pratiquement aucune d'entre elles n'est
                sépharade d'un point de vue culturel. Et malgré un certain
                renouveau qui s'est manifesté ces dernières années en certains
                endroits, il nous semble impensable que la langue, qui n'est
                plus littérairement créative depuis des années - sauf peut-être
                pour la poésie -, continue à vivre au-delà de la génération
                terminale actuelle, puisqu'il est difficile de trouver des
                locuteurs sépharades actifs de moins de cinquante ans.
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                Un commentaire particulier mérite d'être fait sur la question du
                renouveau, soutenu par un organisme public israélien pour
                étendre sa domination aux "Sépharades du monde entier unis" et
                diffusé par une revue tapageuse qui, pour des raisons plus
                politiques que culturelles, brandit agressivement l'étendard
                sépharade du "monde sépharade uni", brandit agressivement la
                bannière de l'écriture judéo-espagnole en caractères latins avec
                une norme totalement contraire à l'écriture hispanique, fondant
                sa plus grande gloire sur l'usage et l'abus du <em>ka</em>, qui
                laisse à l'Espagnol et à l'Hispaniste qui le lit l'impression de
                lire le bulletin d'un asile de squatters. Comme si cela ne
                suffisait pas, ce <em>néo-klub</em> sépharade, qui agit comme un
                grotesque <em>klub de plume</em>, considère que tout ce qu'écrit
                le premier sépharade qui passe dans la rue est une œuvre
                littéraire digne d'être publiée, simplement parce qu'elle est
                écrite en judéo-espagnol, alors que son niveau littéraire réel
                ferait honte à un élève de l'école primaire. A cet égard, nous
                ne pouvons manquer de souligner une chose importante : le fait
                que les très rares auteurs sépharades - et quand je dis auteurs,
                je veux dire poètes, car les autres genres ne sont pas cultivés
                - qui ont aujourd'hui atteint une qualité littéraire suffisante
                pour constituer un dernier chapitre (posthume) de la littérature
                sépharade, sont symptomatiquement ceux qui ont également
                accrédité cette qualité dans leurs langues de culture
                respectives, à savoir : l'anglais, le français, l'hébreu, le
                grec, le serbo-croate, etc.
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                Compte tenu de l'aveuglement des Espagnols en matière de
                politique culturelle, de leur méfiance et de leur timidité
                lorsqu'il s'agit de recevoir des critiques - il semble que la
                soi-disant légende noire ait tellement marqué notre conscience
                collective que nous fuyons comme un chat échaudé les situations
                qui nous semblent similaires alors qu'elles ne le sont pas -,
                nous assistons à des symptômes alarmants selon lesquels, dans
                les circonstances actuelles, les orientations politiques
                d'institutions culturelles espagnoles très renommées et d'autres
                instances officielles doivent remuer la queue face aux
                manifestations flagrantes, injustifiées et inutiles
                d'anti-hispanisme du club susmentionné, peut-être parce que ces
                institutions sont submergées par la jobardise et par la crainte
                de ce que ces gens ne viennent maintenant nous accuser
                d'antisémitisme, <em>bar minan</em> !
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                Mais... il y a une autre raison qui n'est pas si évidente a
                priori : le Sépharade « d'aujourd'hui » a aussi besoin de
                connaître les trésors de sa littérature « d'hier »
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                Cela peut sembler une évidence, mais ce n'en est pas une. En
                général - c'est-à-dire, sauf exceptions notables et rares - les
                Sépharades d'aujourd'hui ne connaissent pas leurs classiques,
                tombant dans la terrible erreur - très fréquente dans
                l'(in)culture de masse dans laquelle nous vivons - de penser
                comme nous l’avons dit plus haut que ce que nous ne connaissons
                pas n’existe pas.
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                Toute culture qui se veut cultivée{" "}
                <HashLink to="#30" className="ftnLink" id="s30">
                  [30]
                </HashLink>{" "}
                a une grande estime et considération pour ses classiques ;
                l'éducation que les jeunes reçoivent à partir de l'école
                secondaire est basée sur leurs lectures, et on les tient pour un
                modèle à suivre. Tel devrait être aussi le modèle des Sépharades
                d'aujourd'hui qui, au détriment d'une culture aussi digne, ont
                pourtant perdu le goût de la lecture des classiques d'hier,
                relégués dans le passé et de moins en moins compréhensibles.
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                Enfin, je m'interroge : si ce n'est dans les classiques et dans
                leur expression littéraire, où les écrivains sépharades
                d'aujourd'hui puiseront-ils leur sève nourricière, où
                plongeront-ils leurs racines pour engendrer des troncs sains et
                produire de nouvelles branches ?
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                Trouver une solution à ces questions est une tâche que nous,
                scientifiques, devons assumer, en faisant connaître les textes
                d'hier ; mais dans une mesure non moindre et avec une
                responsabilité non moins grande, c'est une tâche que les
                éducateurs doivent également assumer, en redonnant aux
                Sépharades d'aujourd'hui la considération et l'estime de leurs
                classiques.
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                Ainsi, et pour conclure, je dirai que les personnes des
                dernières générations ont dû assister à l'agonie de la
                littérature judéo-espagnole qui, dans ses facettes
                traditionnelles, ne survit -et déjà en état de décomposition-
                que dans les textes de transmission orale.
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                Comme je l'ai déjà dit à d'autres occasions, face à cette
                disparition inéluctable, déterminée par des facteurs historiques
                et sociologiques, il ne nous reste plus, à nous chercheurs, qu'à
                faire connaître à la communauté scientifique, par le biais
                d'éditions et d'études de textes, ce qu'a été pendant cinq
                siècles la littérature sépharade sous ses trois aspects de
                transmission orale religieuse, adoptée et traditionnelle. Et ce,
                non seulement pour sauver de la méconnaissance générale des
                œuvres littéraires largement inconnues des Espagnols et des
                Hispanistes, et parfois oubliées par les Sépharades eux-mêmes,
                mais aussi parce qu'il est évident que plus notre connaissance
                de cette littérature sera complète, plus notre connaissance de
                la littérature hispanique dans sa complexe universalité le sera
                aus
              </Typography>

              <Typography align="justify" variant="subtitle1" id="1">
                <HashLink to="#s1" className="ftnLink">
                  [1]{" "}
                </HashLink>{" "}
                J’aimerais prévenir que dans cet article, je ne recourrai pas
                aux signes diacritiques dont je me sers d’habitude pour
                transcrire en caractères latins les mots provenant du
                judéo-espagnol aljamiado et qui sont ceux utilisés par la Revue
                Sefarad du Conseil Supérieur de Recherche Scientifiques de
                Madrid (CSIC). De même, à la fin du texte, on peut consulter la
                bibliographie à laquelle je fais allusion brièvement. Concernant
                la présentation générale de la littérature judéo-espagnole, voir
                la bibliographie que je joins en note 4.
              </Typography>
              <Typography align="justify" variant="subtitle1" id="2">
                <HashLink to="#s2" className="ftnLink">
                  [2]{" "}
                </HashLink>{" "}
                Dans son article «Un género castizo» (1987).
              </Typography>
              <Typography align="justify" variant="subtitle1" id="3">
                <HashLink to="#s3" className="ftnLink">
                  [3]{" "}
                </HashLink>{" "}
                Voir aussi mon livre <em>Entre dos</em> (2008), qui, à travers
                182 textes poétiques, présente la vision sépharade des
                expériences historiques et sociales dans les Balkans des
                différentes communautés.
              </Typography>
              <Typography align="justify" variant="subtitle1" id="4">
                <HashLink to="#s4" className="ftnLink">
                  [4]{" "}
                </HashLink>{" "}
                Des présentations générales de la littérature judéo-espagnole
                ont déjà été faites à d'autres occasions ; voir, par exemple,
                HASSÁN - ROMERO "Sobre los sefardíes" (1981) ; et surtout les
                deux articles de HASSÁN "Visión panorámica" (1982) et "Hacia una
                visión panorámica" (1981), dont les abondantes références
                bibliographiques me dispensent ici de plus amples annotations.
                Voir aussi l'ouvrage collectif{" "}
                <em>Los sefardíes : Cultura y Literatura</em> (1987), où l'on
                trouve des présentations générales sur des thèmes historiques,
                linguistiques et littéraires, parmi lesquelles il convient de
                mentionner les sections consacrées aux genres des{" "}
                <em>coplas</em>, du théâtre, du <em>romancero</em>, de la
                chanson populaire, de la musique traditionnelle, etc. Un autre
                ouvrage général est le livre de DÍAZ-MAS, <em>Los sefardíes</em>{" "}
                (1986). Voir aussi mon livre La <em>creación literaria</em>{" "}
                (1992), où l'on trouve une vaste bibliographie sur les aspects
                traités ici.
              </Typography>
              <Typography align="justify" variant="subtitle1" id="5">
                <HashLink to="#s5" className="ftnLink">
                  [5]{" "}
                </HashLink>{" "}
                Voir la caractérisation générale faite par HASSÁN dans son
                article "El español sefardí" (1995).
              </Typography>
              <Typography align="justify" variant="subtitle1" id="6">
                <HashLink to="#s6" className="ftnLink">
                  [6]{" "}
                </HashLink>{" "}
                [note de la traductrice] ces catégories concernent les ensembles
                constitués par les ballades, les chansons, les proverbes et les
                contes populaires.
              </Typography>
              <Typography align="justify" variant="subtitle1" id="7">
                <HashLink to="#s7" className="ftnLink">
                  [7]{" "}
                </HashLink>{" "}
                Edité par Romeu Ferré (2007).
              </Typography>
              <Typography align="justify" variant="subtitle1" id="8">
                <HashLink to="#s8" className="ftnLink">
                  [8]{" "}
                </HashLink>{" "}
                Voir Hassán «Dos introducciones» (1994).
              </Typography>
              <Typography align="justify" variant="subtitle1" id="9">
                <HashLink to="#s9" className="ftnLink">
                  [9]{" "}
                </HashLink>{" "}
                Édité par ROMEU FERRÉ Crónica (1998) ; voir la bibliographie
                citée. Voir aussi son article "El sueño" (2004) ; et celui de I.
                M. HASSÁN et Pilar ROMEU "Apuntes" (1992).
              </Typography>
              <Typography align="justify" variant="subtitle1" id="10">
                <HashLink to="#s10" className="ftnLink">
                  [10]{" "}
                </HashLink>{" "}
                Les récits du <em>Me'am lo'ez</em> d’Exode ont été récemment
                édités par GARCÍA MORENO dans son livre Relatos (2004).
              </Typography>
              <Typography align="justify" variant="subtitle1" id="11">
                <HashLink to="#s11" className="ftnLink">
                  [11]{" "}
                </HashLink>{" "}
                Publié en 2005 ; voir dans la bibliographie Arié KAPLAN et Dafna
                LEA.
              </Typography>
              <Typography align="justify" variant="subtitle1" id="12">
                <HashLink to="#s12" className="ftnLink">
                  [12]{" "}
                </HashLink>{" "}
                Édité par ROMEU FERRÉ (2016) ; voir pp. 7-10 et dans la
                bibliographie en fin d'ouvrage les mentions d'autres ouvrages du
                <em>Me’am lo’ez</em> parus ces dernières années, ce qui
                m'empêche de faire ici d'autres références.
              </Typography>
              <Typography align="justify" variant="subtitle1" id="13">
                <HashLink to="#s13" className="ftnLink">
                  [13]{" "}
                </HashLink>{" "}
                Récemment édité par ROMERO - GONZÁLEZ BERNAL (2020).
              </Typography>
              <Typography align="justify" variant="subtitle1" id="14">
                <HashLink to="#s14" className="ftnLink">
                  [14]{" "}
                </HashLink>{" "}
                On trouvera une introduction générale au genre des{" "}
                <em>coplas</em> dans mon article "Las Coplas ... Categorías"
                (1981), et dans "Un género castizo" (1978) de HASSÁN ; voir
                aussi ROMERO <em>Creación</em>
                (1992). A cela s'ajoute mon livre, avec la collaboration de
                HASSÁN et CARRACEDO, <em>Bibliografía analítica</em> (1992), qui
                répertorie deux cent soixante-sept publications en contenant, la
                plupart <em>aljamiadas</em> et quelques-unes en caractères
                latins, entre 1700 et 1959 ; voir surtout l'"Introduction" de
                HASSÁN (pp. 9-20). Une anthologie monographique du genre se
                trouve également dans mon livre <em>Primera selección</em>{" "}
                (1991), où sont publiées une vingtaine de coplas représentatives
                de différents groupes thématiques, de différentes époques, de
                différentes formes de transmission -éditée, manuscrite et orale-
                et de leurs deux aires géographiques de diffusion -le détroit de
                Gibraltar et les Balkans- ; voir aussi l'"Introduction" de
                HASSAN (pp. 5-24). Sur la métrique des <em>coplas</em>, voir mon
                article "Formas estróficas" (1991).
              </Typography>
              <Typography align="justify" variant="subtitle1" id="15">
                <HashLink to="#s15" className="ftnLink">
                  [15]{" "}
                </HashLink>{" "}
                Outre mon dernier article cité dans la note précédente, voir
                aussi ROMERO "Las Coplas .... Vestiges ... e innovaciones de la
                poesía medieval hispana" (2016).
              </Typography>
              <Typography align="justify" variant="subtitle1" id="16">
                <HashLink to="#s16" className="ftnLink">
                  [16]{" "}
                </HashLink>{" "}
                Certains des livres et articles que j'ai publiés sur le sujet se
                trouvent dans la bibliographie.
              </Typography>
              <Typography align="justify" variant="subtitle1" id="17">
                <HashLink to="#s17" className="ftnLink">
                  [17]{" "}
                </HashLink>{" "}
                Dans son article «Un género castizo » (1978).
              </Typography>
              <Typography align="justify" variant="subtitle1" id="18">
                <HashLink to="#s18" className="ftnLink">
                  [18]{" "}
                </HashLink>{" "}
                Voir notamment le livre de HASSAN <em>Las coplas de Purim</em>{" "}
                (2010) et les différentes études de ROMERO mentionnées dans la
                bibliographie.
              </Typography>
              <Typography align="justify" variant="subtitle1" id="19">
                <HashLink to="#s19" className="ftnLink">
                  [19]{" "}
                </HashLink>{" "}
                La plupart des <em>coplas</em> mentionnées ici et beaucoup
                d'autres avec un contenu historique sont publiées dans mon livre
                <em>Entre dos</em> (2008).
              </Typography>
              <Typography align="justify" variant="subtitle1" id="20">
                <HashLink to="#s20" className="ftnLink">
                  [20]{" "}
                </HashLink>{" "}
                Voir, par exemple, certains de mes articles, comme "Canciones y
                <em>coplas</em> sefardíes de contenido gastronómico" (2010) et
                plusieurs autres rassemblés dans la bibliographie et surtout mon
                livre
                <em>Yantares</em> (2011).
              </Typography>
              <Typography align="justify" variant="subtitle1" id="21">
                <HashLink to="#s21" className="ftnLink">
                  [21]{" "}
                </HashLink>{" "}
                Voir ROMERO Y hubo luz (2008), "La emancipación de la mujer
                sefardí" (2010) pp. 303-362, etc.
              </Typography>
              <Typography align="justify" variant="subtitle1" id="22">
                <HashLink to="#s22" className="ftnLink">
                  [22]{" "}
                </HashLink>{" "}
                Sur ce genre littéraire, voir mes livres El teatro (1979), où je
                compile une vaste bibliographie, et <em>Repertorio</em> (1983) ;
                ainsi que mes articles "Aspectos literarios y sociológicos del
                teatro ..." (1987), et "Nueva bibliografía ..." (2006).
              </Typography>
              <Typography align="justify" variant="subtitle1" id="23">
                <HashLink to="#s23" className="ftnLink">
                  [23]{" "}
                </HashLink>{" "}
                Vid. par exemple, le récent livre de GRUSS <em>Las novelas</em>{" "}
                ... (2020).
              </Typography>
              <Typography align="justify" variant="subtitle1" id="24">
                <HashLink to="#s24" className="ftnLink">
                  [24]{" "}
                </HashLink>{" "}
                Voir, par exemple, le livre de GARCÍA MORENO et alia,{" "}
                <em>El Nuevo chico diccionario</em> ... (2021), dont la vaste
                bibliographie comprend plusieurs autres études linguistiques
                réalisées par l'auteur et d'autres chercheurs.
              </Typography>
              <Typography align="justify" variant="subtitle1" id="25">
                <HashLink to="#s25" className="ftnLink">
                  [25]{" "}
                </HashLink>{" "}
                Voir, par exemple, le livre de GARCÍA MORENO et ORFALI{" "}
                <em>La saga</em>
                (2018).
              </Typography>

              <Typography align="justify" variant="subtitle1" id="26">
                <HashLink to="#s26" className="ftnLink">
                  [26]{" "}
                </HashLink>{" "}
                Voir mon article "La prensa judeoespañola" (2007)
              </Typography>
              <Typography align="justify" variant="subtitle1" id="27">
                <HashLink to="#s27" className="ftnLink">
                  [27]{" "}
                </HashLink>{" "}
                Voir l'article d'ASENJO "España y los sefardíes" (2005).
              </Typography>
              <Typography align="justify" variant="subtitle1" id="28">
                <HashLink to="#s28" className="ftnLink">
                  [28]{" "}
                </HashLink>{" "}
                Voir à cet égard l'article de DÍAZ-MAS "Repercusión" (2000).
                Voir également l'article de GARCÍA MORENO "La Guerra Civil
                Española ..." (2020).
              </Typography>
              <Typography align="justify" variant="subtitle1" id="29">
                <HashLink to="#s29" className="ftnLink">
                  [29]{" "}
                </HashLink>{" "}
                Voir par exemple mes articles "La polémica ... materiales para
                su estudio" (2010) et "La polémica ... Plus de matériel pour son
                étude" (2010).
              </Typography>
              <Typography align="justify" variant="subtitle1" id="30">
                <HashLink to="#s30" className="ftnLink">
                  [30]{" "}
                </HashLink>{" "}
                C'est ce qu'a déjà dit Jacob M. Hassan dans sa conférence de
                clôture du 6e congrès de la <em>Misgav Yerushlayaim</em>{" "}
                (Jérusalem 2000).
              </Typography>

              <br />
              <br />

              <Typography
                align="justify"
                variant="h6"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                Bibliographie
              </Typography>

              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                Rosa Asenjo, «España y los sefardíes: el desastre del 98 en El
                Avenir», Sefarad 65 (2005) pp. 3-40.
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                Paloma Díaz-Mas, Los sefardíes: Historia, lengua y cultura
                (Barcelona, 1986).
              </Typography>

              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                –––, «Repercusión de la campaña de Ángel Pulido en la opinión
                pública de su época: La respuesta sefardí», en España y la
                cultura hispánica en el sureste europeo (Atenas 2000) pp.
                326-339.
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                Aitor García Moreno, Relatos del pueblo ladinán (Me‘am lo‘ez de
                Éxodo) (Madrid, CSIC, 2004).
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                ––– y Moisés Orfali, La saga de los reyes otomanos. Edición
                crítica y estudio de tres versiones sefardíes del Séfer Sipur
                maljé ‘otmanlim (Constantinopla 1767 y 1863, y Kazanlâk ca.
                1815) (Granada: Editorial Universidad de Granada, 2018).
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                –––, «La Guerra Civil Española en la prensa sefardí: el caso del
                periódico Acción de Salónica», Cadernos de Estudos Sefarditas 23
                (2020) pp. 11-46.
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                ––– y Elisabeth Fernández Martín y Cristóbal José Álvarez López,
                El Nuevo chico diccionario judeo-español-francés de Selomó
                Yisrael Sereslí (Jerusalén, 1898-1899) ... (Berlín: Peter Lang,
                2021).
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                Susy Gruss, Las novelas de Judá Haim Perahiá (Salónica 1886 -
                Xanthi 1970) (Barcelona: Tirocinio, 2020).
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                Iacob M. Hassán, «Hacia una visión panorámica de la literatura
                sefardí», en Actas de las Jornadas de Estudios Sefardíes
                (Cáceres: Univ. de Extremadura, 1981) pp. 51-68.
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                I. M. Hassán y E. Romero, «Sobre los sefardíes y su literatura»,
                en Temas sefardíes: Del cancionero sefardí (Madrid: Ministerio
                de Cultura, 1981) pp. 13-30.
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                –––, «Visión panorámica de la literatura sefardí», en Hispania
                Judaica vol. II (Barcelona 1982) pp. 24-44.
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                –––, «Un género castizo sefardí: Las Coplas», en Los sefardíes:
                Cultura y Literatura (San Sebastián, 1987) pp. 103-123.
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                ––– y Pilar Romeu, «Apuntes sobre la lengua de la Crónica de los
                Reyes otomanos de Moisés Almosnino según la edición del
                manuscrito aljamiado del siglo xvi», en M. Ariza et al. (eds.),
                Actas del II Congreso Internacional de Historia de la Lengua
                Española (Madrid, Pabellón de España, 1992) vol. II, pp.
                161-169.
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                I. M. Hassán, «Dos introducciones de la Biblia de Ferrara», en
                I. M. Hassán (ed.), Introducción a la Biblia de Ferrara ...
                (Madrid, CSIC et al., 1994) pp. 13-66.
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                Iacob M. Hassán, «El español sefardí (judeoespañol, ladino)», en
                M. Seco y G. Salvador (coords.), La lengua española, hoy
                (Madrid, Fundación Juan March, 1995) pp. 117-140.
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                –––, Las coplas de Purim (Madrid: Hebraica Ediciones, 2010).
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                Arié Kaplan (trad. del judeoespañol al inglés) y Dafna Lea
                (trad. del inglés al español), Me’am lo’ez ... Levitico (New
                York, Jerusalem: Moznaim Publishing Corporation, 2005).
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                Elena Romero, El teatro de los sefardíes orientales, 3 vols.
                (Madrid: CSIC, 1979).
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                ––– «Las Coplas sefardíes: Categorías y estado de la cuestión»,
                en Actas de las Jornadas de Estudios Sefardíes (Cáceres, 1981)
                pp. 69-98.
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                –––, Repertorio de noticias sobre el mundo teatral de los
                sefardíes orientales (Madrid: CSIC, 1983).
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                –––, «Aspectos literarios y sociológicos del teatro de los
                sefardíes de los Balcanes», en Los Sefardíes: Cultura y
                Literatura (San Sebastián, 1987) pp. 171-189.
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                –––, Coplas sefardíes, Primera selección de Coplas sefardíes
                (Córdoba: El Almendro, 1991).
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                –––, «Formas estróficas de las coplas sefardíes», en F.
                Corriente y A. Sáenz-Badillos (eds.), Poesía estrófica, Actas
                del pimer congreso internacional sobre poesía estrófica árabe y
                hebrea y sus paralelos romances (Madrid: Facultad de Filología,
                Univ. Complutense et al., 1991) pp. 259-278.
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                –––, La creación literaria en lengua sefardí (Madrid: Fundación
                Mapfre, 1992).
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                –––, con la colaboración de I. M. Hassán y de L. Carracedo,
                Bibliografía analítica de ediciones de coplas sefardíes (Madrid:
                CSIC, 1992).
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                –––, «The Theme of Spain in the Sephardic Haskalah’s
                Literature», en Moshe Lazar and Stephen Haliczer (eds.), The
                Jews of Spain and the Expulsion of 1492 (Lancaster, California,
                1997) pp. 311-327.
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                –––, «El tema de España en la literatura sefardí contemporánea»,
                en Norbert Rehrmann (comp.), El legado de Sefarad en la historia
                y la literatura de América Latina, España, Portugal y Alemania
                (Salamanca: Amarú Ediciones, 2003) pp. 181-200.
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                –––, «Nueva bibliografía de ediciones de obras de teatro
                sefardíes», Sefarad 66 (2006) pp. 183-217.
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                –––, «La prensa judeoespañola contra los recelos, la burocracia
                y la censura», en Pablo Martín Asuero y Karen Gerson Sarhon,
                Ayer y hoy de la prensa en judeoespañol ... (Estambul: Ed. Isis,
                2007) pp. 9-35.
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                –––, Entre dos (o más) fuegos: Fuentes poéticas para la historia
                de los sefardíes de los Balcanes (Madrid: CSIC, 2008).
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                –––, Y hubo luz y no fue tan buena: Las coplas sefardíes de
                Purim y los tiempos modernos (Barcelona: Tirocinio, 2008).
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                –––, «Textos poéticos sobre la emancipación de la mujer sefardí
                en el mundo de los Balcanes», Sefarad 69/1 (en.-jun. 2009) pp.
                173-227, y 69/2 (jul.-dic. 2009) pp. 427-476.
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                –––, «Canciones y coplas sefardíes de contenido gastronómico»,
                en Uriel Macías y Ricardo Izquierdo Benito (eds.), La mesa
                puesta: Leyes, costumbres y recetas judías (Cuenca: Ed. de la
                Univ. de Castilla-La Mancha, 2010) pp. 171-211.
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                –––, «La emancipación de la mujer sefardí a través de textos
                poéticos», en Yolanda Moreno Koch y Ricardo Izquierdo Benito
                (coords.), Hijas de Israel, mujeres de Sefarad: de las aljamas
                de Sefarad al drama del exilio (Cuenca: Ed. de la Univ. de
                Castilla-La Mancha, 2010) pp. 303-362.
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                –––, «La polémica sobre el judeoespañol en la prensa sefardí del
                Imperio otomano: materiales para su estudio», en Paloma Díaz-Mas
                y María Sánchez Pérez (eds.), Los sefardíes ante los retos del
                mundo contemporáneo: Identidad y mentalidades (Madrid: CSIC
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                –––, «La polémica sobre el judeoespañol en la prensa sefardí del
                Imperio otomano: Más materiales para su estudio», Sefarad 70/2
                (jul.-dic. 2010) pp. 435-473.
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                –––, «La copla sefardí de El debate de los frutos y el vino y
                sus ecos en la tradición oral», en Elena Romero (ed.), Estudios
                sefardíes dedicados a la memoria de Iacob M. Hassán (z”l)
                (Madrid: CSIC, 2011) pp. 491-524.
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                –––, Los yantares de Purim: coplas y poemas sefardíes de
                contenido folclórico (Barcelona: Ed. Tirocinio, 2011) 475 pp.
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                ––– y Aitor García Moreno, «Del narguilé a los cigarrillos: un
                ejemplo de la modernización del mundo sefardí de los Balcanes»,
                en Rosa Sánchez y Marie-Christine Bornes Varol (eds.), Le presse
                judéo-espagnole, support et vecteur de la modernité (Istanbul:
                Libra Kitap, 2013) pp. 113-141.
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                –––, «Las coplas sefardíes novísimas de Purim: temas y tópicos»,
                en Yvette Bürki y Elena Romero (eds.), La lengua sefardí,
                Aspectos lingüísticos, literarios y culturales (Berlin: Frank &
                Timme, Romanistik Band 16, 2014) pp. 151-193.
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                –––, «Otra versión sefardí manuscrita de El debate de los frutos
                y el vino», Sefarad 74/2 (jul.-dic. 2014) pp. 427-452.
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                –––, «Las Coplas sefardíes: Vestigios e innovaciones de la
                poesía medieval hispana», en Constance Carta y Sarah Finci y
                Dora Mancheva (eds.), Antes se agotan la mano y la pluma que su
                historia, Homenaje a Carlos Alvar, 2 vols. (Cilengua, Fundación
                de San Millán de la Cogolla, 2016) vol. I, pp. 895-916.
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                ––– y José Manuel González Bernal, Me‘am lo‘ez Cohélet:
                Comentario sefardí al libro bíblico de Eclesiastés del rabino
                Salomón Hakohén; Edición y estudio (CSIC, Fundación San Millán,
                2020).
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                Pilar Romeu Ferré, Crónica de los reyes otomanos (Barcelona
                1998).
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                –––, «El sueño premonitorio de Moisés Almosnino sobre Yosef Nasí
                en el Tratado de los sueños (Salónica 1564)», Sefarad 64 (2004)
                pp. 159-193.
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                –––, Fuente clara (Salónica, 1595) (Barcelona: Tirocinio, 2007).
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                –––, El Meam loez de Josué ... (Barcelona: Tirocinio, 2016).
              </Typography>
              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                Los sefardíes: Cultura y Literatura (San Sebastián: Univ. del
                País Vasco
              </Typography>
            </Box>
          </Box>
        </Container>
      </section>
    </>
  );
}
