import { styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";

export const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    maxWidth: 200,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}));

export const BootstrapInputRTL = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    direction: "rtl",
    maxWidth: 200,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}));

export const fetchData = async (
  url,
  lang,
  targetLang,
  query,
  strict,
  before,
  pageNumber,
  pageSize,
  token,
  setErrMsg,
  setResult,
  setShowTable,
  setLoading,
  setCount
) => {
  try {
    await setShowTable(false);
    const res = await fetch(
      `${url}?sourceLanguage=${lang}&targetLanguages=${targetLang}&query=${query}&strict=${strict}&contextSize=${before}&pageNumber=${pageNumber}&pageSize=${pageSize}`,
      {
        method: "GET",
        headers: new Headers({
          Authorization: `Bearer ${token}`,
          accept: "application/json",
        }),
        mode: "cors",
      }
    );
    if (!res.ok) {
      const message = `An error has occurred: ${res.status} - ${res.statusText}`;
      setErrMsg(message);
    }
    const data = await res.json();
    console.log("data", data, pageNumber, pageSize);
    setResult(data.results);
    setCount(data.totalCount);
    setErrMsg("");
    setShowTable(true);
    setLoading(false);
  } catch (err) {
    setLoading(false);
    console.log(err.message);
  }
};
