import React from "react";
import { Paper, Typography } from "@mui/material";
import Box from "@mui/material/Box";

const ParallelTextDisplay = ({ data }) => {
  const maxSentences = Math.max(data.fr.length, data.yd.length);

  return (
    <Paper elevation={3} sx={{ padding: 4 }} my={10}>
      {/* Introduction section */}
      <Box my={3} textAlign="center">
        <Typography variant="h5" component="h6" color="primary">
          {data.title}
        </Typography>
      </Box>
      <Box display="flex" justifyContent="center" alignItems="center">
        <Box
          ml={2}
          mt={2}
          mb={8}
          sx={{
            "& p": {
              textIndent: "30px",
              marginBottom: "20px",
              textAlign: "justify",
              fontFamily: "Georgia, serif", // optional for a more "book-like" font
              lineHeight: "1.5",
            },
          }}
        >
          <Typography align="justify" variant="subtitle1" component="h2">
            {data.introduction}
          </Typography>
        </Box>
      </Box>
      <div
        style={{
          display: "flex",
          borderBottom: "1px solid #ccc",
          paddingBottom: "10px",
        }}
      >
        <div style={{ flex: 1 }}>
          {Array.from({ length: maxSentences }).map((_, index) => (
            <div
              key={index}
              style={{
                borderBottom: "1px solid #ddd",
                marginBottom: "5px",
                display: "flex",
                alignItems: "flex-start", // Align sentences at the top
              }}
            >
              <div style={{ flex: 1 }}>
                {index < data.fr.length && (
                  <Typography
                    variant={data.fr[index].type === "title" ? "h6" : "body1"}
                    color={
                      data.fr[index].type === "subtitle"
                        ? "secondary"
                        : data.fr[index].type === "title"
                        ? "primary"
                        : "initial"
                    }
                  >
                    {data.fr[index].value}
                  </Typography>
                )}
              </div>
              <div
                style={{
                  flex: 1,
                  marginLeft: "10px",
                  paddingLeft: "10px",
                }}
              >
                {index < data.yd.length && (
                  <Typography
                    variant={data.yd[index].type === "title" ? "h5" : "body1"}
                    color={
                      data.fr[index].type === "subtitle"
                        ? "secondary"
                        : data.fr[index].type === "title"
                        ? "primary"
                        : "initial"
                    }
                  >
                    {data.yd[index].value}
                  </Typography>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </Paper>
  );
};

export default ParallelTextDisplay;
