import {makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
    name: {
        lineHeight: 1,
    },
    content: {
        [theme.breakpoints.up('md')]: {
            paddingLeft: theme.spacing(8),
            paddingRight: theme.spacing(8)
        }
    },
    paragraph: {
        marginBottom: theme.spacing(3)
    },
    image: {
        maxWidth: '100%',
        borderRadius: theme.shape.borderRadius
    }
}));