import React, { useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Navbar from "./components/NavBar";
import HomePage from "./pages/HomePage";
import CorpusPage1 from "./pages/CorpusPage1";
import HistoryPage from "./pages/HistoryPage";
import HistoryPageEs from "./pages/HistoryPageEs";
import Footer from "./components/Footer";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import keycloak from "./Keycloak";
import LoginPage from "./pages/LoginPage";
import ParallelTextsPage from "./pages/ParallelTextsPage";

const initOptions = {
  checkLoginIframe: true, // Set this to true to enable token refreshing
  onLoad: "check-sso",
};

function App() {
  let languageStoredInLocalStorage = localStorage.getItem("language");
  let [language, setLanguage] = useState(
    languageStoredInLocalStorage ? languageStoredInLocalStorage : "Français"
  );
  return (
    <>
      <ReactKeycloakProvider authClient={keycloak} initOptions={initOptions}>
        <BrowserRouter>
          <Navbar
            language={language}
            handleSetLanguage={(language) => {
              setLanguage(language);
              storeLanguageInLocalStorage(language);
            }}
          />
          <Routes>
            <Route exact path="/" element={<HomePage language={language} />} />
            <Route exact path="/history" element={<HistoryPage />} />
            <Route exact path="/historyEs" element={<HistoryPageEs />} />
            <Route exact path="/login" element={<LoginPage />} />
            <Route exact path="/texts" element={<ParallelTextsPage />} />
            {/*<Route*/}
            {/*  exact*/}
            {/*  path="/corpus"*/}
            {/*  element={*/}
            {/*    <PrivateRoute>*/}
            {/*      {" "}*/}
            {/*      <CorpusPage1 language={language} />{" "}*/}
            {/*    </PrivateRoute>*/}
            {/*  }*/}
            {/*/>*/}
            <Route
              exact
              path="/corpus"
              element={<CorpusPage1 language={language} />}
            />
          </Routes>
          <Footer language={language} />
        </BrowserRouter>
      </ReactKeycloakProvider>
    </>
  );
}

function storeLanguageInLocalStorage(language) {
  localStorage.setItem("language", language);
}

export default App;
