import React from "react";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { contentEnglish, contentFrench } from "./content";
import { useStyles } from "../helpers/UseStyles";

export default function LoginPage(props) {
  const classes = useStyles();

  let content = {};
  props.language === "Français"
    ? (content = contentFrench["about"])
    : (content = contentEnglish["about"]);

  return (
    <>
      <section>
        <Container maxWidth="md">
          <Box py={10}>
            <Box textAlign="center" mb={3}>
              <Container maxWidth="sm">
                <Box my={5}>
                  <Typography variant="h5" component="h5" color="primary">
                    Please log in in order to use the corpus
                  </Typography>
                </Box>
              </Container>
            </Box>
            <Box className={classes.content}></Box>
          </Box>
        </Container>
      </section>
    </>
  );
}
