import React, { useContext, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import {
  Alert,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  MenuItem,
  Select,
  Stack,
} from "@mui/material";
import AutorenewRoundedIcon from "@material-ui/icons/AutorenewRounded";
import InputLabel from "@mui/material/InputLabel";
import Button from "@material-ui/core/Button";
import ResultTable from "../components/ResultTable";
import { BootstrapInput } from "../helpers/corpusPageHelpers";
import CustomizedSelects from "../components/CustomizedSelects";
import { contentEnglish, contentFrench } from "./content";
import { useKeycloak } from "@react-keycloak/web";
import CircularProgress from "@mui/material/CircularProgress";
import { fetchData } from "../helpers/corpusPageHelpers";
import LoginPage from "./LoginPage";

const useStyles = makeStyles((theme) => ({
  post: {
    marginBottom: theme.spacing(4),
    [theme.breakpoints.up("md")]: {
      marginBottom: 0,
    },
  },

  section: {
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    paddingBottom: theme.spacing(12),
    [theme.breakpoints.up("md")]: {
      paddingTop: theme.spacing(15),
      paddingBottom: theme.spacing(15),
    },
  },
  img: {
    maxWidth: "100%",
    borderRadius: theme.shape.borderRadius,
  },
  bullet: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    color: theme.palette.text.hint,
  },
  name: {
    lineHeight: 1,
  },
}));

export default function CorpusPage1(props) {
  const classes = useStyles();
  const [firstLang, setFirstLang] = useState("Yiddish");
  const [secondLang, setSecondLang] = useState("Français");
  const [before, setBefore] = useState("1");
  const [query, setQuery] = useState("");
  const [result, setResult] = useState({});
  const [showTable, setShowTable] = useState(false);
  const [strict, setStrict] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const { keycloak } = useKeycloak();
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  let content = {};
  props.language === "Français"
    ? (content = contentFrench.corpusPage)
    : (content = contentEnglish.corpusPage);

  function handleChangeFirstLang(e) {
    setResult({});
    setShowTable(false);
    setFirstLang(e.target.value);
    if (e.target.value === "Yiddish") {
      setSecondLang("Français");
    } else {
      setSecondLang("Yiddish");
    }
  }

  function handleChangeSecondLang(e) {
    setResult({});
    setShowTable(false);
    setSecondLang(e.target.value);
    if (e.target.value === "Yiddish") {
      setFirstLang("Français");
    } else {
      setFirstLang("Yiddish");
    }
  }

  function handleChangeIcon() {
    setResult({});
    setShowTable(false);
    if (firstLang === "Yiddish") {
      setFirstLang("Français");
      setSecondLang("Yiddish");
    } else if (firstLang === "Français") {
      setFirstLang("Yiddish");
      setSecondLang("Français");
    }
  }

  const handleSubmit = () => {
    setPage(0);
    setRowsPerPage(10);
    getData().then((r) => setLoading(false));
  };

  useEffect(() => {
    getData().then((r) => setLoading(false));
  }, [page, rowsPerPage]);

  async function getData() {
    setLoading(true);
    let lang = "yi";
    let targetLang = "fr";
    if (firstLang === "Français") {
      lang = "fr";
      targetLang = "yi";
    }
    if (query) {
      await fetchData(
        process.env.REACT_APP_API_URL,
        lang,
        targetLang,
        query,
        strict,
        before,
        page + 1,
        rowsPerPage,
        keycloak.token,
        setErrMsg,
        setResult,
        setShowTable,
        setLoading,
        setCount
      );
    }
  }

  return (
    <>
      {keycloak.authenticated ? (
        <section className={classes.section}>
          <Container maxWidth="lg">
            <Box justifyContent="center">
              <Typography
                align="center"
                variant="h2"
                component="h2"
                color="primary"
              >
                {content["title"]}
              </Typography>
            </Box>
            <Box py={10}>
              <Grid container spacing={10} justifyContent="center">
                <Grid
                  item
                  xs={10}
                  md={5}
                  className={classes.post}
                  style={{ backgroundColor: "#A6C0F7", borderRadius: "5px" }}
                >
                  <Stack
                    spacing={2}
                    direction="row"
                    mb={1}
                    justifyContent="left"
                    alignItems="center"
                    flexWrap="wrap"
                  >
                    <FormControl
                      sx={{ m: 1, width: "100px" }}
                      variant="standard"
                    >
                      <InputLabel id="firstLang"> </InputLabel>
                      <Select
                        labelId="firstLang"
                        id="selectFirstLang"
                        input={<BootstrapInput />}
                        value={firstLang}
                        onChange={(e) => handleChangeFirstLang(e)}
                      >
                        <MenuItem value={"Yiddish"}>Yiddish</MenuItem>
                        <MenuItem value={"Français"}>Français</MenuItem>
                      </Select>
                      <FormHelperText>{content["firstLang"]}</FormHelperText>
                    </FormControl>
                    <AutorenewRoundedIcon
                      className="corpusIcon"
                      style={{ marginTop: "5px" }}
                      onClick={() => handleChangeIcon()}
                    />
                    <FormControl
                      sx={{ m: 1, width: "100px" }}
                      variant="standard"
                    >
                      <InputLabel id="secondLang"> </InputLabel>
                      <Select
                        labelId="secondLang"
                        id="selectSecondLang"
                        input={<BootstrapInput />}
                        value={secondLang}
                        onChange={(e) => handleChangeSecondLang(e)}
                      >
                        <MenuItem value={"Yiddish"}>Yiddish</MenuItem>
                        <MenuItem value={"Français"}>Français</MenuItem>
                      </Select>
                      <FormHelperText>{content["secondLang"]}</FormHelperText>
                    </FormControl>
                  </Stack>
                  <Box mb={2}>
                    <CustomizedSelects
                      before={before}
                      query={query}
                      setBefore={setBefore}
                      setQuery={setQuery}
                      language={props.language}
                      firstlang={firstLang}
                    />
                  </Box>

                  <Box m={1} mb={2}>
                    <FormGroup>
                      <FormControlLabel
                        control={<Checkbox />}
                        label={content["strict"]}
                        value={strict}
                        onChange={(e) => setStrict(!strict)}
                      />
                    </FormGroup>
                  </Box>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleSubmit()}
                    disabled={!query}
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </Box>
            {!errMsg ? null : (
              <Alert severity="error" sx={{ width: "80%", margin: "auto" }}>
                {errMsg}
              </Alert>
            )}
            {loading && (
              <Box
                sx={{ display: "flex", margin: "auto" }}
                justifyContent="center"
              >
                <CircularProgress />
              </Box>
            )}
            {showTable && result ? (
              <ResultTable
                result={result}
                lang2={secondLang}
                lang1={firstLang}
                url={process.env.REACT_APP_API_URL}
                before={before}
                query={query}
                strict={strict}
                token={keycloak.token}
                count={count}
                page={page}
                setPage={setPage}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
              />
            ) : null}
          </Container>
        </section>
      ) : (
        <LoginPage />
      )}
    </>
  );
}
