import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { HashLink } from "react-router-hash-link";
import "../style/App.css";

const useStyles = makeStyles((theme) => ({
  name: {
    lineHeight: 1,
  },
  content: {
    [theme.breakpoints.up("md")]: {
      paddingLeft: theme.spacing(8),
      paddingRight: theme.spacing(8),
    },
  },
  paragraph: {
    marginBottom: theme.spacing(3),
  },
  image: {
    maxWidth: "100%",
    borderRadius: theme.shape.borderRadius,
  },
}));

export default function HistoryPage(props) {
  const classes = useStyles();
  return (
    <>
      <section>
        <Container maxWidth="md">
          <Box py={10}>
            <Box textAlign="center" mb={5}>
              <Container maxWidth="sm">
                <Box my={5}>
                  <Typography variant="h4" component="h4" color="primary">
                    La traduction du yiddish en France
                  </Typography>
                  <Typography variant="h5" component="h5">
                    Histoire d’une pratique, mémoire d’un monde disparu
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="center" alignItems="center">
                  <Box ml={2} textAlign="left">
                    <Typography
                      align="justify"
                      variant="subtitle1"
                      component="h2"
                      className={classes.name}
                    >
                      Malena Chinski*
                    </Typography>
                  </Box>
                </Box>
              </Container>
            </Box>
            <Box className={classes.content}>
              <Typography
                align="justify"
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                Dans un article paru dans le quotidien bundiste{" "}
                <em>Undzer shtime</em> en novembre 1948, un journaliste signant
                sous le nom Henri Montmartre déplorait&nbsp;le fait que,
                contrairement à d’autres littératures étrangères accessibles en
                version française, la littérature yiddish ressemblait à
                «&nbsp;un parent pauvre&nbsp;»{" "}
                <HashLink to="#1" className="ftnLink" id="s1">
                  [1]
                </HashLink>
                . Sous ce pseudonyme, le futur chercheur et «&nbsp;militant
                acharné de la mémoire&nbsp;» Henri Minczeles (1926-2017){" "}
                <HashLink to="#2" className="ftnLink" id="s2">
                  [2]
                </HashLink>{" "}
                affirmait que pour remédier à ce manque, les institutions
                culturelles juives devaient élaborer un plan de traductions en
                français des classiques du yiddish{" "}
                <HashLink to="#3" className="ftnLink" id="s3">
                  [3]
                </HashLink>
                .
              </Typography>

              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                Son constat était juste. En nous appuyant sur une base de
                données de 176 traductions directes du yiddish publiées sous
                forme de livre en France depuis 1910, on constate en effet que
                moins de 5 % du chiffre total parurent au cours de la première
                moitié du XXe siècle (Tableau 1){" "}
                <HashLink to="#4" className="ftnLink" id="s4">
                  [4]
                </HashLink>
                .
              </Typography>

              <Box my={4}>
                <img
                  src="assets/img/hist_table.png"
                  alt=""
                  className={classes.image}
                />

                <Typography
                  align="justify"
                  variant="body1"
                  color="textSecondary"
                  paragraph={true}
                >
                  Tableau 1. Traductions du yiddish publiées sous la forme de
                  livre en France, 1910-2020{" "}
                </Typography>
              </Box>

              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                Si un « monde yiddish » surgit bien en France dès les années
                1880 à partir des vagues migratoires de Juifs d’Europe orientale{" "}
                <HashLink to="#5" className="ftnLink" id="s5">
                  [5]
                </HashLink>
                , la traduction du yiddish en tant que pratique intellectuelle
                et culturelle ne s’y développa guère, à l’exception de quelques
                pionniers comme Lupus Blumenfeld (1889-1932){" "}
                <HashLink to="#6" className="ftnLink" id="s6">
                  [6]{" "}
                </HashLink>{" "}
                et Edmond Fleg (1874-1963){" "}
                <HashLink to="#7" className="ftnLink" id="s7">
                  {" "}
                  [7]
                </HashLink>
                .
              </Typography>

              <Typography
                align="justify"
                variant="h6"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                Débuts de la traduction dans l’après-guerre : les premiers
                traducteurs rescapés
              </Typography>

              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                Après la Deuxième Guerre mondiale, la vie culturelle en yiddish
                connaît une nouvelle expansion à Paris grâce à l’arrivée
                d’écrivains, artistes et activistes politiques et communautaires
                juifs venus d’Europe de l’Est{" "}
                <HashLink to="#8" className="ftnLink" id="s8">
                  [8]
                </HashLink>
                . Ceux-ci rejoignirent les yiddishophones ayant survécu à la
                Shoah sur le territoire français. Parmi ces milliers de rescapés
                se trouvaient une poignée d’individus de diverses origines,
                arrivés en France avant ou après la guerre, et pour lesquels la
                traduction constituait un aspect de leur activité
                intellectuelle, même s’il n’était pas central. D’où le nombre
                graduellement croissant de traductions publiées dans les années
                1950 (7 titres) et surtout 1960 (18 titres). Ces initiatives ne
                participaient alors d’aucun programme culturel organisé,
                surgissant indépendamment les unes des autres grâce à
                l’initiative de ces intellectuels juifs d’origine
                est-européenne.
              </Typography>

              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                Il faut d’abord évoquer le cas exceptionnel de l’écrivain
                d’origine alsacienne Arnold Mandel (1913-1987){" "}
                <HashLink to="#9" className="ftnLink" id="s9">
                  [9]
                </HashLink>
                . Né à Strasbourg au sein d’une famille venant de Galicie, il
                fit des études de germanistique à la Sorbonne. Pendant la guerre
                il s’enfuit d’abord en Afrique du Nord. S’étant engagé par la
                suite dans la Résistance à Toulouse, il dut passer en Suisse où
                il fut interné dans un camp de réfugiés. Auteur de nombreux
                ouvrages sur le judaïsme, il traduisit du yiddish quatre
                ouvrages, parmi lesquels Ghetto à l’Est. Vilnius, 1941-1943
                (Paris, Robert Marin, 1950) du médecin et chercheur survivant du
                ghetto de Vilna, Marc Dvorjetski [Mark/Meir Dworzetski]
                (1908-1975), qui résida en France après la guerre jusqu’à son
                départ pour Israël en 1949{" "}
                <HashLink to="#10" className="ftnLink" id="s10">
                  [10]
                </HashLink>
                . Arnold Mandel fut ainsi un pionnier dans la traduction
                d’écrits sur la destruction, qui occupent une place centrale
                dans le corpus total des traductions du yiddish en France{" "}
                <HashLink to="#11" className="ftnLink" id="s11">
                  [11]
                </HashLink>
                . De sa plume parut aussi la traduction, préfacée par l’écrivain
                français Pierre Gascar, du récit Les Morts ne versent pas de
                larmes (Paris, Société des Éditions Modernes, 1964) du survivant
                galicien Yoysef Vaynberg [Joseph Weinberg] qui s’installa à
                Paris en 1947 après son retour des camps en Pologne.
              </Typography>

              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                {" "}
                De cette période se détache aussi la figure de l’éducateur et
                musicien Isaac Pougatch (1897-1988). Ce dernier avait déjà
                traduit, juste avant la guerre, le roman classique de Sholem
                Asch (1880-1957) Le Juif aux Psaumes (Paris, Flammarion, 1939),
                traduction faite en collaboration avec son épouse, l’écrivaine
                d’origine russe Juliette Pary (1903-1950){" "}
                <HashLink to="#12" className="ftnLink" id="s12">
                  [12]
                </HashLink>
                . Né à Kiev, Pougatch s’installa à Paris au début des années
                1930. Pendant les années noires, il s’occupa d’enfants réfugiés
                en Suisse ; après-guerre il se consacra à la formation
                d’éducateurs juifs{" "}
                <HashLink to="#13" className="ftnLink" id="s13">
                  [13]
                </HashLink>
                . En plus de son œuvre prolifique d’essayiste et de pédagogue,
                il publia une traduction de récits de Sholem-Aleykhem en
                collaboration avec l’écrivain d’origine lituanienne Joseph
                Gottfarstein (1903-1980){" "}
                <HashLink to="#14" className="ftnLink" id="s14">
                  [14]
                </HashLink>
                , ainsi qu’une version française du témoignage de Charles
                Goldstein (Khayim Itsl Goldshteyn), déporté de Paris à
                Auschwitz, puis envoyé par les nazis à Varsovie pour déblayer
                les ruines du ghetto. Là, il rejoignit l’insurrection de
                Varsovie, puis se réfugia dans une cave avec un groupe d’autres
                survivants juifs{" "}
                <HashLink to="#15" className="ftnLink" id="s15">
                  [15]
                </HashLink>
                .
              </Typography>

              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                De leur côté, Esther Fridman{" "}
                <HashLink to="#16" className="ftnLink" id="s16">
                  [16]
                </HashLink>{" "}
                et son mari Joseph Fridman (1900-1980), un ingénieur juif
                polonais et dirigeant du Linke Poale Tsion (parti socialiste
                sioniste) qui survécut à la guerre en se cachant en France{" "}
                <HashLink to="#17" className="ftnLink" id="s17">
                  [17]
                </HashLink>
                , réalisèrent au cours des années 1960 cinq traductions (en
                tandem et de manière séparée), toutes consacrées à l’œuvre de
                Mendel Mann (1916-1975), écrivain juif polonais qui avait
                combattu dans l’Armée rouge et qui s’installa à Paris en 1961
                après avoir vécu quelques années en Israël{" "}
                <HashLink to="#18" className="ftnLink" id="s18">
                  [18]
                </HashLink>
                .
              </Typography>

              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                Également d’origine polonaise, le poète Charles Dobzynski
                (1929-2014){" "}
                <HashLink to="#19" className="ftnLink" id="s19">
                  [19]
                </HashLink>{" "}
                fit dans cette période ses premiers pas en tant que traducteur
                avec des œuvres de la poétesse d’origine lituanienne Dora
                Teitelbaum (1914-1992) qui avait quitté les États-Unis pour la
                France en 1950{" "}
                <HashLink to="#20" className="ftnLink" id="s20">
                  [20]
                </HashLink>
                . Caché pendant la guerre, Dobzynski prit part aux combats pour
                la libération de Paris. Il poursuivit ses études de manière
                autodidacte et devint poète en langue française, critique de
                cinéma, rédacteur-en-chef de la revue littéraire Europe et
                traducteur de plusieurs langues, tout en maintenant son
                militantisme politique communiste. Nous reviendrons sur son
                activité de traducteur qui continue jusque dans les années 2000.
              </Typography>

              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                Enfin, on trouve dans cette période les premières traductions de
                Rachel Ertel, qui va plus tard devenir une figure centrale dans
                l’enseignement universitaire de la littérature yiddish et dans
                la formation de traducteurs. Née en 1939 dans un village de la
                région de Grodno, elle survécut à la guerre en Sibérie avec sa
                mère, la poétesse Menuha Ram{" "}
                <HashLink to="#21" className="ftnLink" id="s21">
                  [21]
                </HashLink>
                . Après quelques années à Lodz, elles arrivèrent à Paris en 1948
                avec l’écrivain Moshé Waldmann, père adoptif de Rachel Ertel, et
                s’installèrent dans le foyer d’artistes et d’intellectuels
                rescapés situé au 9 rue Guy-Patin{" "}
                <HashLink to="#22" className="ftnLink" id="s22">
                  [22]
                </HashLink>
                . Comme le couple Fridman, Ertel commença son activité de
                traductrice en travaillant sur un ouvrage de Mendel Mann{" "}
                <HashLink to="#23" className="ftnLink" id="s23">
                  [23]
                </HashLink>
                . De cette période date déjà son intérêt pour deux auteurs :
                l’écrivain soviétique Elye Chekhtman (1908-1996) dont elle
                traduisit une partie du roman épique Erev{" "}
                <HashLink to="#24" className="ftnLink" id="s24">
                  [24]
                </HashLink>
                , et H. Leivick (1888-1962), l’un des plus importants poètes
                yiddish aux États-Unis, dont elle traduisit un recueil en
                collaboration avec son père adoptif Moshé Waldman, lui aussi
                poète{" "}
                <HashLink to="#25" className="ftnLink" id="s25">
                  [25]
                </HashLink>
                .
              </Typography>

              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                En regardant l’ensemble des titres publiés dans cette période,
                on constate que, dans la plupart des cas, étaient traduits des
                auteurs encore en vie, dont quelques-uns résidaient à Paris ou y
                avaient séjourné en transit après-guerre. Ceci témoigne de deux
                choses : d’une part, de la richesse de la production littéraire
                en yiddish après la Shoah, quand cette langue était encore un
                important moyen de communication quotidien pour des millions de
                locuteurs à travers le monde ; et la présence d’un public réduit
                mais réel en France, intéressé par de nouvelles parutions
                yiddish. Une poignée d’intellectuels rescapés, tous nés en
                Europe orientale à l’exception d’Arnold Mandel, prirent
                l’initiative à titre individuel de rendre ces récents ouvrages
                au plus vite accessibles au lectorat français. Exploit d’autant
                plus considérable qu’il n’existait alors que peu d’outils
                lexicographiques yiddish-français.{" "}
              </Typography>

              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                Quelques-uns parmi ces rescapés continuèrent à traduire dans la
                décennie suivante, alors que s’accusait inexorablement le déclin
                du yiddish comme langue vernaculaire. Cela explique pourquoi le
                nombre de traductions publiées resta stable dans les années 1970
                (20 titres), avant que ne surgisse une nouvelle génération de
                traducteurs. De cette période datent, parmi d’autres ouvrages,
                la somme de Charles Dobzynski Anthologie de la poésie yiddish.
                Le Miroir d’un peuple (Paris, Gallimard, 1971), rééditée
                plusieurs fois par la suite ; la traduction par Rachel Ertel des
                nouvelles du survivant de Lodz Isaïe Spiegel (1906-1990) Les
                Flammes de la Terre (Paris, Gallimard, 1973) ; et la version
                française proposée par Esther et Joseph Fridman de La Famille
                Machber (Paris, Jean-Claude Lattès, 2 volumes, 1974-1975), saga
                de Der Nister (1884-1950), victime des persécutions contre la
                culture yiddish en Union Soviétique.
              </Typography>

              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                Dans les années 1970 on ne trouve pas d’autres intellectuels
                ayant traduit du yiddish de manière fréquente : le « profil-type
                » du traducteur est alors plutôt celui d’une personne n’ayant
                traduit qu’un seul ouvrage, à l’exception du couple de
                survivants d’origine polonaise Marc (1920-1978) et Léa Rittel
                (1922-2002), qui traduisirent deux titres : le roman épistolaire
                de Sholem-Aleykhem Menahem-Mendel le rêveur (Paris, Albin
                Michel, 1975) et le témoignage La Flamme du Shabbath (Paris,
                Plon, 1978) de Josef Erlich (1908-1982){" "}
                <HashLink to="#26" className="ftnLink" id="s26">
                  [26]
                </HashLink>
                .
              </Typography>

              <Typography
                align="justify"
                variant="h6"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                Enseignants et « disciples » : la formation de nouvelles
                générations de traducteurs
              </Typography>

              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                L’augmentation du nombre des traductions à partir des années
                1980 (25 titres) et 1990 (26 titres), dans un contexte de
                délaissement du yiddish dans les milieux juifs partout dans le
                monde, fut liée à la convergence de deux facteurs fondamentaux :
                d’une part, le surgissement, dans les années 1970, d’un nouvel
                intérêt pour la culture juive dans les générations nées
                après-guerre ; d’autre part, la présence en France de
                professeurs capables d’enseigner la langue et la littérature
                yiddish à l’université. Ceux-ci purent dispenser des cours sur
                ces matières dans les programmes d’études minoritaires créés
                dans le sillage des réformes amenées par mai 68 et la
                revendication du « droit à la différence »{" "}
                <HashLink to="#27" className="ftnLink" id="s27">
                  [27]
                </HashLink>
                .
              </Typography>

              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                L’introduction du yiddish à l’université fut d’abord rendue
                possible grâce aux efforts de Rachel Ertel. Celle-ci était
                devenue enseignante de yiddish à l’Institut des langues et
                cultures orientales (INALCO) en 1963 aux côtés d’Alexandre
                Derczansky (1924-2014), universitaire alsacien spécialiste de la
                sociologie des religions, mais le nombre d’étudiants demeurait
                des plus réduits{" "}
                <HashLink to="#28" className="ftnLink" id="s28">
                  [28]
                </HashLink>
                . Au début des années 1970, Ertel obtint un poste à l’Institut
                d’anglais de l’université Paris-VII, où elle réussit à créer des
                cours de yiddish au motif qu’il s’agissait de l’une des langues
                minoritaires parlées aux États-Unis.
              </Typography>

              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                Dans ses mémoires, Ertel indique que la plupart de ses étudiants
                à Paris-VII venaient de familles ayant perdu des proches dans la
                Shoah ; pour ces jeunes, le yiddish était « la langue des
                disparus qui flottait encore vaguement dans l’air sans être
                vraiment transmise »{" "}
                <HashLink to="#29" className="ftnLink" id="s29">
                  [29]
                </HashLink>
                . C’est dans cette université que surgit un groupe d’étudiants
                investis si assidument dans l’étude de la langue qu’ils purent
                eux-mêmes devenir plus tard des traducteurs et, dans certains
                cas, des enseignants.
              </Typography>

              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                Pour Rachel Ertel, l’enseignement du yiddish impliquait dès le
                début un ambitieux programme de traduction, pour lequel la
                formation de véritables « disciples » était nécessaire{" "}
                <HashLink to="#30" className="ftnLink" id="s30">
                  [30]
                </HashLink>
                . La traduction d’ouvrages en langues juives faisait également
                partie du programme du cercle Gaston Crémieux créé en 1967,
                entre autres par Richard Marienstras, pour qui le questionnement
                de la vie juive en diaspora était central ; Ertel a été l’un de
                ses membres fondateurs{" "}
                <HashLink to="#31" className="ftnLink" id="s31">
                  [31]
                </HashLink>
                . Néanmoins, le but d’Ertel n’était pas de faire « revivre » la
                culture est-européenne mais d’en préserver la mémoire en France.
                Et le principal moyen de faire communiquer ces deux mondes était
                la traduction{" "}
                <HashLink to="#32" className="ftnLink" id="s32">
                  [32]
                </HashLink>
                . La traduction du yiddish constituait donc à ses yeux un projet
                mémoriel : « On sait que la langue s’érode, qu’elle est en voie
                de disparition. On sait que personne ne retournera plus vers le
                texte pour le lire dans sa langue originale. On pense sans cesse
                que n’existera plus que la traduction. C’est en ce sens que
                traduire le yiddish, c’est faire en même temps un véritable
                travail de deuil ... Aucune autre langue ne se trouve dans cette
                situation. Aucune autre langue, sinon le yiddish, n’est sur le
                point de disparaître au moment même où l’on est en train de la
                traduire »
                <HashLink to="#33" className="ftnLink" id="s33">
                  [33]
                </HashLink>
                . Le fait que la langue yiddish porte les conséquences du
                génocide des Juifs d’Europe de l’Est fait en sorte que « l’acte
                de traduction se confond avec l’acte de témoignage, témoignage
                de l’Anéantissement des locuteurs et de leur langue »
                <HashLink to="#34" className="ftnLink" id="s34">
                  [34]
                </HashLink>
                .
              </Typography>

              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                Le choix de traductions d’Ertel visait à changer l’image de la
                langue yiddish, toujours associée au folklore : « Ce que j’ai
                voulu faire, c’est introduire la modernité : la fin du XIXe
                siècle et le début du XXe. C’était l’époque des avant-gardes et
                des expérimentations dans tous les champs de la littérature
                occidentale. J’ai voulu montrer que le yiddish était une sorte
                de caisse de résonance de tous ces bouleversements, qu’il
                empruntait ce miel et le transformait en sa propre chair, par sa
                propre langue et par sa propre vision, ses propres images, ses
                propres métaphores. ... Tout comme les Juifs faisaient partie de
                l’histoire mondiale, la littérature yiddish faisait partie de
                l’histoire de la littérature universelle »{" "}
                <HashLink to="#35" className="ftnLink" id="s35">
                  [35]
                </HashLink>
                .
              </Typography>

              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                Ce programme pédagogique, littéraire et mémoriel d’Ertel se
                concrétisa, surtout à partir de la fin des années 1980, dans un
                ensemble d’ouvrages appartenant à la collection « Domaine
                yiddish », même s’il ne s’agit pas d’une collection dans le sens
                classique du terme, les titres n’étant pas tous publiés par une
                seule maison d’édition. Ertel l’a d’ailleurs décrite comme une «
                collection diasporique » en raison du changement périodique
                d’éditeurs, causé par le manque de rentabilité inexorablement
                mis en avant par les uns et les autres{" "}
                <HashLink to="#36" className="ftnLink" id="s36">
                  [36]
                </HashLink>
                .
              </Typography>

              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                Les traducteurs « apprentis » de Rachel Ertel eurent aussi la
                possibilité d’étudier avec Mordkhe Litvine (1903-1993), arrivé
                de Kovno en 1939 et revenu en France après avoir été emprisonné
                en Allemagne. Après avoir travaillé dans la presse yiddish
                communiste pendant quelques années, il devint guide touristique.
                Ayant pris sa retraite dans les années 1970, il se consacra à la
                traduction de la poésie française en yiddish et enseigna la
                traduction{" "}
                <HashLink to="#37" className="ftnLink" id="s37">
                  [37]
                </HashLink>
                .
              </Typography>

              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                L’arrivée en 1979 du pédagogue, lexicographe et spécialiste de
                la littérature yiddish Yitskhok Niborski, né à Buenos Aires en
                1947, transforma durablement le paysage yiddish parisien{" "}
                <HashLink to="#38" className="ftnLink" id="s38">
                  [38]
                </HashLink>
                . Lui-même étudiant à la fois de Litvine et de Rachel Ertel, il
                impulsa en 1981 la création de l’Association pour l’Étude et la
                Diffusion de la Culture Yiddish (AEDCY) présidée par Louisette
                Kahane-Dajezer (1931-2009) pendant 20 ans{" "}
                <HashLink to="#39" className="ftnLink" id="s39">
                  [39]
                </HashLink>
                . Niborski devint maître de conférences en yiddish à l’INALCO et
                bibliothécaire en chef de la Bibliothèque Medem{" "}
                <HashLink to="#40" className="ftnLink" id="s40">
                  [40]
                </HashLink>
                . Quoiqu’il ait toujours eu une vision pessimiste quant à
                l’utilité de la traduction pour la sauvegarde du patrimoine
                yiddish – il a toujours préconisé l’apprentissage rigoureux et
                l’usage quotidien de la langue chez ses étudiants –, il n’en a
                pas moins joué un rôle des plus importants auprès des
                traducteurs en France. Niborski s’est ainsi toujours montré prêt
                à fournir des conseils sur le choix des textes à traduire en
                français, à assurer une supervision minutieuse des versions
                proposées et des paratextes scientifiques les accompagnant{" "}
                <HashLink to="#41" className="ftnLink" id="s41">
                  [41]
                </HashLink>
                . Par ailleurs, en publiant des dictionnaires bilingues, il a
                doté d’outils indispensables quiconque se lance dans une
                traduction.
              </Typography>

              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                Parmi les œuvres modernes yiddish traduites par cette nouvelle
                génération figurent les recueils collectifs emblématiques,
                Khaliastra/La Bande (Paris, Lachenal & Ritter, 1989) et Une
                maisonnette au bord de la Vistule : anthologie de la prose
                yiddish (Paris, Albin Michel, 1989), tous deux compilés par
                Rachel Ertel. C’est aussi à ce moment que plusieurs traducteurs
                et traductrices amenés à devenir prolifiques virent sortir leurs
                premiers ouvrages de manière indépendante ou collaborative :
                Delphine Bechtel, Carole Ksiazenicer, Jacques Mandelbaum, Jean
                Baumgarten, Nadia Déhan Rotschild, Bernard Vaisbrot, Gilles
                Rozier et Ariel Sion{" "}
                <HashLink to="#42" className="ftnLink" id="s42">
                  [42].
                </HashLink>{" "}
              </Typography>

              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                Leurs choix d’écrivains correspondaient souvent aux visées de
                Rachel Ertel : des auteurs modernes tels Lamed Shapiro
                (1878-1948), Israël Joshua Singer (1893-1944), Moïshe Kulbak
                (1896-1937), Esther Kreitman (1891-1954) ou Aaron Zeitlin
                (1898-1973). Mais de cette période datent aussi la traduction
                par Jean Baumgarten de l’ouvrage populaire du XVIe siècle Le
                Commentaire sur la Torah, Tseenah Ureenah (Paris, Verdier, 1987)
                de Jacob ben Isaac Ashkenazi de Janow (1550-1625), ainsi que
                celle de trois ouvrages de l’auteur classique Sholem-Aleykhem
                traduits par d’autres étudiants d’Ertel, Litvine et Niborski{" "}
                <HashLink to="#43" className="ftnLink" id="s43">
                  [43]
                </HashLink>
                .
              </Typography>

              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                Pourtant, les traductions des années 1980 et 1990 ne sont pas
                uniquement à mettre au compte des nouvelles générations. Rachel
                Ertel et Charles Dobzynski continuèrent leur activité. D’autres
                survivants de la Shoah se mirent à la traduction du yiddish,
                tels Aby Wieviorka (1921-1991){" "}
                <HashLink to="#44" className="ftnLink" id="s44">
                  [44]
                </HashLink>
                , réfugié en Suisse pendant la guerre et qui travailla souvent
                en tandem avec l’écrivain de langue française né après-guerre,
                Henri Raczymow. Ils traduisirent, parmi d’autres, le deuxième
                volume de la trilogie Trois villes de Sholem Asch, Varsovie
                (Paris, Belfond, 1987) et le roman Les Contrebandiers (Paris,
                Seuil, 1989) de l’écrivain juif polonais assassiné à Auschwitz
                Oser Warszawski (1898-1944).{" "}
              </Typography>

              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                D’autre part, Maurice Pfeffer (1926-2010){" "}
                <HashLink to="#45" className="ftnLink" id="s45">
                  [45]
                </HashLink>
                , qui avait survécu à la guerre en France et dont le yiddish
                était la langue maternelle, publia ses premières traductions
                d’ouvrages testimoniaux : C’était ainsi. 1939-1943 la vie dans
                le Ghetto de Varsovie (Austral, 1995) de Jonas Turkow
                (1898-1988) et Maïdanek (Paris, Honoré Champion, 1998) de
                Mordkhe Strigler (1918-1998). L’avocat belge Nathan Weinstock,
                né à Anvers en 1939 et réfugié en Angleterre pendant la guerre,
                retourna dans sa ville natale avec sa famille en 1946, où il
                fréquenta une école religieuse qui dispensait son enseignement
                en yiddish{" "}
                <HashLink to="#46" className="ftnLink" id="s46">
                  [46]
                </HashLink>
                . En collaboration avec son épouse, la psychothérapeute
                Micheline Weinstock, il publia en France le journal d’Hillel
                Seidman, « Du fond de l’abîme», journal du ghetto de Varsovie
                (Paris, Plon, 1998).
              </Typography>

              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                Finalement, Batia Baum{" "}
                <HashLink to="#47" className="ftnLink" id="s47">
                  [47]
                </HashLink>
                , née à Paris sous l’Occupation et cachée à Lyon avec sa mère,
                est la plus jeune parmi les survivants devenus traducteurs. Ses
                premiers travaux sont parus dans les années 1990. Le parcours de
                Batia Baum est particulier car, si le yiddish était bien sa
                langue maternelle, les circonstances de la persécution nazie
                l’obligèrent à arrêter de la parler. Elle dut donc réapprendre
                la langue dans les années 1980 et devint enseignante et
                traductrice de manière plutôt autodidacte. Elle produira un
                vaste corpus d’ouvrages traduits dans les décennies suivantes et
                deviendra l’une des traductrices du yiddish les plus renommées
                en France, comme Rachel Ertel.
              </Typography>

              <Typography
                align="justify"
                variant="h6"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                La traduction du yiddish au XXIe siècle
              </Typography>

              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                Les premières décennies du XXIe siècle ont vu se poursuivre le
                travail réalisé par un groupe limité de traducteurs déjà actifs
                auparavant. Notre base de données montre que parmi les 73 titres
                publiés entre 2000 et 2020, environ un tiers furent traduits par
                Rachel Ertel et Batia Baum.
              </Typography>

              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                Les traductions de cette dernière ne se limitent pas à un auteur
                ou à une période de la littérature. On lui doit la version
                française de grands ouvrages classiques de Mendele
                Moykher-Sforim La Haridelle (Paris, Bibliothèque Medem, 2008) et
                L’Anneau magique (Paris, Bibliothèque Medem, 2019), aussi bien
                que celle de la pièce expressionniste de Peretz La Nuit sur le
                vieux marché (Paris, Bibliothèque Medem, 2016). Parmi le grand
                nombre de textes plus contemporains qu’elle a traduits, on
                trouve des ouvrages centraux du corpus de ce qu’on appelle
                désormais les « écritures de la destruction » : Le Chant du
                peuple juif assassiné (Paris, Bibliothèque Medem, 2005) de
                Yitskhok Katzenelson (1886-1994) et Écrits I et II, Témoignage
                d’un Sonderkommando d’Auschwitz (Paris, Kimé, 2013) de Zalmen
                Gradowski (1910-1944), ainsi que le volume en prose Aquarium
                vert (Paris, Bibliothèque Medem, 2013) du survivant du ghetto de
                Vilna Avrom Sutzkever (1903-2010). En récompense de la qualité
                de son travail, Batia Baum reçut en 2017 le Grand Prix de la
                Traduction de la Société des Gens de Lettres.
              </Typography>

              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                Comme l’indiquent les exemples ci-dessus, la plupart des
                traductions de Batia Baum furent publiées par la Bibliothèque
                Medem{" "}
                <HashLink to="#48" className="ftnLink" id="s48">
                  [48]
                </HashLink>
                , maison d’édition entièrement consacrée à la langue et à la
                littérature yiddish, adossée à la Maison de la culture yiddish
                qui a été créée en 2002 par la fusion de la Bibliothèque Medem
                et de l’Association pour l’étude et la diffusion de la culture
                yiddish{" "}
                <HashLink to="#49" className="ftnLink" id="s49">
                  [49]
                </HashLink>
                . Chaque publication de la Bibliothèque Medem, société à but non
                lucratif, est permise par une collecte de fonds ; ses livres ne
                sont pas vendus dans les librairies et ne peuvent être achetés
                directement qu’à la Maison de la culture yiddish, sur place ou
                en ligne. Ses capacités de publication étant limitées et le
                milieu éditorial commercial ne manifestant qu’un faible intérêt
                pour les œuvres écrites en yiddish, il n’est pas étonnant que de
                nombreuses traductions faites par Batia Baum restent encore
                inédites{" "}
                <HashLink to="#450" className="ftnLink" id="s50">
                  [50]
                </HashLink>
                .
              </Typography>

              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                Parmi les récentes traductions les plus emblématiques de Rachel
                Ertel se trouvent le roman À pas aveugles de par le monde
                (Paris, Denoël, 2012) du survivant de Pologne Leïb Rochman
                (1918-1978), le vaste roman épique Erev, à la veille de… (Paris,
                Buchet-Chastel 2018) d’Elie Chekhtman (1908-1996) et le
                témoignage de H. Leivick (1888-1962) Dans les bagnes du tsar
                (Paris, Éditions de l’Antilope, 2019). Rachel Ertel vient de
                recevoir en 2020 le Prix de l’Académie française pour l’ensemble
                de son œuvre longue de soixante ans. omme celui de Rachel Ertel
                et de Batia Baum, le corpus de traductions de Nathan Weinstock a
                augmenté de manière significative dans la période la plus
                récente ; parmi ses travaux publiés en France, sans doute le
                plus important est Oneg Shabbat. Journal du ghetto de Varsovie
                (Paris, Mémorial de la Shoah / Calmann-Lévy, 2017) d’Emanuel
                Ringelblum, traduit en collaboration avec l’historienne et
                réalisatrice Isabelle Rozenbaumas.
              </Typography>

              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                Deux nouveaux traducteurs appartenant néanmoins à la génération
                née avant-guerre se sont néanmoins signalés. Monique Charbonnel{" "}
                <HashLink to="#51" className="ftnLink" id="s51">
                  [51]
                </HashLink>
                , née en France en 1937 dans une famille juive polonaise
                yiddishophone, fut cachée en Normandie pendant la guerre. Bien
                des années plus tard, elle participa à l’atelier de traduction
                mené par Batia Baum à la Maison de la culture yiddish. Entre les
                années 2010 et 2017 elle traduisit cinq œuvres d’Israël Joshua
                Singer. Jean Spector{" "}
                <HashLink to="#52" className="ftnLink" id="s52">
                  [52]
                </HashLink>
                , aussi né en France en 1937 dans une famille immigrée de
                Pologne et réfugié en Suisse pendant la guerre, a étudié avec
                Rachel Ertel et Yitskhok Niborski. En 2015, il a publié la
                traduction du roman Juifs ordinaires (Paris, Classiques Garnier)
                de l’écrivain Yehoshue Perle assassiné dans le ghetto de
                Varsovie, et en 2020 le roman Étoiles vagabondes (Paris, Le
                Tripode Attila) de Sholem-Aleykhem.
              </Typography>

              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                Parmi les membres de la génération d’après-guerre les plus
                actifs en tant que traducteurs figurent Bernard Vaisbrot, qui a
                traduit plusieurs témoignages de la Shoah ; Nadia Déhan
                Rotschild, qui se consacre principalement à l’œuvre de Sholem
                Aleykhem ; et Gilles Rozier{" "}
                <HashLink to="#53" className="ftnLink" id="s53">
                  [53]
                </HashLink>
                , qui a traduit en français deux livres d’Esther Kreitman et
                deux recueils de témoignages. Certains dans cette cohorte d’âge,
                comme Delphine Bechtel, Jacques Mandelbaum et Carole
                Ksiacenizer, ont suspendu leurs efforts dans ce domaine pour
                différentes raisons. Seuls nouveaux noms, ceux de deux
                traductrices nées dans les années 1950 : Isabelle Rozenbaumas,
                évoquée plus haut, à qui l’on doit, entre autres, la traduction
                du Journal 1943-1944 de Leïb Rochman (Paris, Mémorial de la
                Shoah / Calmann-Lévy, 2017), et Evelyne Grumberg, traductrice de
                la pièce de théâtre Weizmann II d’Aaron Zeitlin (Paris,
                Bibliothèque Medem, 2020).{" "}
              </Typography>

              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                Notre base de données permet d’observer l’émergence d’un
                renouveau générationnel à travers le travail de la spécialiste
                en littératures comparées, Fleur Kuhn-Kennedy. Sa version de la
                nouvelle de Hersh Dovid Nomberg « À qui la faute ? », sortie en
                2020, est d’ailleurs la première traduction du yiddish en
                français publiée par la nouvelle maison d’édition Farlag,
                dirigée par Daniel Kennedy et Fleur Kuhn-Kennedy{" "}
                <HashLink to="#54" className="ftnLink" id="s54">
                  [54]
                </HashLink>
                .{" "}
              </Typography>

              <Typography
                align="justify"
                variant="h6"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                Conclusion
              </Typography>

              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                Le corpus d’ouvrages traduits du yiddish en France au cours de
                plus d’un siècle fut principalement le résultat des efforts de
                deux groupes distincts : des survivants de la Shoah, et des
                personnes nées dans les deux générations de l’après-guerre et
                qui apprirent la langue surtout dans les années 1970 et 1980. Si
                dans le premier groupe la traduction du yiddish est surtout liée
                à la mémoire de la Shoah, la démarche des générations les plus
                jeunes est plus marquée par un intérêt spécifiquement
                littéraire.
              </Typography>

              <Typography
                align="justify"
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                Même si la quantité d’ouvrages traduits du yiddish en français
                reste limitée, la situation a changé de manière significative
                depuis le moment où Henri Minczeles déplorait l’absence presque
                totale de traductions. En effet, il existe désormais un corpus
                d’œuvres de la littérature yiddish disponible en français qui
                est bien plus vaste que la traduction du yiddish en maintes
                autres langues, et Paris reste toujours un centre de traduction
                du yiddish aujourd’hui. Le dynamisme de l’enseignement du
                yiddish au long des dernières décennies, et l’existence d’un
                certain nombre d’institutions qui le soutiennent, laissent
                espérer l’avènement de nouvelles générations de traducteurs et
                traductrices.{" "}
              </Typography>

              <Typography
                align="justify"
                variant="subtitle2"
                color="textSecondary"
                paragraph={true}
                className={classes.paragraph}
              >
                * Chercheuse post-doctorante de la Fondation pour la mémoire de
                la Shoah (FMS) et correspondante au GRIHL à l’École des Hautes
                Études en Sciences Sociales (EHESS).
              </Typography>

              <Typography align="justify" variant="subtitle1" id="1">
                <HashLink to="#s1" className="ftnLink">
                  [1]{" "}
                </HashLink>{" "}
                Hanri Monmartr, « Di yidishe literatur in der frants.
                iberzetsung », Undzer shtime, 29 novembre 1948, p. 4.
              </Typography>

              <Typography align="justify" variant="subtitle1" id="2">
                <HashLink to="#s2" className="ftnLink">
                  [2]{" "}
                </HashLink>{" "}
                Hazan, Kathy, « Henri Minczeles », Revue d’Histoire de la Shoah
                207 (2), 2017, p. 448- 449. Minczeles survécut à la Shoah en
                France sous une fausse identité et participa à la Libération de
                Paris avec les jeunesses socialistes. Son père fut déporté à
                Auschwitz. Tout en étant actif au Cercle amical puis au Centre
                Medem-Arbeter Ring, il a publié plusieurs livres d’histoire
                juive et fut membre du comité de rédaction de la Revue
                d’Histoire de la Shoah.
              </Typography>

              <Typography align="justify" variant="subtitle1" id="3">
                <HashLink to="#s3" className="ftnLink">
                  [3]{" "}
                </HashLink>{" "}
                Monmartr, « Di yidishe literatur… », art. cité.
              </Typography>

              <Typography align="justify" variant="subtitle1" id="4">
                <HashLink to="#s4" className="ftnLink">
                  [4]{" "}
                </HashLink>
                Cet article présente les premiers résultats d’une recherche en
                cours sur l’histoire de la traduction du yiddish en France. Le
                point de départ pour notre étude a été la liste générale
                élaborée par la Bibliothèque Medem :
                http://www.yiddishweb.com/traductions-du-yiddish-en-francais/
                (consulté le 12 janvier 2020). N’ont pas été inclues dans notre
                base de données : les traductions indirectes, les traductions
                pour des particuliers, les traductions sorties dans des revues
                littéraires, les traductions inédites, les traductions en
                français publiées dans d’autres pays, et les rééditions, la
                seule exception étant les deux volumes collectifs Royaumes
                juifs. Trésors de la littérature yiddish (Paris, Robert Laffont,
                2008 et 2009), édités par Rachel Ertel, puisqu’il s’agit de
                rééditions augmentées et enrichies de plusieurs paratextes ;
                deux traductions inédites y furent ajoutées. Nous voudrions
                remercier Simon Perego, Alan Astro et Constance Pâris de
                Bollardière pour leurs conseils et relectures ; Yitskhok
                Niborski pour les informations indispensables apportées au début
                de cette recherche ; enfin, les traducteurs et traductrices qui
                ont partagé leurs témoignages à travers des entretiens
                personnels avec l’auteure, ainsi que dans le cadre des
                séminaires ANR-LJtrad animés par Arnaud Bikard : Nadia Déhan
                Rotschild, Jean Spector, Bernard Vaisbrot, Jacques Mandelbaum,
                Evelyne Grumberg, Carole Ksiacenizer, Batia Baum et Gilles
                Rozier.
              </Typography>

              <Typography align="justify" variant="subtitle1" id="5">
                <HashLink to="#s5" className="ftnLink">
                  [5]{" "}
                </HashLink>
                Gilles Rozier, « Yiddish, le monde », dans Dictionnaire du
                judaïsme français depuis 1944, dir. par Jean Leselbaum et
                Antoine Spire, Paris/Lormont, Armand Colin/Le Bord de l’eau,
                2013, p. 953.
              </Typography>

              <Typography align="justify" variant="subtitle1" id="6">
                <HashLink to="#s6" className="ftnLink">
                  [6]{" "}
                </HashLink>
                Né à Botosani en Roumanie, Blumenfeld s’installa à Paris en
                1909, où il poursuivit des études à la Sorbonne. Il traduisit
                des pièces de théâtre du français au yiddish, lesquelles furent
                représentées à Paris. Voir : Leksikon fun der nayer yidisher
                literatur, dir. par Shmuel Niger, Yankev Shatzky et alii., vol.
                1, New York, Congress for Jewish Culture, 1956, coll. 325. Avant
                la Deuxième Guerre mondiale, Blumenfeld publia une anthologie de
                récits traduits du yiddish en français (Anthologie des conteurs
                yiddish, Paris, Rieder, 1922) ainsi qu’une traduction de Sholem
                Asch (Le Soldat juif, Paris, La Renaissance du Livre, 1924).
              </Typography>

              <Typography align="justify" variant="subtitle1" id="7">
                <HashLink to="#s7" className="ftnLink">
                  [7]{" "}
                </HashLink>
                Né à Genève, Edmund Fleg était écrivain et philosophe. Il
                combattit dans la Première Guerre mondiale. Après la Deuxième
                Guerre mondiale, il devint une figure centrale des Colloques des
                intellectuels juifs de langue française. Voir : Sandrine,
                Szwarc, Les intellectuels juifs de 1945 à nos jours, Paris, Le
                bord de l’eau, 2013, p. 168-176. Edmond Fleg fut l’auteur de la
                première traduction en français de Sholem-Aleykhem (L’Histoire
                de Tévié, Paris, Rieder, 1925).
              </Typography>

              <Typography align="justify" variant="subtitle1" id="8">
                <HashLink to="#s8" className="ftnLink">
                  [8]{" "}
                </HashLink>
                Voir l’introduction et les contributions au dossier « Les
                migrations juives d’Europe centrale et orientale en France au
                lendemain de la Shoah », Archives juives. Revue d’histoire des
                Juifs de France 54 (1), à paraître (2021).
              </Typography>

              <Typography align="justify" variant="subtitle1" id="9">
                <HashLink to="#s9" className="ftnLink">
                  [9]{" "}
                </HashLink>
                Pierre Aubery, « Mandel, Arnold », dans Encyclopaedia Judaica,
                deuxième édition, dir. par Fred Skolnik et Michael Berenbaum,
                Detroit, Thomson Gale, 2007, vol. 13, p. 458.
              </Typography>

              <Typography align="justify" variant="subtitle1" id="10">
                <HashLink to="#s10" className="ftnLink">
                  [10]{" "}
                </HashLink>
                Sur Dworzetski, voir : Mark L. Smith, The Yiddish Historians and
                the Struggle for a Jewish History of the Holocaust, Detroit,
                Wayne State University Press, 2019. Le titre original était
                Yerusholaim delite in kamf un umkum [La Jérusalem de Lituanie
                dans les combats et la destruction] (Paris, Union populaire
                juive en France, 1948). La traduction de Mandel fut rééditée
                sous le titre La Victoire du Ghetto (Paris, Éditions
                France-Empire, 1962) avec l’inclusion du témoignage qu’apporta
                l’auteur au procès d’Adolf Eichmann en 1961.
              </Typography>

              <Typography align="justify" variant="subtitle1" id="11">
                <HashLink to="#s11" className="ftnLink">
                  [11]{" "}
                </HashLink>
                Voir Judith Lindenberg (dir.), Premiers savoirs de la Shoah,
                Paris, CNRS Éditions, 2017. De 1950 date aussi la traduction du
                témoignage du poète survivant Avrom Sutzkever (1903-2010) :
                Ghetto de Vilna, traduit par Charles Brenazin, Paris, Cooped.,
                1950. Aucune information biographique n’a pour le moment été
                trouvée sur ce traducteur, dont on ne connaît pas d’autres
                traductions.{" "}
              </Typography>

              <Typography align="justify" variant="subtitle1" id="12">
                <HashLink to="#s12" className="ftnLink">
                  [12]{" "}
                </HashLink>
                Juliette Pary était le nom de plume de Juliette Gourfinkel. La
                sœur de celle-ci, l’écrivaine russe Nina Gourfinkel (1898-1984),
                a aussi traduit du yiddish un seul ouvrage, la célèbre pièce
                d’An-ski Le Dibbouk, en collaboration avec Arié Mambush (Paris,
                L’Arche, 1957). Voir : Ruth Schatzman, « Nina Gourfïnkel
                (1898-1984) », Revue des études slaves, tome 63, fascicule 3,
                1991, p. 705-710.
              </Typography>

              <Typography align="justify" variant="subtitle1" id="13">
                <HashLink to="#s13" className="ftnLink">
                  [13]{" "}
                </HashLink>
                Katy Hazan, Les Orphelins de la Shoah, les maisons de l’espoir
                (1944-1960), Paris, Les Belles Lettres, 2000, p. 324-327.
              </Typography>

              <Typography align="justify" variant="subtitle1" id="14">
                <HashLink to="#s14" className="ftnLink">
                  [14]{" "}
                </HashLink>
                Sholem-Aleykhem, Le Tailleur ensorcelé et autres contes, Paris,
                Albin Michel, 1960. Gottfarstein arriva à Paris à la fin des
                années 1920. Réfugié en Suisse pendant l’Occupation avec son
                épouse et leur enfant, il rentra en France après la guerre et se
                consacra à la culture juive. Voir : Judith Friedlander, Vilna on
                the Seine. Jewish Intellectuals in France since 1968, New Haven
                and London, Yale University Press, 1990, p. 157-161.
              </Typography>

              <Typography align="justify" variant="subtitle1" id="15">
                <HashLink to="#s15" className="ftnLink">
                  [15]{" "}
                </HashLink>
                Charles Goldstein, Sept ans dans un bunker, Paris, Gallimard,
                1967.
              </Typography>

              <Typography align="justify" variant="subtitle1" id="16">
                <HashLink to="#s16" className="ftnLink">
                  [16]{" "}
                </HashLink>
                Aucune information biographique n’a été trouvée la concernant.
              </Typography>

              <Typography align="justify" variant="subtitle1" id="17">
                <HashLink to="#s17" className="ftnLink">
                  [17]{" "}
                </HashLink>
                Khayim Leyb Fuks, « Fridman, Yoysef », dans Leksikon fun der
                nayer yidisher literatur, op. cit., vol. 7, coll. 477-478. Après
                la guerre, Fridman fut le co-fondateur et secrétaire de
                l’Association des écrivains et journalistes juifs (1946-1950),
                et fit paraître le journal mensuel Yidish (1948-1950). Il
                traduisit du français au yiddish un roman d’Émile Zola.
              </Typography>

              <Typography align="justify" variant="subtitle1" id="18">
                <HashLink to="#s18" className="ftnLink">
                  [18]{" "}
                </HashLink>
                M. Y. [Mortkhe Yofe], « Man, Mendl », Leksikon fun der nayer
                yidisher literatur, op. cit., vol. 4, coll. 431-434.
              </Typography>

              <Typography align="justify" variant="subtitle1" id="19">
                <HashLink to="#s19" className="ftnLink">
                  [19]{" "}
                </HashLink>
                Jean-Baptiste Para, « Les horizons de la mémoire », préface de
                Charles Dobzynski, Je est un Juif, roman, Paris, Gallimard,
                2017, p. 7-15.
              </Typography>

              <Typography align="justify" variant="subtitle1" id="20">
                <HashLink to="#s20" className="ftnLink">
                  [20]{" "}
                </HashLink>
                Berl Kagan, Leksikon fun yidish-shraybers, New York (sans maison
                d’édition), 1986, coll. 281-282.
              </Typography>

              <Typography align="justify" variant="subtitle1" id="21">
                <HashLink to="#s21" className="ftnLink">
                  [21]{" "}
                </HashLink>
                Sur la vie et l’œuvre de Rachel Ertel, voir de celle-ci Mémoire
                du yiddish : Transmettre une langue assassinée. Entretiens avec
                Stéphane Bou, Paris, Albin Michel, 2019.
              </Typography>

              <Typography align="justify" variant="subtitle1" id="22">
                <HashLink to="#s22" className="ftnLink">
                  [22]{" "}
                </HashLink>
                Rachel Ertel a évoqué ses premières années en France dans « Les
                fantômes du 9 rue Guy Patin. (En souvenirs) », Les Temps
                Modernes 686 (5), 2015, p. 21-54 ; voir aussi Mémoire du
                yiddish, op. cit., p. 63-89. Sur la période yiddish de
                l’immeuble du 9 rue Guy-Patin, voir : Malena Chinski et
                Constance Pâris de Bollardière, « Un foyer artistique et
                intellectuel yiddish pour migrants rescapés, le 9 rue Guy-Patin
                (1947-1950) », Archives juives. Revue d’histoire des Juifs de
                France 54 (1), à paraître (2021).
              </Typography>

              <Typography align="justify" variant="subtitle1" id="23">
                <HashLink to="#s23" className="ftnLink">
                  [23]{" "}
                </HashLink>
                Mendel Mann, Sur la Vistule, Paris, Calmann-Lévy, 1962.
              </Typography>

              <Typography align="justify" variant="subtitle1" id="24">
                <HashLink to="#s24" className="ftnLink">
                  [24]{" "}
                </HashLink>
                Elie Chekhtman, À la Veille de…, Paris, Editeurs Français
                Réunis, 1964 ; Ertel finira la traduction de tout le roman
                plusieurs décennies plus tard (voir la troisième section).
              </Typography>

              <Typography align="justify" variant="subtitle1" id="25">
                <HashLink to="#s25" className="ftnLink">
                  [25]{" "}
                </HashLink>
                H. Leivick, H. Leivick, poète yiddish, Paris, Gopa, 1967. Voir
                la traduction la plus récente de H. Leivick réalisée par Rachel
                Ertel dans la troisième section.
              </Typography>

              <Typography align="justify" variant="subtitle1" id="26">
                <HashLink to="#s26" className="ftnLink">
                  [26]{" "}
                </HashLink>
                Ces traductions furent commandées par les maisons d’éditions,
                comme nous l’a dit leur fille Régine Waintrater, que nous
                remercions.
              </Typography>

              <Typography align="justify" variant="subtitle1" id="27">
                <HashLink to="#s27" className="ftnLink">
                  [27]{" "}
                </HashLink>
                Gilles Rozier, « Yiddish, le monde», art. cité, p. 956-957 ;
                Judith Friedlander, op. cit., p. 61. Comme le rappelle Ertel, la
                visibilité croissante de la Shoah dans l’espace public français
                dans les années 1970 contribua aussi à faire grandir l’intérêt
                pour la langue yiddish (Mémoire du yiddish, op. cit., p. 169).
              </Typography>

              <Typography align="justify" variant="subtitle1" id="28">
                <HashLink to="#s28" className="ftnLink">
                  [28]{" "}
                </HashLink>
                Rachel Ertel, Mémoire du yiddish, op. cit., p. 160. Derczansky
                devint plus tard chercheur au Centre national de la recherche
                scientifique (CNRS). Sur sa trajectoire, voir Judith
                Friedlander, op. cit., p. 192-194.
              </Typography>

              <Typography align="justify" variant="subtitle1" id="29">
                <HashLink to="#s29" className="ftnLink">
                  [29]{" "}
                </HashLink>
                Rachel Ertel, Mémoire du yiddish, op. cit., p. 163-164.
              </Typography>

              <Typography align="justify" variant="subtitle1" id="30">
                <HashLink to="#s30" className="ftnLink">
                  [30]{" "}
                </HashLink>
                Jean Baumgarten, « Yiddish in France : A Conversation with
                Rachel Ertel », traduit par Alan Astro, Shofar 14 (4), 1996.
              </Typography>

              <Typography align="justify" variant="subtitle1" id="31">
                <HashLink to="#s31" className="ftnLink">
                  [31]{" "}
                </HashLink>
                Judith Friedlander, op. cit., p. 40, 51.
              </Typography>

              <Typography align="justify" variant="subtitle1" id="32">
                <HashLink to="#s32" className="ftnLink">
                  [32]{" "}
                </HashLink>
                Judith Friedlander, op. cit., p. 62.
              </Typography>

              <Typography align="justify" variant="subtitle1" id="33">
                <HashLink to="#s33" className="ftnLink">
                  [33]{" "}
                </HashLink>
                Ertel, Mémoire du yiddish, op. cit., p. 175 (souligné dans
                l’original).
              </Typography>

              <Typography align="justify" variant="subtitle1" id="34">
                <HashLink to="#s34" className="ftnLink">
                  [34]{" "}
                </HashLink>
                Rachel Ertel, « Une littérature sans frontières », dans Royaumes
                Juifs. Trésors de la littérature yiddish, Vol. I, édité par
                Rachel Ertel, Robert Laffont Bouquins, Paris, 2008, p. CI.
              </Typography>

              <Typography align="justify" variant="subtitle1" id="35">
                <HashLink to="#s35" className="ftnLink">
                  [35]{" "}
                </HashLink>
                Rachel Ertel, Mémoire du yiddish, op. cit., p. 182-183.
              </Typography>

              <Typography align="justify" variant="subtitle1" id="36">
                <HashLink to="#s36" className="ftnLink">
                  [36]{" "}
                </HashLink>
                Ibid., p. 182. En accord avec ce principe d’universalité, aucune
                maison d’édition de cette collection n’est liée à des
                institutions juives.
              </Typography>

              <Typography align="justify" variant="subtitle1" id="37">
                <HashLink to="#s37" className="ftnLink">
                  [37]{" "}
                </HashLink>
                Sur Litvine, voir Judith Friedlander, op. cit., p. 62, 65-79.
              </Typography>

              <Typography align="justify" variant="subtitle1" id="38">
                <HashLink to="#s38" className="ftnLink">
                  [38]{" "}
                </HashLink>
                https://www.yiddishweb.com/animateurs/yitskhok-niborski/
                (consulté le 12 janvier 2021).
              </Typography>

              <Typography align="justify" variant="subtitle1" id="39">
                <HashLink to="#s39" className="ftnLink">
                  [39]{" "}
                </HashLink>
                Judith Friedlander, op. cit., p. 52. Pour le détails des
                activités de l’AEDCY, voir Louisette Kahane,. « Le yiddish en
                France de l’après-guerre à nos jours », La Rassegna Mensile Di
                Israel, 3e série, 62 (1/2), 1996, p. 447.
              </Typography>

              <Typography align="justify" variant="subtitle1" id="40">
                <HashLink to="#s40" className="ftnLink">
                  [40]{" "}
                </HashLink>
                La Bibliothèque Medem fut fondée en 1929 par Kive Vaisbrot au
                sein de l’organisation bundiste Arbeter-ring. Elle quitta les
                locaux de cette organisation en 2001 et devint adossée à la
                Maison de la Culture Yiddish. Voir : Judith Friedlander, op.
                cit., p. 189 ; Constance Pâris de Bollardière, « Bund », dans
                Dictionnaire du Judaïsme français depuis 1944, op. cit., p. 128
                ; Alan Astro, Autour du yiddish de Paris à Buenos Aires, Paris,
                Classiques Gallimard, à paraître.
              </Typography>

              <Typography align="justify" variant="subtitle1" id="41">
                <HashLink to="#s41" className="ftnLink">
                  [41]{" "}
                </HashLink>
                Entretien de l’auteure avec Yitskhok Niborski, 17 juin 2019.{" "}
              </Typography>

              <Typography align="justify" variant="subtitle1" id="42">
                <HashLink to="#s42" className="ftnLink">
                  [42]{" "}
                </HashLink>
                Toutes les personnes nommées sont nées entre 1946 et 1963,
                Gilles Rozier étant le plus jeune du groupe. Les motivations et
                visions personnelles autour de la traduction de la part de la
                nouvelle génération reste à explorer.
              </Typography>

              <Typography align="justify" variant="subtitle1" id="43">
                <HashLink to="#s42" className="ftnLink">
                  [43]{" "}
                </HashLink>
                Sholem-Aleykhem, Contes ferroviaires, traduit par Nadia Déhan
                Rotschild, Louisette Kahane-Dajczer, Jacques Mandelbaum,
                Mathilde Mann et Viviane Siman, Paris, Liana Levi/Scribe, 1991 ;
                La Peste Soit de l’Amérique, traduit par Nadia Déhan Rotschild,
                Paris, Liana Levi/Scribe, 1992 ; Gens de Kasrilevkè, traduit par
                Jacques Mandelbaum, Paris, Julliard, 1993.
              </Typography>

              <Typography align="justify" variant="subtitle1" id="44">
                <HashLink to="#s44" className="ftnLink">
                  [44]{" "}
                </HashLink>
                Annette Wieviorka, L’heure d’exactitude. Histoire, mémoire,
                témoignage, Paris, Albin Michel, 2011, p. 19.
              </Typography>

              <Typography align="justify" variant="subtitle1" id="45">
                <HashLink to="#s45" className="ftnLink">
                  [45]{" "}
                </HashLink>
                Georges Bensoussan, « Hommage à Maurice Pfeffer (1926-2010) »,
                Revue d’Histoire de la Shoah 193 (2), 2010, p. 177-178.
              </Typography>

              <Typography align="justify" variant="subtitle1" id="46">
                <HashLink to="#s46" className="ftnLink">
                  [46]{" "}
                </HashLink>
                Nous remercions Meïr Waintrater pour ces détails biographiques.
              </Typography>

              <Typography align="justify" variant="subtitle1" id="47">
                <HashLink to="#s47" className="ftnLink">
                  [47]{" "}
                </HashLink>
                Entretien de Max Kohn avec Batia Baum dans SBS Radio – Yiddish,
                Melbourne, Australie, 11 mars 2010 (en yiddish). Transcrit et
                traduit au français par Erez Levy :
                https://www.maxkohn.com/interviews-yiddish-13/ (consulté le 12
                janvier 2020) ; Corinna Gepner, « Une langue de l’entre-deux.
                Portrait de Batia Baum », dans « Traduire le Yiddish », dossier
                préparé par Corinna Gepner, Translittérature 43, été 2012, p.
                50-53.
              </Typography>

              <Typography align="justify" variant="subtitle1" id="48">
                <HashLink to="#s48" className="ftnLink">
                  [48]{" "}
                </HashLink>
                https://editions.yiddish.paris/editions/ (consulté le 10 janvier
                2021).
              </Typography>

              <Typography align="justify" variant="subtitle1" id="49">
                <HashLink to="#s49" className="ftnLink">
                  [49]{" "}
                </HashLink>
                Site de la Maison de la culture yiddish :
                http://www.yiddishweb.com/ (consulté le 10 janvier 2021).
              </Typography>

              <Typography align="justify" variant="subtitle1" id="50">
                <HashLink to="#s50" className="ftnLink">
                  [50]{" "}
                </HashLink>
                Communication personnelle de Batia Baum à l’auteure, janvier
                2021.
              </Typography>

              <Typography align="justify" variant="subtitle1" id="51">
                <HashLink to="#s51" className="ftnLink">
                  [51]{" "}
                </HashLink>
                Gepner, Corinna, « Monique Charbonnel traductrice du yiddish :
                Entretien mené par Corinna Gepner », Translittérature 50,
                automne 2016, p. 167-175.
              </Typography>

              <Typography align="justify" variant="subtitle1" id="52">
                <HashLink to="#s52" className="ftnLink">
                  [52]{" "}
                </HashLink>
                https://www.yiddishweb.com/animateurs/jean-spector/ (consulté le
                12 janvier 2020).
              </Typography>

              <Typography align="justify" variant="subtitle1" id="53">
                <HashLink to="#s53" className="ftnLink">
                  [53]{" "}
                </HashLink>
                Ancien directeur de la Maison de la Culture Yiddish, Gilles
                Rozier est co-directeur des Éditions de l’Antilope, lesquelles
                ont publié depuis 2016 huit volumes de littérature yiddish, le
                dernier étant un recueil de récits d’Yitskhok-Leybush Peretz
                traduits par Batia Baum, Histoires des temps passés et à venir
                (2020).
              </Typography>

              <Typography align="justify" variant="subtitle1" id="54">
                <HashLink to="#s54" className="ftnLink">
                  [54]
                </HashLink>
                https://www.farlag.com/books (consulté le 26 janvier 2021).
              </Typography>
            </Box>
          </Box>
        </Container>
      </section>
    </>
  );
}
