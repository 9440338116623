import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import '../style/App.css';
import Typography from "@material-ui/core/Typography";

function createData(institution, name, status, role) {
  return { institution, name, status, role };
}

const rows = [
  createData('Inalco/CERMOM', "Arnaud Bikard", "MCF", "Coordinateur"),
  createData('Inalco/CERMOM', "Marie-Christine Bornes Varol", "Professeur", "Études d’histoire des traductions du judéo-espagnol, anthologie bilingue des textes (judéo-espagnol)"),
  createData('Inalco/CERMOM', "Valentina Fedchenko", "Post-doctorante", "Depouillement des archives, constitution de la base de données (yiddish), métadonnées, analyse des libertés de traduction, élaboration de contenue du site, organisation des colloques"),
     createData('Joliciel Informatique', "Assaf Urieli", "Directeur, chercheur", "Développement informatique, maintenance"),
    createData('Inalco/CERMOM', "Sarah Gimenez", "Doctorante", "Correction d’alignement et de l’OCR (judéo-espagnol), rédaction d’anthologie bilingue (judéo-espagnol)"),

];

export default function ProjectTeam() {
  return (
    <TableContainer component={Paper}  className="TeamTable">
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Nom</TableCell>
            <TableCell align="left">Institution</TableCell>
            <TableCell align="left">Statut</TableCell>
            <TableCell align="left">Rôle</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                <Typography color="primary">{row.name}</Typography>
              </TableCell>
              <TableCell align="left">{row.institution}</TableCell>
              <TableCell align="left">{row.status}</TableCell>
              <TableCell align="left">{row.role}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}