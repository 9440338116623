import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import "../style/App.css";

const useStyles = makeStyles((theme) => ({
  section: {
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    paddingTop: theme.spacing(12),
    paddingBottom: theme.spacing(12),
    [theme.breakpoints.up("md")]: {
      paddingTop: theme.spacing(30),
      paddingBottom: theme.spacing(30),
    },
  },
  primaryAction: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      marginRight: theme.spacing(0),
      marginBottom: theme.spacing(2),
    },
  },
}));

export default function Header(props) {
  const classes = useStyles();

  let content = {
    English: {
      "header-p1": "LJTRAD",
      "header-p2": "The Translation of Jewish Languages",
      description: "The Translation of Jewish Languages",
      "primary-action": "Corpus",
      pattern: "assets/img/about.png",
    },
    French: {
      "header-p1": "LJTRAD",
      "header-p2": "La traduction des langues juives",
      description: "La traduction des langues juives",
      "primary-action": "Corpus",
      pattern: "assets/img/about.png",
    },
  };

  props.language === "Français"
    ? (content = content.French)
    : (content = content.English);

  return (
    <section
      className={classes.section}
      style={{ backgroundImage: `url("${content["pattern"]}")` }}
    >
      <Container maxWidth="md">
        <Box textAlign="center" className="BoxHeader">
          <Typography
            variant="h2"
            component="h2"
            gutterBottom={true}
            color="primary"
            className="TitleHeader"
            style={{ fontSize: "6.0vh" }}
          >
            {" "}
          </Typography>
          <Typography
            variant="h2"
            component="h2"
            color="primary"
            className="TitleHeader"
            style={{ fontSize: "6.0vh" }}
          >
            {content["header-p1"]}{" "}
          </Typography>
          <Typography
            variant="h2"
            component="span"
            color="primary"
            className="TitleHeader"
            style={{ fontSize: "6.0vh" }}
          >
            {content["header-p2"]}
          </Typography>

          <Box mt={3}>
            <Button
              variant="contained"
              color="primary"
              className={classes.primaryAction}
              href="/corpus"
            >
              {content["primary-action"]}
            </Button>
          </Box>
        </Box>
      </Container>
    </section>
  );
}
